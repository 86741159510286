<template>
  <header
    class="ct-layout-header"
    :class="{
      'ct-layout-header--edit-mode': inEditMode
    }"
  >
    <LogoPpReport
      v-bind="{
        title: headerRaw?.title ?? '',
        homeLink: '/'
      }"
    />
    <div class="ct-layout-header__right ct-layout-header__right--desktop">
      <SocialMediaDropdown
        v-if="headerRaw?.showSocialMediaButtons"
        class="ct-layout-header__social-media-dropdown"
        v-bind="{
          socialMediaNames: headerTranslation.socialMedia,
          useShareReportLinks:true
        }"
      />
      <DropdownSelect
        v-for="(dropdown, index) in allDropdowns"
        :key="`${index}dropdown`"
        class="ct-layout-header__download-button"
        v-bind="{
          ...dropdown,
          dropdownType: DropdownTypeIs.DROPDOWN
        }"
      />
    </div>
    <div class="ct-layout-header__right ct-layout-header__right--tablet">
      <SocialMediaDropdown
        v-if="headerRaw?.showSocialMediaButtons"
        class="ct-layout-header__social-media-dropdown"
        v-bind="{
          socialMediaNames: headerTranslation.socialMedia,
          useShareReportLinks:true
        }"
      />
      <button
        class="ct-layout-header__burger-menu--button"
        @click="toggleMenu"
      >
        <IconBurgerMenu class="ct-layout-header__burger-menu--icon" />
      </button>
      <LayoutBurgerMenu
        v-if="showMenu"
        v-bind="{
          allDropdowns: allDropdownsReverse
        }"
        @closePanel="toggleMenu()"
      />
    </div>
  </header>
</template>

<script setup lang="ts">
import type { DropdownOptions, DropdownsRaw, HeaderRaw } from "@/types/entrypoints"
import { computed } from "vue"
import LogoPpReport from "@/components/Logo/PpReport.vue"
import SocialMediaDropdown from "@/components/SocialMedia/Dropdown.vue"
import DropdownSelect from "@/components/DropdownSelect/Index.vue"
import LayoutBurgerMenu from "@/components/Layout/BurgerMenu.vue"
import IconBurgerMenu from "@/components/Icon/Burger.vue"
import { HeaderTranslation } from "@/types/translations"
import useCommon from "@/composables/useCommon"
import { DropdownTypeIs } from "@/types/Components/dropdownSelect"

const showMenu = defineModel<boolean>()
const props = defineProps<{
	headerRaw: HeaderRaw
	dropdownsRaw: DropdownsRaw
	headerTranslation: HeaderTranslation
	videoClassName?: string
	inEditMode?: boolean
}>()
const allDropdowns = computed<DropdownOptions[]>(() => {
	const _allDropdowns = []
	if (props.dropdownsRaw && props.headerRaw) {
		for (const nestedElement of props.headerRaw.allNestedElements) {
			const nestedDropdownElement = props.dropdownsRaw[nestedElement.id]
			if (nestedDropdownElement && nestedDropdownElement.options) {
				_allDropdowns.push(nestedDropdownElement)
			}
		}
	}
	return _allDropdowns
})
const allDropdownsReverse = computed(() => allDropdowns.value.slice().reverse())
const { freezeBackground, freezeVideo } = useCommon()
function toggleMenu() {
	showMenu.value = !showMenu.value
	freezeBackground(!showMenu.value)
	if (props.videoClassName) {
		const herosVideo = document.getElementsByClassName(props.videoClassName)
		for (const heroVideo of herosVideo) {
			freezeVideo(!showMenu.value, heroVideo as HTMLVideoElement)
		}
	}
	if (showMenu.value) {
		window.scrollTo({ top: 0 })
	}
}
</script>

<style scoped>
.ct-layout-header {
  @apply flex h-24 justify-between items-center;

  &--edit-mode {
    @apply bg-extraDarkGrey px-2;
  }

  &__right {
    @apply flex items-start gap-4;

    &--desktop {
      @apply hidden lg:flex;
    }

    &--tablet {
      :deep(.ct-dropdown-button--default) {
        @apply min-h-0 h-9;
      }

      @apply flex lg:hidden items-start gap-3 h-9;
    }
  }

  &__download-button {
    :deep(.ct-dropdown__options--default) {
      @apply w-full;
    }
  }

  &__burger-menu {
    &--button {
      @apply flex w-9 h-9 flex-col justify-center items-center gap-2.5 bg-primary p-2.5;
    }

    &--icon {
      @apply text-black w-[0.893rem] h-[0.625rem];
    }
  }
}
</style>
