<template>
  <div

    ref="chartContainer"
    class="ct-chart-gauge-list"
    :class="`ct-chart-gauge-list--${info.displayDataOf}`"
  >
    <div class="ct-chart-gauge-list__top">
      <span
        class="ct-chart-gauge-list__title"
        v-html="info.title"
      />
      <Dropdowns />
    </div>
    <span
      class="ct-chart-gauge-list__description"
      v-html="info.description"
    />
    <div
      v-if="showCharts"
      class="ct-chart-gauge-list__container"
    >
      <Gauge
        v-for="(chartData,index) in chartsData"
        :key="`${index}-gauge-chart`"
        :cmsInfo="info"
        :index
        :chartData
        :chartSettings
      />
    </div>
    <span
      class="ct-chart-gauge-list__chart-after-chart"
      v-html="info.descriptionAfterChart"
    />
  </div>
</template>

<script setup lang="ts">
import type { CmsInfoChartGauge, FrontendData } from "@/types/entrypoints"
import { computed, onMounted, ref } from "vue"
import { defineComponent } from "vue"
import useCommon from "@/composables/useCommon"
import { GaugeChartDataInfo } from "@/types/chartsData"
import Gauge from "@/components/Chart/Gauge.vue"

const props = defineProps<{
	frontendData: FrontendData
	info: CmsInfoChartGauge
}>()
const chartContainer = ref()
const Dropdowns = defineComponent({
	template: props.info.downloadButtonsHtml || ""
})
const { activeOnlyWhenEnteringViewpoint } = useCommon()
const chartSettings = computed(() => {
	const chartsData = props.frontendData.charts as GaugeChartDataInfo
	if (chartsData) {
		return chartsData[props.info.displayDataOf]?.settings
	}
	return undefined
})
const chartsData = computed(() => {
	const chartsData = props.frontendData.charts as GaugeChartDataInfo
	if (chartsData) {
		return chartsData[props.info.displayDataOf]?.data
	}
	return undefined
})
const showCharts = ref(false)
function activateCharts() {
	showCharts.value = true
}
onMounted(() => {
	activeOnlyWhenEnteringViewpoint(chartContainer.value, activateCharts)
})
</script>

<style scoped lang="postcss">
.ct-chart-gauge-list {
  @apply flex flex-col gap-6;

  &__top {
    @apply flex gap-1 items-center justify-between;
  }

  &__title{
	@apply custom-font__title-text-3;
  }

  &__container {
    @apply flex flex-wrap gap-3 md:gap-0 overflow-x-scroll md:overflow-auto;
  }

  &__description{
    @apply custom-font__sml-body-text-2;
  }

  &__chart-after-chart{
    @apply italic text-naturalGrey custom-font__small-text-2;
  }
}
</style>
