<template>
  <ul class="ct-tab-menu-row">
    <li
      v-for="(tab, key) of menuItems"
      :key="`${key}tabBase`"
      class="ct-tab-menu-row__item"
      :class="{ 'ct-tab-menu-row__item--active': currentItem === tab.id }"
      @click="toggleTab(tab)"
      v-html="tab.title"
    />
  </ul>
</template>

<script setup lang="ts">
import { TabMenuItem, TabMenuItems } from "@/types/Components/tab"

defineProps<{
	menuItems: TabMenuItems
	currentItem: string | number | undefined
}>()
const emit = defineEmits<{
	click: [tab: string | number]
}>()
function toggleTab(tab: TabMenuItem) {
	emit("click", tab.id)
}
</script>

<style scoped lang="postcss">
.ct-tab-menu-row {
  @apply flex cursor-pointer;

  &__item {
    @apply grow lg:grow-0 lg:w-1/2 px-6 py-4 bg-white border-b border-primary custom-font__lrg-body-text-1;

    &--active {
      @apply bg-primary;
    }
  }
}
</style>
