<template>
  <div
    class="ct-chart-line"
    :class="`ct-chart-line--${cmsInfo?.displayDataOf}`"
  >
    <span
      v-if="showTitle"
      class="ct-chart-line__text"
      v-html="title"
    />
    <div
      ref="chartRef"
      class="ct-chart-line__chart"
    />
  </div>
</template>

<script setup lang="ts">
import type { CmsInfoChartLine, TargetLine } from "@/types/entrypoints"
import { computed, onMounted, onUnmounted, ref } from "vue"
import * as am5 from "@amcharts/amcharts5/index"
import * as am5xy from "@amcharts/amcharts5/xy"
import { YearTypeLineChartData } from "@/types/chartsData"
import useChart from "@/composables/useChart"
import { ChartSettings } from "@/types/backend"
import colours from "../../../../tailwind/colours"

const props = defineProps<{
	chartSettings: ChartSettings
	cmsInfo: CmsInfoChartLine | undefined
	chartData: YearTypeLineChartData
	title: string
	showTitle: boolean
	targetLinesInfo?: TargetLine[] | undefined
}>()
const chartRef = ref()
let chart: am5xy.XYChart | undefined
const { getChartTheme, legendFont, getYAxisLabelSettings, getXAxisLabelSettings, getInitlisedVariables } = useChart()
const { allLegends, allColours, min, max, unit } = getInitlisedVariables(props.chartSettings, props.cmsInfo?.displayDataOf)
const xAxisIntervalCount = computed(() => Number.isInteger(props.chartSettings?.show_data_in_every_x_interval) ? (props.chartSettings?.show_data_in_every_x_interval ?? 1) : 1)
function addCursor({ root }: { root: am5.Root }) {
	var cursor = chart?.set("cursor", am5xy.XYCursor.new(root, {}))
	cursor?.lineY.set("visible", false)
}
function addAxis({ root, chart: _chart }: { root: am5.Root
	chart: am5xy.XYChart
}) {
	const xRenderer = am5xy.AxisRendererX.new(root, {
		minorGridEnabled: true,
		minGridDistance: 66
	})
	xRenderer.grid.template.set("visible", false)
	const xAxis = _chart.xAxes.push(
		am5xy.DateAxis.new(root, {
			baseInterval: {
				timeUnit: "year",
				count: xAxisIntervalCount.value
			},
			renderer: xRenderer
		})
	)

	const yRenderer = am5xy.AxisRendererY.new(root, {
		visible: false
	})
	yRenderer.grid.template.set("visible", false)
	const yAxisSettings: am5xy.IValueAxisSettings<am5xy.AxisRenderer> = {
		renderer: yRenderer
	}
	if (typeof min.value === "number") {
		yAxisSettings.min = min.value
	}
	if (typeof max.value === "number") {
		yAxisSettings.max = max.value
	}
	const yAxis = _chart.yAxes.push(
		am5xy.ValueAxis.new(root, yAxisSettings)
	)
	const yAxisTitle = props.chartSettings?.y_axis_label
	const xAxisTitle = props.chartSettings?.x_axis_label
	if (yAxisTitle) {
		yAxis.children.unshift(am5.Label.new(root, {
			...getYAxisLabelSettings(yAxisTitle)
		}))
	}
	if (xAxisTitle) {
		xAxis.children.moveValue(am5.Label.new(root, {
			...getXAxisLabelSettings(xAxisTitle)
		}))
	}
	return { xAxis, yAxis }
}
function addSeries({ root, xAxis, yAxis }: {
	root: am5.Root
	xAxis: am5xy.DateAxis<am5xy.AxisRenderer>
	yAxis: am5xy.ValueAxis<am5xy.AxisRenderer>
}): am5xy.LineSeries[] {
	const seriess: am5xy.LineSeries[] = []
	if (chart && props.chartData) {
		for (const legend of allLegends.value) {
			const config: am5xy.IXYSeriesSettings = {
				name: legend,
				xAxis,
				yAxis,
				valueYField: legend,
				valueXField: "date_timestamp",
				tooltip: am5.Tooltip.new(root, {
					labelText: `${legend}: {valueX}, [bold]{valueY}${unit.value}[\\]`
				})
			}
			if (allColours.value[legend]) {
				config.stroke = allColours.value[legend]
				config.fill = allColours.value[legend]
			}
			let series = chart.series.push(
				am5xy.LineSeries.new(root, config)
			)
			series.data.setAll(props.chartData)
			series.strokes.template.setAll({
				strokeWidth: 3
			})
			let seriesRangeDataItem = yAxis.makeDataItem({})
			series.createAxisRange(seriesRangeDataItem)
			seriesRangeDataItem.get("grid")?.setAll({
				strokeOpacity: 1,
				visible: true
			})
			series.appear(1000)
			seriess.push(series)
		}
	}
	return seriess
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function addBullets({ root, seriess }: { root: am5.Root, seriess: am5xy.LineSeries[] }) {
	for (const series of seriess) {
	// Actual bullet
		series.bullets.push(function () {
			var bulletCircle = am5.Circle.new(root, {
				radius: 5,
				fill: series.get("fill")
			})
			return am5.Bullet.new(root, {
				sprite: bulletCircle
			})
		})
	}
}
function addLegends({ root }: {	root: am5.Root }) {
	if (chart) {
		let legend = chart.bottomAxesContainer.children.push(
			am5.Legend.new(root, {
				centerX: am5.percent(50),
				x: am5.percent(50),
				marginTop: 15,
				marginBottom: 20,
				clickTarget: "none"
			}))
		legend.labels.template.setAll({ ...legendFont })
		// When legend item container is hovered, dim all the series except the hovered one
		legend.itemContainers.template.events.on("pointerover", function (e) {
			let itemContainer = e.target
			// As series list is data of a legend, dataContext is series
			let series = itemContainer.dataItem?.dataContext
			chart?.series.each(function (chartSeries) {
				if (chartSeries != series) {
					// @ts-expect-error , for some reason fill is not typed but it is used
					chartSeries.strokes.template.setAll({
						strokeOpacity: 0.15
					})
				}
				else {
					// @ts-expect-error , for some reason fill is not typed but it is used
					chartSeries.strokes.template.setAll({
						strokeWidth: 5
					})
				}
			})
		})
		// When legend item container is unhovered, make all series as they are
		legend.itemContainers.template.events.on("pointerout", function () {
			chart?.series.each(function (chartSeries) {
				// @ts-expect-error , for some reason fill is not typed but it is used
				chartSeries.strokes.template.setAll({
					strokeOpacity: 1,
					strokeWidth: 3,
					stroke: chartSeries.get("fill")
				})
			})
		})
		// It's is important to set legend data after all the events are set on template, otherwise events won't be copied
		legend.data.setAll(chart.series.values)
	}
}
function addTargetLines({ seriess, targetLinesInfo, yAxis }: {
	root: am5.Root
	targetLinesInfo: TargetLine[]
	seriess: am5xy.LineSeries[]
	yAxis: am5xy.ValueAxis<am5xy.AxisRenderer>
}) {
	let graciousRange = 0.95
	const series = seriess[0]
	if (series) {
		for (const targetLineInfo of targetLinesInfo) {
			const colourCode = colours.colors.chart[targetLineInfo.targetType.internalId] as string
			let dataValue = (max.value ?? 0) * graciousRange
			if (unit.value === "%") {
				dataValue = Number(targetLineInfo.targetPercentage)
			}
			var seriesRangeDataItem = yAxis.makeDataItem({ value: dataValue, endValue: 0 })
			graciousRange = graciousRange - 0.2
			var seriesRange = series.createAxisRange(seriesRangeDataItem)
			seriesRange.fills?.template.setAll({
				visible: true,
				opacity: 0.3
			})
			seriesRangeDataItem.get("grid")?.setAll({
				strokeOpacity: 1,
				visible: true,
				stroke: am5.color(colourCode),
				strokeDasharray: [10, 10]
			})
			seriesRangeDataItem.get("label")?.setAll({
				location: 0,
				visible: true,
				inside: true,
				text: targetLineInfo.targetType.label,
				centerX: 0,
				centerY: am5.p100,
				fontWeight: "bold",
				fill: am5.color(colourCode)
			})
		}
	}
}
function initChart() {
	if (props.chartData !== undefined && Object.keys(props.chartData).length > 0) {
		let root = am5.Root.new(chartRef.value)
		const theme = getChartTheme(root)
		root.setThemes([theme])
		root.dateFormatter.setAll({
			dateFormat: "yyyy",
			dateFields: ["valueX"]
		})
		chart = root.container.children.push(
			am5xy.XYChart.new(root, {
				wheelY: "zoomX",
				layout: root.verticalLayout
			}))
		addCursor({ root })
		const { xAxis, yAxis } = addAxis({ root, chart })
		const seriess = addSeries({ root, xAxis, yAxis })
		// addBullets({ root, seriess })
		addLegends({ root })
		if (Array.isArray(props.targetLinesInfo)) {
			addTargetLines({ root, seriess, targetLinesInfo: props.targetLinesInfo, yAxis })
		}
		chart.appear(1000, 100)
	}
}
onMounted(() => {
	initChart()
})
onUnmounted(() => {
	if (chart) chart.dispose()
})
</script>

<style scoped lang="postcss">
.ct-chart-line {
  @apply flex flex-col gap-1;

  &__text {
    @apply custom-font__lrg-body-text-1;
  }

  &__chart {
    @apply w-[35rem] md:w-full h-[30rem];
  }
}
</style>
