<template>
  <ul class="ct-map-styles__items">
    <li
      v-for="(style,index) in styles"
      :key="`${index}mapstyle`"
      class="ct-map-styles__item"
      :class="{'ct-map-styles__item--active': style.link === chosenStyle}"
      @click="chooseTheStyle(style)"
      v-html="style.title"
    />
  </ul>
</template>

<script setup lang="ts">
import { MapTranslation } from "@/types/translations"
import type { Map } from "maplibre-gl"
import useMap from "@/composables/useMap"
import { computed } from "vue"
import { MapStyleInfo } from "@/types/Components/map"
import useEnvs from "@/composables/useEnvs"
import { LAYER_ADDED_AFTER_THIS_LAYER_SATELITE, LAYER_ADDED_AFTER_THIS_LAYER_TERRAIN } from "../../../../constants"

const props = defineProps<{
	map: Map | undefined
	translations: MapTranslation
}>()
const chosenStyle = defineModel<string>()
const emit = defineEmits<{
	click: [void]
}>()
const { getStyles, getAllAppliedEsriLayers } = useMap()
const styles = getStyles(props.translations)
if (styles.length === 0) {
	console.error("There is no styles available for mapbox to initialise")
}
const chosenStyleIsReconised = computed(() => styles.find(style => style.link === chosenStyle.value) !== undefined)
if (!chosenStyleIsReconised.value && styles.length > 0) {
	chosenStyle.value = styles[0].link
}
const { VITE_MAPBOX_STYLE_SATELLITE, VITE_MAPBOX_STYLE_TERRAIN } = useEnvs()
function chooseTheStyle(styleInfo: MapStyleInfo) {
	chosenStyle.value = styleInfo.link

	props.map?.setStyle(styleInfo.link,
		{
			transformStyle: (previousStyle, nextStyle) => {
				const layers = [...nextStyle.layers]
				let index = -1
				switch (styleInfo.link) {
					case VITE_MAPBOX_STYLE_TERRAIN:{
						index = nextStyle.layers.findIndex(layer => (layer.id === LAYER_ADDED_AFTER_THIS_LAYER_TERRAIN))
						break
					}
					case VITE_MAPBOX_STYLE_SATELLITE: {
						index = nextStyle.layers.findIndex(layer => (layer.id === LAYER_ADDED_AFTER_THIS_LAYER_SATELITE))
						break
					}
				}
				if (index > -1) {
					layers.splice(index, 0, ...getAllAppliedEsriLayers(previousStyle?.layers ?? []))
				}
				return {
					...nextStyle,
					sources: {
						...previousStyle?.sources,
						...nextStyle.sources
					},
					layers
				}
			} }
	)
	emit("click")
}
</script>

<style lang="postcss" scoped>
.ct-map-styles{
  &__items{
    @apply flex cursor-pointer;
  }

  &__item{
    @apply flex bg-white border-naturalDarkGrey md:min-w-[5.375rem] md:min-h-9 justify-center items-center gap-2.5 p-1.5 md:p-2.5 custom-font__map-style;

    &--active{
      @apply bg-naturalDarkGrey text-white;
    }
  }
}
  </style>
