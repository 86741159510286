<template>
  <footer class="ct-footer">
    <div class="ct-footer__top">
      <LogoList class="ct-footer__logo-list" />
    </div>
    <div class="ct-footer__bottom">
      <SocialMediaList
        v-if="footerRaw?.showSocialMediaButtons"
        v-bind="{
          socialMediaNames: footerTranslation.socialMedia
        }"
      />
      <div class="ct-footer__tiny-prints">
        <span
          class="ct-footer__copyright"
          v-html="copyRight"
        />
        <Links
          v-for="(link, index) in linksInfo"
          :key="`${index}Links`"
          class="ct-footer__links"
          v-bind="{
            linksInfo: link
          }"
        />
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { FooterRaw, LinksRaw } from "@/types/entrypoints"
import { computed } from "vue"
import LogoList from "@/components/Logo/List.vue"
import SocialMediaList from "@/components/SocialMedia/List.vue"
import Links from "@/components/Link/List.vue"
import { FooterTranslation } from "@/types/translations"

const props = defineProps<{
	footerRaw: FooterRaw
	linksRaw: LinksRaw
	footerTranslation: FooterTranslation
}>()
const copyRight = computed(
	() => `&copy; ${new Date().getFullYear()} UN Environment Programme (UNEP). All rights reserved.`
)
const linksInfo = computed(() => {
	const _links = []
	if (props.linksRaw && props.footerRaw) {
		for (const nestedElement of props.footerRaw.allNestedElements) {
			const nestedLinksElement = props.linksRaw[nestedElement.id]
			if (nestedLinksElement && nestedLinksElement.links) {
				_links.push(nestedLinksElement)
			}
		}
	}
	return _links
})
</script>

<style scoped>
.ct-footer {
  @apply flex flex-col items-start gap-12 md:gap-[4.5rem] p-4 bg-black lg:px-[4.5rem] lg:py-6 text-white;

  &__top {
    @apply flex flex-col md:items-center gap-6 w-full;
  }

  &__bottom {
    @apply flex flex-col items-end gap-6 w-full;
  }

  &__tiny-prints {
    @apply w-full flex flex-col gap-3 md:flex-row md:gap-0 justify-between items-start;
  }

  &__copyright {
    @apply custom-font__small-text-2 order-2 md:order-none;
  }

  &__links {
    @apply custom-font__small-text-2 order-1 md:order-none;
  }
}
</style>
