import { DISPLAY_DATA_OF_ALL_CHARTS, DISPLAY_DATA_OF_CLUSTERED_BAR_CHART, DISPLAY_DATA_OF_STACKED_CHART, Legend, TARGET_LINE } from "../app/frontend/types/backend"

// All legend colours are defined here
const chart: {
	[key in Legend | TARGET_LINE]?: string
} & {
	[key in DISPLAY_DATA_OF_ALL_CHARTS]?: {
		[key in Legend]?: string
	} } = {
	[Legend.BLACK]: "#000000",
	[Legend.TERRESTRIAL]: "#37A802",
	[Legend.MARINE]: "#004DA8",
	[Legend.TERRESTRIAL_KBA]: "#37A802",
	[Legend.MARINE_KBA]: "#004DA8",
	[Legend.FRESHWATER_KBA]: "#00AEEF",
	[Legend.COVERAGE_TARGET]: "#666666",
	[Legend.TARGET]: "#666666",
	[Legend.WDPA]: "#71A32B",
	[Legend.DEGAZETTEMENT]: "#3B0F77",
	[Legend.DOWNGRADE]: "#8F5BD7",
	[Legend.DOWNSIZE]: "#C7ADEB",
	[Legend.PROTECTION_TOTAL_WDPA]: "#00AEEF",
	[Legend.OECM_ADDTION]: "#0C72C0",
	[Legend.PA]: "#90598F",
	[Legend.OECMS]: "#F5AB8B",
	[Legend.ITT]: "#46C1A9",

	[Legend.FEDERAL_NATIONAL]: "#b888ca",
	[Legend.SUB_NATIONAL_MINISTRY]: "#844c9b",
	[Legend.GOVERNMENT_DELEGATED]: "#602d73",

	[Legend.COLLABORATIVE_GOVERNANCE]: "#70acc4",
	[Legend.JOINT_GOVERNANCE]: "#38748e",
	[Legend.TRASNBOUNDARY_GOVERNANCE]: "#195268",

	[Legend.INDIVIDUAL_LANDOWNERS]: "#72a12b",
	[Legend.NON_PROFIT_ORG]: "#55791e",
	[Legend.FOR_PROFIT]: "#344a16",

	[Legend.INDIGENOUS_PEOPLE]: "#d060aa",
	[Legend.LOCAL_COMMUNITIES]: "#6f114e",

	[Legend.MIXED]: "#f2d4a0",
	[Legend.NOT_REPORTED]: "#9d9d9d",

	[TARGET_LINE.TERRESTRIAL]: "#37A802",
	[TARGET_LINE.MARINE]: "#004DA8",
	[TARGET_LINE.TERRESTRIAL_KBA]: "#37A802",
	[TARGET_LINE.MARINE_KBA]: "#004DA8",
	[TARGET_LINE.FRESHWATER_KBA]: "#71A32B",

	[DISPLAY_DATA_OF_CLUSTERED_BAR_CHART.MEAN_COVERAGE_ALL_KBA_SUBREGIONAL]: {
		[Legend.TERRESTRIAL]: "#FFD400"
	},
	[DISPLAY_DATA_OF_STACKED_CHART.GOVERANCE_SUBREGIONAL]: {
		[Legend.GOVERNANCE_BY_GOV]: "#9234b9",
		[Legend.SHARED_GOVERANCE]: "#3090bb",
		[Legend.PRIVATE_GOVERANCE]: "#729b56",
		[Legend.GOVERANCE_BY_INDIGENOUS_PEOPLES_AND_LOCAL_COMMUNITIES]: "#b81980",
		[Legend.MIXED_GOVERANCE_SUBREGIONAL]: "#f2d4a0",
		[Legend.NOT_REPORTED_GOVERANCE_SUBREGIONAL]: "#9d9d9d"
	}
}

const bioGeoGraphicChart: {
	[key in (Legend.TERRESTRIAL | Legend.MARINE | Legend.FRESHWATER | Legend.TRANSTIONAL | Legend.PELAGIC)]: {
		[key in (Legend.PA | Legend.OECMS)]: string
	}
} = {
	[Legend.TERRESTRIAL]: {
		[Legend.PA]: "#37A802",
		[Legend.OECMS]: "#1F6100"
	},
	[Legend.MARINE]: {
		[Legend.PA]: "#004DA8",
		[Legend.OECMS]: "#0C72C0"
	},
	[Legend.FRESHWATER]: {
		[Legend.PA]: "#00AEEF",
		[Legend.OECMS]: "#0C72C0"
	},
	[Legend.TRANSTIONAL]: {
		[Legend.PA]: "#F27357",
		[Legend.OECMS]: "#96390B"
	},
	[Legend.PELAGIC]: {
		[Legend.PA]: "#A72579",
		[Legend.OECMS]: "#731552"
	}
}
export const colours = {
	primary: "#71A32B",
	secondary: "#551153",
	navigation: "#0F0F0F",
	error: "#ff2226",
	grey: "#D8D8D8",
	extraDarkGrey: "#3A3A3A",
	naturalLightGrey: "#F0F0F0",
	naturalDarkGrey: "#666666",
	naturalGrey: "#919191",
	map: {
		panel: "#2B2A2A",
		option: "#3A3A3A",
		layer: { }
	},
	chart,
	bioGeoGraphicChart
}
export default {
	colors: colours
}
