<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 58 54"
    fill="none"
  >
    <path
      d="M16.9531 33.5003V21.2156H19.4025V33.5003H16.9531Z"
      fill="currentColor"
    />
    <path
      d="M21.7011 21.2156H24.2636V27.8855C24.2636 28.9406 24.3012 29.6189 24.3766 29.9204C24.4897 30.4103 24.7534 30.8248 25.168 31.1263C25.5825 31.4277 26.1477 31.5785 26.8637 31.5785C27.5797 31.5785 28.1449 31.4277 28.5218 31.1639C28.8986 30.8625 29.1247 30.5233 29.2001 30.1088C29.2754 29.6943 29.3131 28.9783 29.3131 28.0362V21.2533H31.8756V27.6971C31.8756 29.1667 31.8002 30.2219 31.6495 30.8248C31.4987 31.4277 31.2349 31.9553 30.8581 32.3698C30.4813 32.7843 29.9914 33.1235 29.3508 33.3872C28.7102 33.651 27.9188 33.7641 26.9014 33.7641C25.6955 33.7641 24.7534 33.6133 24.1128 33.3496C23.4722 33.0858 22.9823 32.7089 22.6055 32.2944C22.2287 31.8422 22.0026 31.39 21.8895 30.9378C21.7388 30.2219 21.6257 29.2044 21.6257 27.8101V21.2156"
      fill="currentColor"
    />
    <path
      d="M33.4207 27.4333C33.4207 26.1898 33.6091 25.1347 33.9859 24.2679C34.2497 23.6273 34.6265 23.0998 35.1164 22.5722C35.6063 22.0823 36.1338 21.7055 36.6991 21.4794C37.4528 21.1402 38.3195 20.9895 39.2992 20.9895C41.0703 20.9895 42.5023 21.5547 43.5574 22.6852C44.2357 23.4012 44.7256 24.3056 44.9517 25.3984L42.3893 25.4361C42.2385 24.9086 42.0124 24.494 41.711 24.1549C41.108 23.4766 40.3167 23.0998 39.2992 23.0998C38.2818 23.0998 37.4904 23.4389 36.8498 24.1549C36.2469 24.8709 35.9077 25.926 35.9077 27.3203M35.9454 27.358C35.9454 28.7522 36.2469 29.8074 36.8875 30.5233C37.5281 31.2393 38.3195 31.5785 39.2992 31.5785C40.279 31.5785 41.0703 31.2393 41.711 30.5233C42.0501 30.1465 42.3139 29.6189 42.4646 29.016L45.0271 29.0914C44.801 30.2972 44.3111 31.277 43.5951 32.0307C42.54 33.1612 41.108 33.6887 39.3369 33.6887C37.5281 33.6887 36.0962 33.1235 35.041 32.0307C33.9859 30.9002 33.4583 29.3929 33.4583 27.4333"
      fill="currentColor"
    />
    <path
      d="M46.4966 33.5003V21.2156H48.6822L54.297 29.732V21.2156H56.558V33.5003H54.41L48.6445 24.9085L48.8706 33.5003H46.4966Z"
      fill="currentColor"
    />
    <path
      d="M42.766 40.2456L50.7172 40.2079C46.1952 47.9706 37.7918 53.1709 28.1826 53.1709C13.7877 53.1709 2.14355 41.4891 2.14355 27.1318C2.14355 12.7746 13.8253 1.09277 28.1826 1.09277C37.9426 1.09277 46.459 6.48147 50.9433 14.4326H43.1052C39.5253 10.2121 34.1743 7.5366 28.2203 7.5366C17.4052 7.5366 8.62506 16.3168 8.62506 27.1318C8.62506 37.9469 17.4052 46.7271 28.2203 46.7271C33.9858 46.7648 39.1861 44.24 42.766 40.2456Z"
      fill="currentColor"
    />
  </svg>
</template>
