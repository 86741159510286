import Cookies from "js-cookie"
import { COOKIE_NAMES } from "../../../constants"

const defaultExpiresAt = new Date(new Date().setFullYear(new Date().getFullYear() + 1))
function setUserConsentToCookie(decision: boolean) {
	Cookies.set(COOKIE_NAMES.CONSENT, String(decision === true), {
		expires: defaultExpiresAt
	})
}
function getUserConsentFromCookie() {
	return Cookies.get(COOKIE_NAMES.CONSENT)
}
function userConsentedForOptionalCookies() {
	return getUserConsentFromCookie() === "true"
}
function setCookie(cookieName: COOKIE_NAMES, value: string, expires: Date) {
	Cookies.set(cookieName, value, {
		// If no date is provided then use defualt expires date
		expires: expires || defaultExpiresAt
	})
}
function getCookie(cookieName: COOKIE_NAMES) {
	return Cookies.get(cookieName)
}
function removeCookie(cookieName: COOKIE_NAMES) {
	Cookies.remove(cookieName)
}
export default function useCookies() {
	return {
		COOKIE_NAMES,
		setUserConsentToCookie,
		getUserConsentFromCookie,
		userConsentedForOptionalCookies,
		setCookie,
		getCookie,
		removeCookie
	}
}
