<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17 16"
    fill="none"
  >
    <path
      d="M6.99976 0V6.58814H0.411621V9.41186H6.99976V16H9.82349V9.41186H16.4116V6.58814H9.82349V0H6.99976Z"
      fill="currentColor"
    />
  </svg>
</template>
