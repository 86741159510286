import { LayerSpecificationWithExtra, LegendColours, MapInfo, MapLegend, MapOption, MapRealmInfo, MapsInfo, MapStyleInfo, Resource } from "@/types/Components/map"
import { MapArea, MapRealm, MapAreaType, EsriLayerId } from "@/types/esri"
import { MapTranslation } from "@/types/translations"
import { computed, Ref } from "vue"
import useEnvs from "@/composables/useEnvs"
import { DISPLAY_DATA_OF_MAP, MAP_RESOURCES } from "@/types/backend"
import type { LayerSpecification, Map } from "maplibre-gl"
import { EsriLayerInfo } from "@/types/mapsData"

// import { colours } from "../../../tailwind/colours"
// If you need to use your own defined colours for legends rather then using the colous from Esri then you can use the following line
// const layerColours = colours.map.layer
function getStyleLink(mapResource: MAP_RESOURCES) {
	return `https://tiles.arcgis.com/tiles/Mj0hjvkNtV7NRhA7/arcgis/rest/services/${mapResource}/VectorTileServer/resources/styles/root.json?f=pjson`
}
// TODO: search build_map_info_base for the reason we have to do this
async function getInfoFromESRIServer(displayDataOf: DISPLAY_DATA_OF_MAP) {
	if (window.frontendData && window.frontendData.maps[displayDataOf]) {
		for (const _mapResource of Object.keys(window.frontendData.maps[displayDataOf])) {
			const mapResource = _mapResource as MAP_RESOURCES
			const url = getStyleLink(mapResource)
			const layers: EsriLayerInfo[] = await fetch(url).then(async (_data) => {
				const data = await _data.json() as { layers: unknown }
				return Array.isArray(data.layers) ? data.layers as EsriLayerInfo[] : []
			}).catch(() => {
				console.log(`We could not fetch layers info for ${mapResource}`)
				return []
			})
			if (window.frontendData.maps[displayDataOf][mapResource]) {
				window.frontendData.maps[displayDataOf][mapResource].style_layers = layers
			}
		}
		if (displayDataOf === DISPLAY_DATA_OF_MAP.DISTRIBUTION_THE_WORLDS_PA_AND_OECMS) {
			const colour = "rgba(11,76,168,0.7)"
			const oecm_colour = "rgba(217,177,67,0.5)"
			const circle_radius = 2
			const oecmMapInfo = [
				{ id: MAP_RESOURCES.PP_LIVE_24_CPT3_WDPA, isOECMS: false },
				{ id: MAP_RESOURCES.PP_LIVE_24_CPT3_WDOECM, isOECMS: true }]
			oecmMapInfo.forEach(({ id, isOECMS }) => {
				if (window.frontendData
					&& window.frontendData.maps[displayDataOf]
					&& window.frontendData.maps[displayDataOf][id]
					&& window.frontendData.maps[displayDataOf][id].style_layers) {
					window.frontendData.maps[displayDataOf][id].style_layers.push({
						"id": isOECMS ? EsriLayerId.ALL_MARINE_POINT_OECMS : EsriLayerId.ALL_MARINE_POINT,
						"type": "circle",
						"source": "esri",
						"source-layer": isOECMS ? "WDOECM_point_Aug2024" : "WDPA_point_Aug2024",
						"filter": [
							">",
							"_symbol",
							0
						],
						"layout": {},
						"paint": {
							"circle-radius": circle_radius,
							"circle-color": isOECMS ? oecm_colour : colour
						}
					})
					window.frontendData.maps[displayDataOf][id].style_layers.push({
						"id": isOECMS ? EsriLayerId.ALL_MARINE_POLYGON_OECMS : EsriLayerId.ALL_MARINE_POLYGON,
						"type": "fill",
						"source": "esri",
						"source-layer": isOECMS ? "WDOECM_poly_Aug2024" : "WDPA_poly_Aug2024",
						"filter": [
							">",
							"_symbol",
							0
						],
						"layout": {},
						"paint": {
							"fill-color": isOECMS ? oecm_colour : colour
						}
					})
					if (isOECMS === false) {
						window.frontendData.maps[displayDataOf][id].style_layers.push({
							"id": EsriLayerId.ALL_MARINE_ABNJ,
							"type": "fill",
							"source": "esri",
							"source-layer": "WDPA_poly_Aug2024_ABNJ",
							"filter": [
								"==",
								"_symbol",
								0
							],
							"layout": {},
							"paint": {
								"fill-color": "rgba(6,53,70,0.7)"
							}
						})
					}
				}
			})
		}
	}
}
function getResourceTileInfo(displayDataOfMap: DISPLAY_DATA_OF_MAP, mapResource: MAP_RESOURCES) {
	const esriLayersInfo = window.frontendData?.maps
	if (!esriLayersInfo || !esriLayersInfo[displayDataOfMap])
		return	{
			resource: undefined,
			layers: []
		}
	const info = esriLayersInfo[displayDataOfMap][mapResource]
	return {
		resource: info?.tile_link,
		layers: info?.style_layers ?? []
	}
}
function getColourFromEsriLayers(esriLayers: EsriLayerInfo[], EsriLayerId: EsriLayerId): string | undefined {
	const esriLayer = esriLayers.find(esriLayer => esriLayer.id === EsriLayerId)
	switch (esriLayer?.type) {
		case "fill":
			return esriLayer.paint?.["fill-color"] as string | undefined
		case "circle":
			return esriLayer.paint?.["circle-color"] as string | undefined
		default:
			return undefined
	}
}
/**
 *
 * @param esriLayer the esri layer that are having type LayerSpecificationWithExtra
 * @param areaType MapAreaType defined in the map definition above
 * @param legendColours LegendColours defined in the map definition above
 * @returns No Return.
 * If there is no legend colour found then will use the default colour set in ESRI
 */
function convertColoursToSameAsLegendColour(esriLayer: LayerSpecificationWithExtra, areaType: MapAreaType, legendColours: LegendColours) {
	const iso3Colours = legendColours.iso !== undefined ? legendColours.iso[areaType] : undefined
	const colourCode = esriLayer.metadata.resource.isISO3Layer ? iso3Colours : legendColours[areaType]
	if (colourCode !== undefined) {
		switch (esriLayer?.type) {
			case "fill":{
				esriLayer.paint = {
					"fill-color": colourCode,
					"fill-opacity": 0.8
				}
				break
			}
			case "circle":{
				esriLayer.paint = {
					"circle-color": colourCode,
					"circle-opacity": 0.8,
					"circle-radius": 2
				}
				break
			}
		}
	}
}
function distributionTheWorldsPaAndOecmsMapInfo(): MapInfo {
	const PP_LIVE_24_CPT3_WDPA = getResourceTileInfo(DISPLAY_DATA_OF_MAP.DISTRIBUTION_THE_WORLDS_PA_AND_OECMS, MAP_RESOURCES.PP_LIVE_24_CPT3_WDPA)
	const PP_LIVE_24_CPT3_WDOECM = getResourceTileInfo(DISPLAY_DATA_OF_MAP.DISTRIBUTION_THE_WORLDS_PA_AND_OECMS, MAP_RESOURCES.PP_LIVE_24_CPT3_WDOECM)
	const resources: Resource[] = [
		{
			id: MAP_RESOURCES.PP_LIVE_24_CPT3_WDPA,
			area: MapArea.GLOBAL,
			type: MapAreaType.PA,
			resource: PP_LIVE_24_CPT3_WDPA?.resource,
			allLayers: PP_LIVE_24_CPT3_WDPA?.layers
		},
		{
			id: MAP_RESOURCES.PP_LIVE_24_CPT3_WDOECM,
			area: MapArea.GLOBAL,
			type: MapAreaType.PA_OECMS,
			resource: PP_LIVE_24_CPT3_WDOECM.resource,
			allLayers: PP_LIVE_24_CPT3_WDOECM.layers
		}

	]
	return {
		resources,
		areas: {
			[MapArea.GLOBAL]: {
				[MapRealm.TERRESTRIAL]: {
					legends: [
						{
							legend: MapLegend.ALL_TERRESTRIAL,
							esriLayerNames: [
								EsriLayerId.ALL_TERRESTRIAL_POINT,
								EsriLayerId.ALL_TERRESTRIAL_POLYGON,
								EsriLayerId.ALL_TERRESTRIAL_POLYGON_OECMS,
								EsriLayerId.ALL_TERRESTRIAL_POINT_OECMS
							],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA.layers, EsriLayerId.ALL_TERRESTRIAL_POINT),
								// The legend colour will not be used in map only used in the Panel search convertColoursToSameAsLegendColour for more info
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA.layers, EsriLayerId.ALL_TERRESTRIAL_POINT)
							}

						}
					]
				},
				[MapRealm.MARINE]: {
					legends: [
						{
							legend: MapLegend.ALL_MARINE,
							esriLayerNames: [
								EsriLayerId.ALL_MARINE_POLYGON,
								EsriLayerId.ALL_MARINE_POINT,
								EsriLayerId.ALL_MARINE_ABNJ,
								EsriLayerId.ALL_MARINE_POLYGON_OECMS,
								EsriLayerId.ALL_MARINE_POINT_OECMS
							],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA.layers, EsriLayerId.ALL_MARINE_POLYGON),
								// The legend colour will not be used in map only used in the Panel search convertColoursToSameAsLegendColour for more info
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA.layers, EsriLayerId.ALL_MARINE_POLYGON)
							}
						},
						{
							legend: MapLegend.NATIONAL_WATERS,
							esriLayerNames: [
								EsriLayerId.NATIONAL_WATER_POINT,
								EsriLayerId.NATIONAL_WATER_POLY_1,
								EsriLayerId.NATIONAL_WATER_POLY_2,

								EsriLayerId.MARINE_NATIONAL_WATERS_POINT_OECMS,
								EsriLayerId.MARINE_NATIONAL_WATERS_POLYGON_OECMS,
								EsriLayerId.MARINE_NATIONAL_WATERS_POLYGON_OECMS_1
							],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA.layers, EsriLayerId.NATIONAL_WATER_POLY_1),
								// The legend colour will not be used in map only used in the Panel search convertColoursToSameAsLegendColour for more info
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA.layers, EsriLayerId.NATIONAL_WATER_POLY_1)
							}
						},
						{
							legend: MapLegend.ABNJ,
							esriLayerNames: [
								EsriLayerId.POLYGON_ABNJ
							],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA.layers, EsriLayerId.POLYGON_ABNJ),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA.layers, EsriLayerId.POLYGON_ABNJ)
							}
						}
					]
				}
			}
		}
	}
}
function nationalSubRegionalCoveragePaAndOecmsMapInfo(): MapInfo {
	const PP_LIVE_24_CPT3_WDPA_NAT = getResourceTileInfo(DISPLAY_DATA_OF_MAP.NATIONAL_SUB_REGIONAL_COVERAGE_PA_AND_OECMS, MAP_RESOURCES.PP_LIVE_24_CPT3_WDPA_NAT)
	const PP_LIVE_24_CPT3_WDPA_NAT_OECMS = getResourceTileInfo(DISPLAY_DATA_OF_MAP.NATIONAL_SUB_REGIONAL_COVERAGE_PA_AND_OECMS, MAP_RESOURCES.PP_LIVE_24_CPT3_WDPA_NAT_OECMS)
	const PP_LIVE_24_CPT3_WDPA_REG = getResourceTileInfo(DISPLAY_DATA_OF_MAP.NATIONAL_SUB_REGIONAL_COVERAGE_PA_AND_OECMS, MAP_RESOURCES.PP_LIVE_24_CPT3_WDPA_REG)
	const PP_LIVE_24_CPT3_WDPA_REG_OECMS = getResourceTileInfo(DISPLAY_DATA_OF_MAP.NATIONAL_SUB_REGIONAL_COVERAGE_PA_AND_OECMS, MAP_RESOURCES.PP_LIVE_24_CPT3_WDPA_REG_OECMS)
	const PP_Live_24_Cpt3_WDPA_NAT_PARENT = getResourceTileInfo(DISPLAY_DATA_OF_MAP.NATIONAL_SUB_REGIONAL_COVERAGE_PA_AND_OECMS, MAP_RESOURCES.PP_Live_24_Cpt3_WDPA_NAT_PARENT)
	const PP_Live_24_Cpt3_WDPA_NAT_PARENT_OECMS = getResourceTileInfo(DISPLAY_DATA_OF_MAP.NATIONAL_SUB_REGIONAL_COVERAGE_PA_AND_OECMS, MAP_RESOURCES.PP_Live_24_Cpt3_WDPA_NAT_PARENT_OECMS)

	const resources: Resource[] = [
		{
			id: MAP_RESOURCES.PP_LIVE_24_CPT3_WDPA_NAT,
			area: MapArea.NATIONAL,
			type: MapAreaType.PA,
			resource: PP_LIVE_24_CPT3_WDPA_NAT?.resource,
			allLayers: PP_LIVE_24_CPT3_WDPA_NAT?.layers
		},
		{
			id: MAP_RESOURCES.PP_LIVE_24_CPT3_WDPA_NAT_OECMS,
			area: MapArea.NATIONAL,
			type: MapAreaType.PA_OECMS,
			resource: PP_LIVE_24_CPT3_WDPA_NAT_OECMS.resource,
			allLayers: PP_LIVE_24_CPT3_WDPA_NAT_OECMS.layers
		},
		{
			id: MAP_RESOURCES.PP_LIVE_24_CPT3_WDPA_REG,
			area: MapArea.SUB_REGIONAL,
			type: MapAreaType.PA,
			resource: PP_LIVE_24_CPT3_WDPA_REG.resource,
			allLayers: PP_LIVE_24_CPT3_WDPA_REG.layers
		},
		{
			id: MAP_RESOURCES.PP_LIVE_24_CPT3_WDPA_REG_OECMS,
			area: MapArea.SUB_REGIONAL,
			type: MapAreaType.PA_OECMS,
			resource: PP_LIVE_24_CPT3_WDPA_REG_OECMS.resource,
			allLayers: PP_LIVE_24_CPT3_WDPA_REG_OECMS.layers
		},
		{
			id: MAP_RESOURCES.PP_Live_24_Cpt3_WDPA_NAT_PARENT,
			area: MapArea.NATIONAL,
			type: MapAreaType.PA,
			resource: PP_Live_24_Cpt3_WDPA_NAT_PARENT.resource,
			allLayers: PP_Live_24_Cpt3_WDPA_NAT_PARENT.layers,
			isISO3Layer: true
		},
		{
			id: MAP_RESOURCES.PP_Live_24_Cpt3_WDPA_NAT_PARENT_OECMS,
			area: MapArea.NATIONAL,
			type: MapAreaType.PA_OECMS,
			resource: PP_Live_24_Cpt3_WDPA_NAT_PARENT_OECMS.resource,
			allLayers: PP_Live_24_Cpt3_WDPA_NAT_PARENT_OECMS.layers,
			isISO3Layer: true
		}
	]
	const terrestrialLegendsNational: MapRealmInfo = {
		legends: [
			{
				legend: MapLegend.LESS_THAN_FIVE_PERCENT,
				esriLayerNames: [
					EsriLayerId.LAND_LESS_THAN_FIVE,
					EsriLayerId.LAND_LESS_THAN_FIVE_ISO3
				],
				legendColours: {
					[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA_NAT.layers, EsriLayerId.LAND_LESS_THAN_FIVE),
					[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA_NAT_OECMS.layers, EsriLayerId.LAND_LESS_THAN_FIVE),
					iso: {
						[MapAreaType.PA]: getColourFromEsriLayers(PP_Live_24_Cpt3_WDPA_NAT_PARENT.layers, EsriLayerId.LAND_LESS_THAN_FIVE_ISO3),
						[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_Live_24_Cpt3_WDPA_NAT_PARENT_OECMS.layers, EsriLayerId.LAND_LESS_THAN_FIVE_ISO3)
					}
				}
			},
			{
				legend: MapLegend.FIVE_TO_TEN_PERCENT,
				esriLayerNames: [
					EsriLayerId.LAND_FIVE_TO_TEN,
					EsriLayerId.LAND_FIVE_TO_TEN_ISO3
				],
				legendColours: {
					[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA_NAT.layers, EsriLayerId.LAND_FIVE_TO_TEN),
					[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA_NAT_OECMS.layers, EsriLayerId.LAND_FIVE_TO_TEN),
					iso: {
						[MapAreaType.PA]: getColourFromEsriLayers(PP_Live_24_Cpt3_WDPA_NAT_PARENT.layers, EsriLayerId.LAND_FIVE_TO_TEN_ISO3),
						[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_Live_24_Cpt3_WDPA_NAT_PARENT_OECMS.layers, EsriLayerId.LAND_FIVE_TO_TEN_ISO3)
					}
				}
			},
			{
				legend: MapLegend.TEN_TO_SEVENTEEN_PERCENT,
				esriLayerNames: [
					EsriLayerId.LAND_TEN_TO_SEVENTEEN,
					EsriLayerId.LAND_TEN_TO_SEVENTEEN_ISO3
				],
				legendColours: {
					[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA_NAT.layers, EsriLayerId.LAND_TEN_TO_SEVENTEEN),
					[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA_NAT_OECMS.layers, EsriLayerId.LAND_TEN_TO_SEVENTEEN),
					iso: {
						[MapAreaType.PA]: getColourFromEsriLayers(PP_Live_24_Cpt3_WDPA_NAT_PARENT.layers, EsriLayerId.LAND_TEN_TO_SEVENTEEN_ISO3),
						[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_Live_24_Cpt3_WDPA_NAT_PARENT_OECMS.layers, EsriLayerId.LAND_TEN_TO_SEVENTEEN_ISO3)
					}
				}
			},
			{
				legend: MapLegend.SEVENTEEN_TO_THIRTY_PERCENT,
				esriLayerNames: [
					EsriLayerId.LAND_SEVENTEEN_TO_THIRTY,
					EsriLayerId.LAND_SEVENTEEN_TO_THIRTY_ISO3
				],
				legendColours: {
					[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA_NAT.layers, EsriLayerId.LAND_SEVENTEEN_TO_THIRTY),
					[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA_NAT_OECMS.layers, EsriLayerId.LAND_SEVENTEEN_TO_THIRTY),
					iso: {
						[MapAreaType.PA]: getColourFromEsriLayers(PP_Live_24_Cpt3_WDPA_NAT_PARENT.layers, EsriLayerId.LAND_SEVENTEEN_TO_THIRTY_ISO3),
						[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_Live_24_Cpt3_WDPA_NAT_PARENT_OECMS.layers, EsriLayerId.LAND_SEVENTEEN_TO_THIRTY_ISO3)
					}
				}
			},
			{
				legend: MapLegend.MORE_THAN_THIRTY_PERCENT,
				esriLayerNames: [
					EsriLayerId.LAND_MORE_THAN_THIRTY,
					EsriLayerId.LAND_MORE_THAN_THIRTY_ISO3
				],
				legendColours: {
					[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA_NAT.layers, EsriLayerId.LAND_MORE_THAN_THIRTY),
					[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA_NAT_OECMS.layers, EsriLayerId.LAND_MORE_THAN_THIRTY),
					iso: {
						[MapAreaType.PA]: getColourFromEsriLayers(PP_Live_24_Cpt3_WDPA_NAT_PARENT.layers, EsriLayerId.LAND_MORE_THAN_THIRTY_ISO3),
						[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_Live_24_Cpt3_WDPA_NAT_PARENT_OECMS.layers, EsriLayerId.LAND_MORE_THAN_THIRTY_ISO3)
					}
				}
			},
			{
				legend: MapLegend.DATA_DEFICITENT,
				esriLayerNames: [
					EsriLayerId.LAND_DATA_DEFICIENT,
					EsriLayerId.LAND_DATA_DEFICIENT_ISO3
				],
				legendColours: {
					[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA_NAT.layers, EsriLayerId.LAND_DATA_DEFICIENT),
					[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA_NAT_OECMS.layers, EsriLayerId.LAND_DATA_DEFICIENT),
					iso: {
						[MapAreaType.PA]: getColourFromEsriLayers(PP_Live_24_Cpt3_WDPA_NAT_PARENT.layers, EsriLayerId.LAND_DATA_DEFICIENT_ISO3),
						[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_Live_24_Cpt3_WDPA_NAT_PARENT_OECMS.layers, EsriLayerId.LAND_DATA_DEFICIENT_ISO3)
					}
				}
			}
		],
		showISO3Toggle: true
	}
	const marineLegendsNational: MapRealmInfo = {
		legends: [
			{
				legend: MapLegend.LESS_THAN_FIVE_PERCENT,
				esriLayerNames: [
					EsriLayerId.MARINE_LESS_THAN_FIVE,
					EsriLayerId.MARINE_LESS_THAN_FIVE_ISO3
				],
				legendColours: {
					[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA_NAT.layers, EsriLayerId.MARINE_LESS_THAN_FIVE),
					[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA_NAT_OECMS.layers, EsriLayerId.MARINE_LESS_THAN_FIVE),
					iso: {
						[MapAreaType.PA]: getColourFromEsriLayers(PP_Live_24_Cpt3_WDPA_NAT_PARENT.layers, EsriLayerId.MARINE_LESS_THAN_FIVE_ISO3),
						[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_Live_24_Cpt3_WDPA_NAT_PARENT_OECMS.layers, EsriLayerId.MARINE_LESS_THAN_FIVE_ISO3)
					}
				}
			},
			{
				legend: MapLegend.FIVE_TO_TEN_PERCENT,
				esriLayerNames: [
					EsriLayerId.MARINE_FIVE_TO_TEN,
					EsriLayerId.MARINE_FIVE_TO_TEN_ISO3
				],
				legendColours: {
					[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA_NAT.layers, EsriLayerId.MARINE_FIVE_TO_TEN),
					[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA_NAT_OECMS.layers, EsriLayerId.MARINE_FIVE_TO_TEN),
					iso: {
						[MapAreaType.PA]: getColourFromEsriLayers(PP_Live_24_Cpt3_WDPA_NAT_PARENT.layers, EsriLayerId.MARINE_FIVE_TO_TEN_ISO3),
						[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_Live_24_Cpt3_WDPA_NAT_PARENT_OECMS.layers, EsriLayerId.MARINE_FIVE_TO_TEN_ISO3)
					}
				}
			},
			{
				legend: MapLegend.TEN_TO_SEVENTEEN_PERCENT,
				esriLayerNames: [
					EsriLayerId.MARINE_TEN_TO_SEVENTEEN,
					EsriLayerId.MARINE_TEN_TO_SEVENTEEN_ISO3
				],
				legendColours: {
					[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA_NAT.layers, EsriLayerId.MARINE_TEN_TO_SEVENTEEN),
					[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA_NAT_OECMS.layers, EsriLayerId.MARINE_TEN_TO_SEVENTEEN),
					iso: {
						[MapAreaType.PA]: getColourFromEsriLayers(PP_Live_24_Cpt3_WDPA_NAT_PARENT.layers, EsriLayerId.MARINE_TEN_TO_SEVENTEEN_ISO3),
						[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_Live_24_Cpt3_WDPA_NAT_PARENT_OECMS.layers, EsriLayerId.MARINE_TEN_TO_SEVENTEEN_ISO3)
					}
				}
			},
			{
				legend: MapLegend.SEVENTEEN_TO_THIRTY_PERCENT,
				esriLayerNames: [
					EsriLayerId.MARINE_SEVENTEEN_TO_THIRTY,
					EsriLayerId.MARINE_SEVENTEEN_TO_THIRTY_ISO3
				],
				legendColours: {
					[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA_NAT.layers, EsriLayerId.MARINE_SEVENTEEN_TO_THIRTY),
					[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA_NAT_OECMS.layers, EsriLayerId.MARINE_SEVENTEEN_TO_THIRTY),
					iso: {
						[MapAreaType.PA]: getColourFromEsriLayers(PP_Live_24_Cpt3_WDPA_NAT_PARENT.layers, EsriLayerId.MARINE_SEVENTEEN_TO_THIRTY_ISO3),
						[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_Live_24_Cpt3_WDPA_NAT_PARENT_OECMS.layers, EsriLayerId.MARINE_SEVENTEEN_TO_THIRTY_ISO3)
					}
				}
			},
			{
				legend: MapLegend.MORE_THAN_THIRTY_PERCENT,
				esriLayerNames: [
					EsriLayerId.MARINE_MORE_THAN_THIRTY,
					EsriLayerId.MARINE_MORE_THAN_THIRTY_ISO3
				],
				legendColours: {
					[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA_NAT.layers, EsriLayerId.MARINE_MORE_THAN_THIRTY),
					[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA_NAT_OECMS.layers, EsriLayerId.MARINE_MORE_THAN_THIRTY),
					iso: {
						[MapAreaType.PA]: getColourFromEsriLayers(PP_Live_24_Cpt3_WDPA_NAT_PARENT.layers, EsriLayerId.MARINE_MORE_THAN_THIRTY_ISO3),
						[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_Live_24_Cpt3_WDPA_NAT_PARENT_OECMS.layers, EsriLayerId.MARINE_MORE_THAN_THIRTY_ISO3)
					}
				}
			},
			{
				legend: MapLegend.DATA_DEFICITENT,
				esriLayerNames: [
					EsriLayerId.MARINE_DATA_DEFICIENT,
					EsriLayerId.MARINE_DATA_DEFICIENT_ISO3
				],
				legendColours: {
					[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA_NAT.layers, EsriLayerId.MARINE_DATA_DEFICIENT),
					[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA_NAT_OECMS.layers, EsriLayerId.MARINE_DATA_DEFICIENT),
					iso: {
						[MapAreaType.PA]: getColourFromEsriLayers(PP_Live_24_Cpt3_WDPA_NAT_PARENT.layers, EsriLayerId.MARINE_DATA_DEFICIENT_ISO3),
						[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_Live_24_Cpt3_WDPA_NAT_PARENT_OECMS.layers, EsriLayerId.MARINE_DATA_DEFICIENT_ISO3)
					}
				}
			}
		],
		showISO3Toggle: true
	}
	const terrestrialLegendsSubRegional: MapRealmInfo = {
		legends: [
			{
				legend: MapLegend.LESS_THAN_FIVE_PERCENT,
				esriLayerNames: [
					EsriLayerId.LAND_SUBREGION_LESS_THAN_FIVE
				],
				legendColours: {
					[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA_REG.layers, EsriLayerId.LAND_SUBREGION_LESS_THAN_FIVE),
					[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA_REG_OECMS.layers, EsriLayerId.LAND_SUBREGION_LESS_THAN_FIVE)
				}
			},
			{
				legend: MapLegend.FIVE_TO_TEN_PERCENT,
				esriLayerNames: [
					EsriLayerId.LAND_SUBREGION_FIVE_TO_TEN
				],
				legendColours: {
					[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA_REG.layers, EsriLayerId.LAND_SUBREGION_FIVE_TO_TEN),
					[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA_REG_OECMS.layers, EsriLayerId.LAND_SUBREGION_FIVE_TO_TEN)
				}
			},
			{
				legend: MapLegend.TEN_TO_SEVENTEEN_PERCENT,
				esriLayerNames: [
					EsriLayerId.LAND_SUBREGION_TEN_TO_SEVENTEEN
				],
				legendColours: {
					[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA_REG.layers, EsriLayerId.LAND_SUBREGION_TEN_TO_SEVENTEEN),
					[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA_REG_OECMS.layers, EsriLayerId.LAND_SUBREGION_TEN_TO_SEVENTEEN)
				}

			},
			{
				legend: MapLegend.SEVENTEEN_TO_THIRTY_PERCENT,
				esriLayerNames: [
					EsriLayerId.LAND_SUBREGION_SEVENTEEN_TO_THIRTY
				],
				legendColours: {
					[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA_REG.layers, EsriLayerId.LAND_SUBREGION_SEVENTEEN_TO_THIRTY),
					[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA_REG_OECMS.layers, EsriLayerId.LAND_SUBREGION_SEVENTEEN_TO_THIRTY)
				}
			},
			{
				legend: MapLegend.MORE_THAN_THIRTY_PERCENT,
				esriLayerNames: [
					EsriLayerId.LAND_SUBREGION_MORE_THAN_THIRTY
				],
				legendColours: {
					[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA_REG.layers, EsriLayerId.LAND_SUBREGION_MORE_THAN_THIRTY),
					[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA_REG_OECMS.layers, EsriLayerId.LAND_SUBREGION_MORE_THAN_THIRTY)
				}
			},
			{
				legend: MapLegend.DATA_DEFICITENT,
				esriLayerNames: [
					EsriLayerId.LAND_SUBREGION_DATA_DEFICIENT
				],
				legendColours: {
					[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA_REG.layers, EsriLayerId.LAND_SUBREGION_DATA_DEFICIENT),
					[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA_REG_OECMS.layers, EsriLayerId.LAND_SUBREGION_DATA_DEFICIENT)
				}
			}
		]
	}
	const marineLegendsSubRegional: MapRealmInfo = {
		legends: [
			{
				legend: MapLegend.LESS_THAN_FIVE_PERCENT,
				esriLayerNames: [
					EsriLayerId.MARINE_SUBREGION_LESS_THAN_FIVE
				],
				legendColours: {
					[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA_REG.layers, EsriLayerId.MARINE_SUBREGION_LESS_THAN_FIVE),
					[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA_REG_OECMS.layers, EsriLayerId.MARINE_SUBREGION_LESS_THAN_FIVE)
				}
			},
			{
				legend: MapLegend.FIVE_TO_TEN_PERCENT,
				esriLayerNames: [
					EsriLayerId.MARINE_SUBREGION_FIVE_TO_TEN
				],
				legendColours: {
					[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA_REG.layers, EsriLayerId.MARINE_SUBREGION_FIVE_TO_TEN),
					[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA_REG_OECMS.layers, EsriLayerId.MARINE_SUBREGION_FIVE_TO_TEN)
				}

			},
			{
				legend: MapLegend.TEN_TO_SEVENTEEN_PERCENT,
				esriLayerNames: [
					EsriLayerId.MARINE_SUBREGION_TEN_TO_SEVENTEEN
				],
				legendColours: {
					[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA_REG.layers, EsriLayerId.MARINE_SUBREGION_TEN_TO_SEVENTEEN),
					[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA_REG_OECMS.layers, EsriLayerId.MARINE_SUBREGION_TEN_TO_SEVENTEEN)
				}

			},
			{
				legend: MapLegend.SEVENTEEN_TO_THIRTY_PERCENT,
				esriLayerNames: [
					EsriLayerId.MARINE_SUBREGION_SEVENTEEN_TO_THIRTY
				],
				legendColours: {
					[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA_REG.layers, EsriLayerId.MARINE_SUBREGION_SEVENTEEN_TO_THIRTY),
					[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA_REG_OECMS.layers, EsriLayerId.MARINE_SUBREGION_SEVENTEEN_TO_THIRTY)
				}
			},
			{
				legend: MapLegend.MORE_THAN_THIRTY_PERCENT,
				esriLayerNames: [
					EsriLayerId.MARINE_SUBREGION_MORE_THAN_THIRTY
				],
				legendColours: {
					[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA_REG.layers, EsriLayerId.MARINE_SUBREGION_MORE_THAN_THIRTY),
					[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA_REG_OECMS.layers, EsriLayerId.MARINE_SUBREGION_MORE_THAN_THIRTY)
				}
			},
			{
				legend: MapLegend.DATA_DEFICITENT,
				esriLayerNames: [
					EsriLayerId.MARINE_SUBREGION_DATA_DEFICIENT
				],
				legendColours: {
					[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA_REG.layers, EsriLayerId.MARINE_SUBREGION_DATA_DEFICIENT),
					[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT3_WDPA_REG_OECMS.layers, EsriLayerId.MARINE_SUBREGION_DATA_DEFICIENT)
				}
			}
		]
	}
	return {
		resources,
		areas: {
			[MapArea.NATIONAL]: {
				[MapRealm.TERRESTRIAL]: terrestrialLegendsNational,
				[MapRealm.MARINE]: marineLegendsNational
			},
			[MapArea.SUB_REGIONAL]: {
				[MapRealm.TERRESTRIAL]: terrestrialLegendsSubRegional,
				[MapRealm.MARINE]: marineLegendsSubRegional
			}
		}
	}
}
function keyBiodiversityAreasMapInfo(): MapInfo {
	const PP_LIVE_24_CPT3_KBA_NAT = getResourceTileInfo(DISPLAY_DATA_OF_MAP.KEY_BIODIVERSITY_AREAS, MAP_RESOURCES.PP_LIVE_24_CPT3_KBA_NAT)
	const PP_LIVE_24_CPT3_KBA_OECMS = getResourceTileInfo(DISPLAY_DATA_OF_MAP.KEY_BIODIVERSITY_AREAS, MAP_RESOURCES.PP_LIVE_24_CPT3_KBA_OECMS)
	const resources: Resource[] = [
		{
			id: MAP_RESOURCES.PP_LIVE_24_CPT3_KBA_NAT,
			area: MapArea.GLOBAL,
			type: MapAreaType.PA,
			resource: PP_LIVE_24_CPT3_KBA_NAT.resource,
			allLayers: PP_LIVE_24_CPT3_KBA_NAT.layers
		},
		{
			id: MAP_RESOURCES.PP_LIVE_24_CPT3_KBA_OECMS,
			area: MapArea.GLOBAL,
			type: MapAreaType.PA_OECMS,
			resource: PP_LIVE_24_CPT3_KBA_OECMS.resource,
			allLayers: PP_LIVE_24_CPT3_KBA_OECMS.layers
		}
	]
	return {
		resources,
		areas: {
			[MapArea.GLOBAL]: {
				[MapRealm.ALL_KBAS]: {
					legends: [
						{
							legend: MapLegend.FULLY_COVERED,
							esriLayerNames: [
								EsriLayerId.KBA_FULLY_COVERED,
								EsriLayerId.KBA_FULLY_COVERED_OECMS
							],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT3_KBA_NAT.layers, EsriLayerId.KBA_FULLY_COVERED),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT3_KBA_OECMS.layers, EsriLayerId.KBA_FULLY_COVERED_OECMS)
							}
						},
						{
							legend: MapLegend.PARTIALLY_COVERED,
							esriLayerNames: [
								EsriLayerId.KBA_PARTIALLY_COVERED,
								EsriLayerId.KBA_PARTIALLY_COVERED_OECMS
							],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT3_KBA_NAT.layers, EsriLayerId.KBA_PARTIALLY_COVERED),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT3_KBA_OECMS.layers, EsriLayerId.KBA_PARTIALLY_COVERED_OECMS)
							}
						},
						{
							legend: MapLegend.NOT_COVERED,
							esriLayerNames: [
								EsriLayerId.KBA_NOT_COVERED,
								EsriLayerId.KBA_NOT_COVERED_OECMS
							],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT3_KBA_NAT.layers, EsriLayerId.KBA_NOT_COVERED),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT3_KBA_OECMS.layers, EsriLayerId.KBA_NOT_COVERED_OECMS)
							}
						}
					]
				}
			}
		}
	}
}
function ncpMapInfo(): MapInfo {
	const PP_LIVE_24_CPT4_NCP_NAT = getResourceTileInfo(DISPLAY_DATA_OF_MAP.NCP, MAP_RESOURCES.PP_LIVE_24_CPT4_NCP_NAT)
	const PP_LIVE_24_CPT4_NCP_NAT_OECMS = getResourceTileInfo(DISPLAY_DATA_OF_MAP.NCP, MAP_RESOURCES.PP_LIVE_24_CPT4_NCP_NAT_OECMS)
	const PP_LIVE_24_CPT4_NCP_REG = getResourceTileInfo(DISPLAY_DATA_OF_MAP.NCP, MAP_RESOURCES.PP_LIVE_24_CPT4_NCP_REG)
	const PP_LIVE_24_CPT4_NCP_REG_OECMS = getResourceTileInfo(DISPLAY_DATA_OF_MAP.NCP, MAP_RESOURCES.PP_LIVE_24_CPT4_NCP_REG_OECMS)
	const resources: Resource[] = [
		{
			id: MAP_RESOURCES.PP_LIVE_24_CPT4_NCP_NAT,
			area: MapArea.NATIONAL,
			type: MapAreaType.PA,
			resource: PP_LIVE_24_CPT4_NCP_NAT.resource,
			allLayers: PP_LIVE_24_CPT4_NCP_NAT.layers
		},
		{
			id: MAP_RESOURCES.PP_LIVE_24_CPT4_NCP_NAT_OECMS,
			area: MapArea.NATIONAL,
			type: MapAreaType.PA_OECMS,
			resource: PP_LIVE_24_CPT4_NCP_NAT_OECMS.resource,
			allLayers: PP_LIVE_24_CPT4_NCP_NAT_OECMS.layers
		},
		{
			id: MAP_RESOURCES.PP_LIVE_24_CPT4_NCP_REG,
			area: MapArea.SUB_REGIONAL,
			type: MapAreaType.PA,
			resource: PP_LIVE_24_CPT4_NCP_REG.resource,
			allLayers: PP_LIVE_24_CPT4_NCP_REG.layers
		},
		{
			id: MAP_RESOURCES.PP_LIVE_24_CPT4_NCP_REG_OECMS,
			area: MapArea.SUB_REGIONAL,
			type: MapAreaType.PA_OECMS,
			resource: PP_LIVE_24_CPT4_NCP_REG_OECMS.resource,
			allLayers: PP_LIVE_24_CPT4_NCP_REG_OECMS.layers
		}
	]
	function getPotential(pa: ReturnType<typeof getResourceTileInfo>, paOECMs: ReturnType<typeof getResourceTileInfo>): MapRealmInfo {
		return {
			legends: [
				{
					legend: MapLegend.LESS_THAN_TWENTY_PERCENT,
					esriLayerNames: [EsriLayerId.POTENTIAL_LESS_THEN_TWENTY],
					legendColours: {
						[MapAreaType.PA]: getColourFromEsriLayers(pa.layers, EsriLayerId.POTENTIAL_LESS_THEN_TWENTY),
						[MapAreaType.PA_OECMS]: getColourFromEsriLayers(paOECMs.layers, EsriLayerId.POTENTIAL_LESS_THEN_TWENTY)
					}
				},
				{
					legend: MapLegend.TWENTY_TO_FOURTY_PERCENT,
					esriLayerNames: [EsriLayerId.POTENTIAL_TWENTY_TO_FOURTY],
					legendColours: {
						[MapAreaType.PA]: getColourFromEsriLayers(pa.layers, EsriLayerId.POTENTIAL_TWENTY_TO_FOURTY),
						[MapAreaType.PA_OECMS]: getColourFromEsriLayers(paOECMs.layers, EsriLayerId.POTENTIAL_TWENTY_TO_FOURTY)
					}
				},
				{
					legend: MapLegend.FOURTY_TO_SIXTY_PERCENT,
					esriLayerNames: [EsriLayerId.POTENTIAL_FOURTY_TO_SIXTY],
					legendColours: {
						[MapAreaType.PA]: getColourFromEsriLayers(pa.layers, EsriLayerId.POTENTIAL_FOURTY_TO_SIXTY),
						[MapAreaType.PA_OECMS]: getColourFromEsriLayers(paOECMs.layers, EsriLayerId.POTENTIAL_FOURTY_TO_SIXTY)
					}
				},
				{
					legend: MapLegend.SIXTY_TO_EIGHTY_PERCENT,
					esriLayerNames: [EsriLayerId.POTENTIAL_SIXTY_TO_EIGHTY],
					legendColours: {
						[MapAreaType.PA]: getColourFromEsriLayers(pa.layers, EsriLayerId.POTENTIAL_SIXTY_TO_EIGHTY),
						[MapAreaType.PA_OECMS]: getColourFromEsriLayers(paOECMs.layers, EsriLayerId.POTENTIAL_SIXTY_TO_EIGHTY)
					}
				},
				{
					legend: MapLegend.EIGHTY_TO_THUNDARD_PERCENT,
					esriLayerNames: [EsriLayerId.POTENTIAL_LESS_THEN_HUNDARD],
					legendColours: {
						[MapAreaType.PA]: getColourFromEsriLayers(pa.layers, EsriLayerId.POTENTIAL_LESS_THEN_HUNDARD),
						[MapAreaType.PA_OECMS]: getColourFromEsriLayers(paOECMs.layers, EsriLayerId.POTENTIAL_LESS_THEN_HUNDARD)
					}
				}
			]
		}
	}
	function getRealized(pa: ReturnType<typeof getResourceTileInfo>, paOECMs: ReturnType<typeof getResourceTileInfo>): MapRealmInfo {
		return {
			legends: [
				{
					legend: MapLegend.LESS_THAN_TWENTY_PERCENT,
					esriLayerNames: [EsriLayerId.REALISED_LESS_THEN_TWENTY],
					legendColours: {
						[MapAreaType.PA]: getColourFromEsriLayers(pa.layers, EsriLayerId.REALISED_LESS_THEN_TWENTY),
						[MapAreaType.PA_OECMS]: getColourFromEsriLayers(paOECMs.layers, EsriLayerId.REALISED_LESS_THEN_TWENTY)
					}
				},
				{
					legend: MapLegend.TWENTY_TO_FOURTY_PERCENT,
					esriLayerNames: [EsriLayerId.REALISED_TWENTY_TO_FOURTY],
					legendColours: {
						[MapAreaType.PA]: getColourFromEsriLayers(pa.layers, EsriLayerId.REALISED_TWENTY_TO_FOURTY),
						[MapAreaType.PA_OECMS]: getColourFromEsriLayers(paOECMs.layers, EsriLayerId.REALISED_TWENTY_TO_FOURTY)
					}
				},
				{
					legend: MapLegend.FOURTY_TO_SIXTY_PERCENT,
					esriLayerNames: [EsriLayerId.REALISED_FOURTY_TO_SIXTY],
					legendColours: {
						[MapAreaType.PA]: getColourFromEsriLayers(pa.layers, EsriLayerId.REALISED_FOURTY_TO_SIXTY),
						[MapAreaType.PA_OECMS]: getColourFromEsriLayers(paOECMs.layers, EsriLayerId.REALISED_FOURTY_TO_SIXTY)
					}
				},
				{
					legend: MapLegend.SIXTY_TO_EIGHTY_PERCENT,
					esriLayerNames: [EsriLayerId.REALISED_SIXTY_TO_EIGHTY],
					legendColours: {
						[MapAreaType.PA]: getColourFromEsriLayers(pa.layers, EsriLayerId.REALISED_SIXTY_TO_EIGHTY),
						[MapAreaType.PA_OECMS]: getColourFromEsriLayers(paOECMs.layers, EsriLayerId.REALISED_SIXTY_TO_EIGHTY)
					}
				},
				{
					legend: MapLegend.EIGHTY_TO_THUNDARD_PERCENT,
					esriLayerNames: [EsriLayerId.REALISED_LESS_THEN_HUNDARD],
					legendColours: {
						[MapAreaType.PA]: getColourFromEsriLayers(pa.layers, EsriLayerId.REALISED_LESS_THEN_HUNDARD),
						[MapAreaType.PA_OECMS]: getColourFromEsriLayers(paOECMs.layers, EsriLayerId.REALISED_LESS_THEN_HUNDARD)
					}
				}
			]
		}
	}

	return {
		resources,
		areas: {
			[MapArea.NATIONAL]: {
				[MapRealm.POTENTIAL]: getPotential(PP_LIVE_24_CPT4_NCP_NAT, PP_LIVE_24_CPT4_NCP_NAT_OECMS),
				[MapRealm.REALIZED]: getRealized(PP_LIVE_24_CPT4_NCP_NAT, PP_LIVE_24_CPT4_NCP_NAT_OECMS)
			},
			[MapArea.SUB_REGIONAL]: {
				[MapRealm.POTENTIAL]: getPotential(PP_LIVE_24_CPT4_NCP_REG, PP_LIVE_24_CPT4_NCP_REG_OECMS),
				[MapRealm.REALIZED]: getRealized(PP_LIVE_24_CPT4_NCP_REG, PP_LIVE_24_CPT4_NCP_REG_OECMS)
			}
		}
	}
}
function extendPaOecmCoverageEcoregionsMapInfo(): MapInfo {
	const PP_LIVE_24_CPT5_ECO_TERR_WDPAS = getResourceTileInfo(DISPLAY_DATA_OF_MAP.EXTENT_PA_OECM_COVERAGE_ECOREGIONS, MAP_RESOURCES.PP_LIVE_24_CPT5_ECO_TERR_WDPAS)
	const PP_LIVE_24_CPT5_ECO_MARINE_WDPAS = getResourceTileInfo(DISPLAY_DATA_OF_MAP.EXTENT_PA_OECM_COVERAGE_ECOREGIONS, MAP_RESOURCES.PP_LIVE_24_CPT5_ECO_MARINE_WDPAS)
	const PP_LIVE_24_CPT5_ECO_FRESH_WDPAS = getResourceTileInfo(DISPLAY_DATA_OF_MAP.EXTENT_PA_OECM_COVERAGE_ECOREGIONS, MAP_RESOURCES.PP_LIVE_24_CPT5_ECO_FRESH_WDPAS)
	const PP_LIVE_24_CPT5_ECO_TERR_OECMS = getResourceTileInfo(DISPLAY_DATA_OF_MAP.EXTENT_PA_OECM_COVERAGE_ECOREGIONS, MAP_RESOURCES.PP_LIVE_24_CPT5_ECO_TERR_OECMS)
	const PP_LIVE_24_CPT5_ECO_MARINE_OECMS = getResourceTileInfo(DISPLAY_DATA_OF_MAP.EXTENT_PA_OECM_COVERAGE_ECOREGIONS, MAP_RESOURCES.PP_LIVE_24_CPT5_ECO_MARINE_OECMS)
	const PP_LIVE_24_CPT5_ECO_FRESH_OECMS = getResourceTileInfo(DISPLAY_DATA_OF_MAP.EXTENT_PA_OECM_COVERAGE_ECOREGIONS, MAP_RESOURCES.PP_LIVE_24_CPT5_ECO_FRESH_OECMS)

	const resources: Resource[] = [
		{
			id: MAP_RESOURCES.PP_LIVE_24_CPT5_ECO_TERR_WDPAS,
			area: MapArea.GLOBAL,
			type: MapAreaType.PA,
			resource: PP_LIVE_24_CPT5_ECO_TERR_WDPAS.resource,
			allLayers: PP_LIVE_24_CPT5_ECO_TERR_WDPAS.layers
		},
		{
			id: MAP_RESOURCES.PP_LIVE_24_CPT5_ECO_MARINE_WDPAS,
			area: MapArea.GLOBAL,
			type: MapAreaType.PA,
			resource: PP_LIVE_24_CPT5_ECO_MARINE_WDPAS.resource,
			allLayers: PP_LIVE_24_CPT5_ECO_MARINE_WDPAS.layers
		},
		{
			id: MAP_RESOURCES.PP_LIVE_24_CPT5_ECO_FRESH_WDPAS,
			area: MapArea.GLOBAL,
			type: MapAreaType.PA,
			resource: PP_LIVE_24_CPT5_ECO_FRESH_WDPAS.resource,
			allLayers: PP_LIVE_24_CPT5_ECO_FRESH_WDPAS.layers
		},
		{
			id: MAP_RESOURCES.PP_LIVE_24_CPT5_ECO_TERR_OECMS,
			area: MapArea.GLOBAL,
			type: MapAreaType.PA_OECMS,
			resource: PP_LIVE_24_CPT5_ECO_TERR_OECMS.resource,
			allLayers: PP_LIVE_24_CPT5_ECO_TERR_OECMS.layers
		},
		{
			id: MAP_RESOURCES.PP_LIVE_24_CPT5_ECO_MARINE_OECMS,
			area: MapArea.GLOBAL,
			type: MapAreaType.PA_OECMS,
			resource: PP_LIVE_24_CPT5_ECO_MARINE_OECMS.resource,
			allLayers: PP_LIVE_24_CPT5_ECO_MARINE_OECMS.layers
		},
		{
			id: MAP_RESOURCES.PP_LIVE_24_CPT5_ECO_FRESH_OECMS,
			area: MapArea.GLOBAL,
			type: MapAreaType.PA_OECMS,
			resource: PP_LIVE_24_CPT5_ECO_FRESH_OECMS.resource,
			allLayers: PP_LIVE_24_CPT5_ECO_FRESH_OECMS.layers	}
	]
	return {
		resources,
		areas: {
			[MapArea.GLOBAL]: {
				[MapRealm.TERRESTRIAL]: {
					legends: [
						{
							legend: MapLegend.LESS_THAN_FIVE_PERCENT,
							esriLayerNames: [EsriLayerId.TERRESTRIAL_ECOREGIONS_LESS_THEN_FIVE],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT5_ECO_TERR_WDPAS.layers, EsriLayerId.TERRESTRIAL_ECOREGIONS_LESS_THEN_FIVE),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT5_ECO_TERR_OECMS.layers, EsriLayerId.TERRESTRIAL_ECOREGIONS_LESS_THEN_FIVE)
							}
						},
						{
							legend: MapLegend.FIVE_TO_SIXTEEN_PERCENT,
							esriLayerNames: [EsriLayerId.TERRESTRIAL_ECOREGIONS_FIVE_TO_SIXTEEN],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT5_ECO_TERR_WDPAS.layers, EsriLayerId.TERRESTRIAL_ECOREGIONS_FIVE_TO_SIXTEEN),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT5_ECO_TERR_OECMS.layers, EsriLayerId.TERRESTRIAL_ECOREGIONS_FIVE_TO_SIXTEEN)
							}
						},
						{
							legend: MapLegend.SIXTEEN_TO_TWENTY_NINE_PERCENT,
							esriLayerNames: [EsriLayerId.TERRESTRIAL_ECOREGIONS_SIXTEEN_TO_TWENTY_NINE],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT5_ECO_TERR_WDPAS.layers, EsriLayerId.TERRESTRIAL_ECOREGIONS_SIXTEEN_TO_TWENTY_NINE),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT5_ECO_TERR_OECMS.layers, EsriLayerId.TERRESTRIAL_ECOREGIONS_SIXTEEN_TO_TWENTY_NINE)
							}
						},
						{
							legend: MapLegend.MORE_THAN_OR_EQUAL_THIRTY_PERCENT,
							esriLayerNames: [EsriLayerId.TERRESTRIAL_ECOREGIONS_MORE_THAN_OR_EQUAL_THIRTY],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT5_ECO_TERR_WDPAS.layers, EsriLayerId.TERRESTRIAL_ECOREGIONS_MORE_THAN_OR_EQUAL_THIRTY),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT5_ECO_TERR_OECMS.layers, EsriLayerId.TERRESTRIAL_ECOREGIONS_MORE_THAN_OR_EQUAL_THIRTY)
							}
						}
					]
				},
				[MapRealm.MARINE]: {
					legends: [
						{
							legend: MapLegend.LESS_THAN_TEN_PERCENT,
							esriLayerNames: [EsriLayerId.MARINE_ECOREGIONS_LESS_THEN_TEN],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT5_ECO_MARINE_WDPAS.layers, EsriLayerId.MARINE_ECOREGIONS_LESS_THEN_TEN),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT5_ECO_MARINE_OECMS.layers, EsriLayerId.MARINE_ECOREGIONS_LESS_THEN_TEN)
							}

						},
						{
							legend: MapLegend.TEN_TO_NINETEEN_PERCENT,
							esriLayerNames: [EsriLayerId.MARINE_ECOREGIONS_TEN_TO_NINETEEN],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT5_ECO_MARINE_WDPAS.layers, EsriLayerId.MARINE_ECOREGIONS_TEN_TO_NINETEEN),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT5_ECO_MARINE_OECMS.layers, EsriLayerId.MARINE_ECOREGIONS_TEN_TO_NINETEEN)
							}

						},
						{
							legend: MapLegend.NINETEEN_TO_TWENTY_NINE_PERCENT,
							esriLayerNames: [EsriLayerId.MARINE_ECOREGIONS_NINETEEN_TO_TWENTY_NINE],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT5_ECO_MARINE_WDPAS.layers, EsriLayerId.MARINE_ECOREGIONS_NINETEEN_TO_TWENTY_NINE),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT5_ECO_MARINE_OECMS.layers, EsriLayerId.MARINE_ECOREGIONS_NINETEEN_TO_TWENTY_NINE)
							}

						},
						{
							legend: MapLegend.MORE_THAN_OR_EQUAL_THIRTY_PERCENT,
							esriLayerNames: [EsriLayerId.MARINE_ECOREGIONS_MORE_THAN_OR_EQUAL_THIRTY],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT5_ECO_MARINE_WDPAS.layers, EsriLayerId.MARINE_ECOREGIONS_MORE_THAN_OR_EQUAL_THIRTY),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT5_ECO_MARINE_OECMS.layers, EsriLayerId.MARINE_ECOREGIONS_MORE_THAN_OR_EQUAL_THIRTY)
							}

						}
					]
				},
				[MapRealm.FRESHWATER]: {
					legends: [
						{
							legend: MapLegend.LESS_THAN_FIVE_PERCENT,
							esriLayerNames: [EsriLayerId.FRESHWATER_ECOREGIONS_LESS_THEN_FIVE],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT5_ECO_FRESH_WDPAS.layers, EsriLayerId.FRESHWATER_ECOREGIONS_LESS_THEN_FIVE),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT5_ECO_FRESH_OECMS.layers, EsriLayerId.FRESHWATER_ECOREGIONS_LESS_THEN_FIVE)
							}
						},
						{
							legend: MapLegend.FIVE_TO_SIXTEEN_PERCENT,
							esriLayerNames: [EsriLayerId.FRESHWATER_ECOREGIONS_FIVE_TO_SIXTEEN],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT5_ECO_FRESH_WDPAS.layers, EsriLayerId.FRESHWATER_ECOREGIONS_FIVE_TO_SIXTEEN),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT5_ECO_FRESH_OECMS.layers, EsriLayerId.FRESHWATER_ECOREGIONS_FIVE_TO_SIXTEEN)
							}
						},
						{
							legend: MapLegend.SIXTEEN_TO_TWENTY_NINE_PERCENT,
							esriLayerNames: [EsriLayerId.FRESHWATER_ECOREGIONS_SIXTEEN_TO_TWENTY_NINE],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT5_ECO_FRESH_WDPAS.layers, EsriLayerId.FRESHWATER_ECOREGIONS_SIXTEEN_TO_TWENTY_NINE),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT5_ECO_FRESH_OECMS.layers, EsriLayerId.FRESHWATER_ECOREGIONS_SIXTEEN_TO_TWENTY_NINE)
							}
						},
						{
							legend: MapLegend.MORE_THAN_OR_EQUAL_THIRTY_PERCENT,
							esriLayerNames: [EsriLayerId.FRESHWATER_ECOREGIONS_MORE_THAN_OR_EQUAL_THIRTY],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT5_ECO_FRESH_WDPAS.layers, EsriLayerId.FRESHWATER_ECOREGIONS_MORE_THAN_OR_EQUAL_THIRTY),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT5_ECO_FRESH_OECMS.layers, EsriLayerId.FRESHWATER_ECOREGIONS_MORE_THAN_OR_EQUAL_THIRTY)
							}
						}
					]
				}
			}
		}
	}
}
function spiMapInfo(): MapInfo {
	const PP_LIVE_24_CPT5_SPI = getResourceTileInfo(DISPLAY_DATA_OF_MAP.SPI, MAP_RESOURCES.PP_LIVE_24_CPT5_SPI)
	const PP_LIVE_24_CPT5_SPI_OECMS = getResourceTileInfo(DISPLAY_DATA_OF_MAP.SPI, MAP_RESOURCES.PP_LIVE_24_CPT5_SPI_OECMS)
	const PP_LIVE_24_CPT5_SPI_REG = getResourceTileInfo(DISPLAY_DATA_OF_MAP.SPI, MAP_RESOURCES.PP_LIVE_24_CPT5_SPI_REG)
	const PP_LIVE_24_CPT5_SPI_REG_OECMS = getResourceTileInfo(DISPLAY_DATA_OF_MAP.SPI, MAP_RESOURCES.PP_LIVE_24_CPT5_SPI_REG_OECMS)
	const resources: Resource[] = [
		{
			id: MAP_RESOURCES.PP_LIVE_24_CPT5_SPI,
			area: MapArea.NATIONAL,
			type: MapAreaType.PA,
			resource: PP_LIVE_24_CPT5_SPI.resource,
			allLayers: PP_LIVE_24_CPT5_SPI.layers
		},
		{
			id: MAP_RESOURCES.PP_LIVE_24_CPT5_SPI_OECMS,
			area: MapArea.NATIONAL,
			type: MapAreaType.PA_OECMS,
			resource: PP_LIVE_24_CPT5_SPI_OECMS.resource,
			allLayers: PP_LIVE_24_CPT5_SPI_OECMS.layers
		},
		{
			id: MAP_RESOURCES.PP_LIVE_24_CPT5_SPI_REG,
			area: MapArea.SUB_REGIONAL,
			type: MapAreaType.PA,
			resource: PP_LIVE_24_CPT5_SPI_REG.resource,
			allLayers: PP_LIVE_24_CPT5_SPI_REG.layers
		},
		{
			id: MAP_RESOURCES.PP_LIVE_24_CPT5_SPI_REG_OECMS,
			area: MapArea.SUB_REGIONAL,
			type: MapAreaType.PA_OECMS,
			resource: PP_LIVE_24_CPT5_SPI_REG_OECMS.resource,
			allLayers: PP_LIVE_24_CPT5_SPI_REG_OECMS.layers
		}
	]
	return {
		resources,
		areas: {
			[MapArea.NATIONAL]: {
				[MapRealm.TERRESTRIAL]: {
					legends: [
						{
							legend: MapLegend.LESS_THAN_TWENTY_PERCENT,
							esriLayerNames: [EsriLayerId.SPI_LAND_LESS_THAN_TWENTY],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT5_SPI.layers, EsriLayerId.SPI_LAND_LESS_THAN_TWENTY),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT5_SPI_OECMS.layers, EsriLayerId.SPI_LAND_LESS_THAN_TWENTY)
							}
						},
						{
							legend: MapLegend.TWENTY_TO_FOURTY_PERCENT,
							esriLayerNames: [EsriLayerId.SPI_LAND_TWENTY_TO_FOURTY],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT5_SPI.layers, EsriLayerId.SPI_LAND_TWENTY_TO_FOURTY),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT5_SPI_OECMS.layers, EsriLayerId.SPI_LAND_TWENTY_TO_FOURTY)
							}
						},
						{
							legend: MapLegend.FOURTY_TO_SIXTY_PERCENT,
							esriLayerNames: [EsriLayerId.SPI_LAND_FOURTY_TO_SIXTY],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT5_SPI.layers, EsriLayerId.SPI_LAND_FOURTY_TO_SIXTY),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT5_SPI_OECMS.layers, EsriLayerId.SPI_LAND_FOURTY_TO_SIXTY)
							}
						},
						{
							legend: MapLegend.SIXTY_TO_EIGHTY_PERCENT,
							esriLayerNames: [EsriLayerId.SPI_LAND_SIXTY_To_EIGHTY],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT5_SPI.layers, EsriLayerId.SPI_LAND_SIXTY_To_EIGHTY),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT5_SPI_OECMS.layers, EsriLayerId.SPI_LAND_SIXTY_To_EIGHTY)
							}
						},
						{
							legend: MapLegend.EIGHTY_TO_THUNDARD_PERCENT,
							esriLayerNames: [EsriLayerId.SPI_LAND_MORE_THAN_EIGHTY],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT5_SPI.layers, EsriLayerId.SPI_LAND_MORE_THAN_EIGHTY),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT5_SPI_OECMS.layers, EsriLayerId.SPI_LAND_MORE_THAN_EIGHTY)
							}
						},
						{
							legend: MapLegend.DATA_DEFICITENT,
							esriLayerNames: [
								EsriLayerId.SPI_LAND_DATA_DEFICIENT
							],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT5_SPI.layers, EsriLayerId.SPI_LAND_DATA_DEFICIENT),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT5_SPI_OECMS.layers, EsriLayerId.SPI_LAND_DATA_DEFICIENT)
							}
						}
					]
				},
				[MapRealm.MARINE]: {
					legends: [
						{
							legend: MapLegend.LESS_THAN_TWENTY_PERCENT,
							esriLayerNames: [EsriLayerId.SPI_MARINE_LESS_THAN_TWENTY],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT5_SPI.layers, EsriLayerId.SPI_MARINE_LESS_THAN_TWENTY),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT5_SPI_OECMS.layers, EsriLayerId.SPI_MARINE_LESS_THAN_TWENTY)
							}
						},
						{
							legend: MapLegend.TWENTY_TO_FOURTY_PERCENT,
							esriLayerNames: [EsriLayerId.SPI_MARINE_TWENTY_TO_FOURTY],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT5_SPI.layers, EsriLayerId.SPI_MARINE_TWENTY_TO_FOURTY),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT5_SPI_OECMS.layers, EsriLayerId.SPI_MARINE_TWENTY_TO_FOURTY)
							}
						},
						{
							legend: MapLegend.FOURTY_TO_SIXTY_PERCENT,
							esriLayerNames: [EsriLayerId.SPI_MARINE_FOURTY_TO_SIXTY],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT5_SPI.layers, EsriLayerId.SPI_MARINE_FOURTY_TO_SIXTY),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT5_SPI_OECMS.layers, EsriLayerId.SPI_MARINE_FOURTY_TO_SIXTY)
							}
						},
						{
							legend: MapLegend.SIXTY_TO_EIGHTY_PERCENT,
							esriLayerNames: [EsriLayerId.SPI_MARINE_SIXTY_To_EIGHTY],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT5_SPI.layers, EsriLayerId.SPI_MARINE_SIXTY_To_EIGHTY),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT5_SPI_OECMS.layers, EsriLayerId.SPI_MARINE_SIXTY_To_EIGHTY)
							}
						},
						{
							legend: MapLegend.EIGHTY_TO_THUNDARD_PERCENT,
							esriLayerNames: [EsriLayerId.SPI_MARINE_EIGHTY_TO_HUNDARD],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT5_SPI.layers, EsriLayerId.SPI_MARINE_EIGHTY_TO_HUNDARD),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT5_SPI_OECMS.layers, EsriLayerId.SPI_MARINE_EIGHTY_TO_HUNDARD)
							}
						},
						{
							legend: MapLegend.DATA_DEFICITENT,
							esriLayerNames: [
								EsriLayerId.SPI_MARINE_DATA_DEFICIENT
							],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT5_SPI.layers, EsriLayerId.SPI_MARINE_DATA_DEFICIENT),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT5_SPI_OECMS.layers, EsriLayerId.SPI_MARINE_DATA_DEFICIENT)
							}
						}
					]
				}
			},
			[MapArea.SUB_REGIONAL]: {
				[MapRealm.TERRESTRIAL]: {
					legends: [
						{
							legend: MapLegend.LESS_THAN_TWENTY_PERCENT,
							esriLayerNames: [EsriLayerId.SPI_LAND_LESS_THAN_TWENTY_SUBREGION],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT5_SPI_REG.layers, EsriLayerId.SPI_LAND_LESS_THAN_TWENTY_SUBREGION),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT5_SPI_REG_OECMS.layers, EsriLayerId.SPI_LAND_LESS_THAN_TWENTY_SUBREGION)
							}
						},
						{
							legend: MapLegend.TWENTY_TO_FOURTY_PERCENT,
							esriLayerNames: [EsriLayerId.SPI_LAND_TWENTY_TO_FOURTY_SUBREGION],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT5_SPI_REG.layers, EsriLayerId.SPI_LAND_TWENTY_TO_FOURTY_SUBREGION),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT5_SPI_REG.layers, EsriLayerId.SPI_LAND_TWENTY_TO_FOURTY_SUBREGION)
							}
						},
						{
							legend: MapLegend.FOURTY_TO_SIXTY_PERCENT,
							esriLayerNames: [EsriLayerId.SPI_LAND_FOURTY_TO_SIXTY_SUBREGION],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT5_SPI_REG.layers, EsriLayerId.SPI_LAND_FOURTY_TO_SIXTY_SUBREGION),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT5_SPI_REG.layers, EsriLayerId.SPI_LAND_FOURTY_TO_SIXTY_SUBREGION)
							}
						},
						{
							legend: MapLegend.SIXTY_TO_EIGHTY_PERCENT,
							esriLayerNames: [EsriLayerId.SPI_LAND_SIXTY_To_EIGHTY_SUBREGION],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT5_SPI_REG.layers, EsriLayerId.SPI_LAND_SIXTY_To_EIGHTY_SUBREGION),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT5_SPI_REG.layers, EsriLayerId.SPI_LAND_SIXTY_To_EIGHTY_SUBREGION)
							}
						},
						{
							legend: MapLegend.EIGHTY_TO_THUNDARD_PERCENT,
							esriLayerNames: [EsriLayerId.SPI_LAND_MORE_THAN_EIGHTY_SUBREGION],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT5_SPI_REG.layers, EsriLayerId.SPI_LAND_MORE_THAN_EIGHTY_SUBREGION),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT5_SPI_REG.layers, EsriLayerId.SPI_LAND_MORE_THAN_EIGHTY_SUBREGION)
							}
						},
						{
							legend: MapLegend.DATA_DEFICITENT,
							esriLayerNames: [
								EsriLayerId.SPI_LAND_DATA_DEFICIENT_SUBREGION
							],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT5_SPI_REG.layers, EsriLayerId.SPI_LAND_DATA_DEFICIENT_SUBREGION),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT5_SPI_REG_OECMS.layers, EsriLayerId.SPI_LAND_DATA_DEFICIENT_SUBREGION)
							}
						}
					]
				},
				[MapRealm.MARINE]: {
					legends: [
						{
							legend: MapLegend.LESS_THAN_TWENTY_PERCENT,
							esriLayerNames: [EsriLayerId.SPI_MARINE_LESS_THAN_TWENTY_SUBREGION],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT5_SPI_REG.layers, EsriLayerId.SPI_MARINE_LESS_THAN_TWENTY_SUBREGION),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT5_SPI_REG_OECMS.layers, EsriLayerId.SPI_MARINE_LESS_THAN_TWENTY_SUBREGION)
							}
						},
						{
							legend: MapLegend.TWENTY_TO_FOURTY_PERCENT,
							esriLayerNames: [EsriLayerId.SPI_MARINE_TWENTY_TO_FOURTY_SUBREGION],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT5_SPI_REG.layers, EsriLayerId.SPI_MARINE_TWENTY_TO_FOURTY_SUBREGION),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT5_SPI_REG_OECMS.layers, EsriLayerId.SPI_MARINE_TWENTY_TO_FOURTY_SUBREGION)
							}
						},
						{
							legend: MapLegend.FOURTY_TO_SIXTY_PERCENT,
							esriLayerNames: [EsriLayerId.SPI_MARINE_FOURTY_TO_SIXTY_SUBREGION],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT5_SPI_REG.layers, EsriLayerId.SPI_MARINE_FOURTY_TO_SIXTY_SUBREGION),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT5_SPI_REG_OECMS.layers, EsriLayerId.SPI_MARINE_FOURTY_TO_SIXTY_SUBREGION)
							}
						},
						{
							legend: MapLegend.SIXTY_TO_EIGHTY_PERCENT,
							esriLayerNames: [EsriLayerId.SPI_MARINE_SIXTY_To_EIGHTY_SUBREGION],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT5_SPI_REG.layers, EsriLayerId.SPI_MARINE_SIXTY_To_EIGHTY_SUBREGION),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT5_SPI_REG_OECMS.layers, EsriLayerId.SPI_MARINE_SIXTY_To_EIGHTY_SUBREGION)
							}
						},
						{
							legend: MapLegend.EIGHTY_TO_THUNDARD_PERCENT,
							esriLayerNames: [EsriLayerId.SPI_MARINE_MORE_THAN_EIGHTY_SUBREGION],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT5_SPI_REG.layers, EsriLayerId.SPI_MARINE_MORE_THAN_EIGHTY_SUBREGION),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT5_SPI_REG_OECMS.layers, EsriLayerId.SPI_MARINE_MORE_THAN_EIGHTY_SUBREGION)
							}
						},
						{
							legend: MapLegend.DATA_DEFICITENT,
							esriLayerNames: [
								EsriLayerId.SPI_MARINE_DATA_DEFICIENT_SUBREGION
							],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT5_SPI_REG.layers, EsriLayerId.SPI_MARINE_DATA_DEFICIENT_SUBREGION),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT5_SPI_REG_OECMS.layers, EsriLayerId.SPI_MARINE_DATA_DEFICIENT_SUBREGION)
							}
						}
					]
				}
			}
		}
	}
}
function proNetMapInfo(): MapInfo {
	const PP_LIVE_24_CPT6_PRONET_NAT = getResourceTileInfo(DISPLAY_DATA_OF_MAP.PRO_NET, MAP_RESOURCES.PP_LIVE_24_CPT6_PRONET_NAT)
	const PP_LIVE_24_CPT6_PRONET_NAT_OECMS = getResourceTileInfo(DISPLAY_DATA_OF_MAP.PRO_NET, MAP_RESOURCES.PP_LIVE_24_CPT6_PRONET_NAT_OECMS)
	const PP_LIVE_24_CPT6_PRONET_REG = getResourceTileInfo(DISPLAY_DATA_OF_MAP.PRO_NET, MAP_RESOURCES.PP_LIVE_24_CPT6_PRONET_REG)
	const PP_LIVE_24_CPT6_PRONET_REG_OECMS = getResourceTileInfo(DISPLAY_DATA_OF_MAP.PRO_NET, MAP_RESOURCES.PP_LIVE_24_CPT6_PRONET_REG_OECMS)

	const resources = [
		{
			id: MAP_RESOURCES.PP_LIVE_24_CPT6_PRONET_NAT,
			area: MapArea.NATIONAL,
			type: MapAreaType.PA,
			resource: PP_LIVE_24_CPT6_PRONET_NAT.resource,
			allLayers: PP_LIVE_24_CPT6_PRONET_NAT.layers
		},
		{
			id: MAP_RESOURCES.PP_LIVE_24_CPT6_PRONET_NAT_OECMS,
			area: MapArea.NATIONAL,
			type: MapAreaType.PA_OECMS,
			resource: PP_LIVE_24_CPT6_PRONET_NAT_OECMS.resource,
			allLayers: PP_LIVE_24_CPT6_PRONET_NAT_OECMS.layers
		},
		{
			id: MAP_RESOURCES.PP_LIVE_24_CPT6_PRONET_REG,
			area: MapArea.SUB_REGIONAL,
			type: MapAreaType.PA,
			resource: PP_LIVE_24_CPT6_PRONET_REG.resource,
			allLayers: PP_LIVE_24_CPT6_PRONET_REG.layers
		},
		{
			id: MAP_RESOURCES.PP_LIVE_24_CPT6_PRONET_REG_OECMS,
			area: MapArea.SUB_REGIONAL,
			type: MapAreaType.PA_OECMS,
			resource: PP_LIVE_24_CPT6_PRONET_REG_OECMS.resource,
			allLayers: PP_LIVE_24_CPT6_PRONET_REG_OECMS.layers
		}
	]
	return {
		resources,
		areas: {
			[MapArea.NATIONAL]: {
				[MapRealm.TERRESTRIAL]: {
					legends: [
						{
							legend: MapLegend.LESS_THAN_ZERO_POINT_TWO,
							esriLayerNames: [EsriLayerId.PRONET_LESS_THAN_ZERO_POINT_TWO],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PRONET_NAT.layers, EsriLayerId.PRONET_LESS_THAN_ZERO_POINT_TWO),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PRONET_NAT_OECMS.layers, EsriLayerId.PRONET_LESS_THAN_ZERO_POINT_TWO)
							}
						},
						{
							legend: MapLegend.ZERO_POINT_TWO_TO_ZERO_POINT_FOUR,
							esriLayerNames: [EsriLayerId.PRONET_ZERO_POINT_TWO_TO_ZERO_POINT_FOUR],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PRONET_NAT.layers, EsriLayerId.PRONET_ZERO_POINT_TWO_TO_ZERO_POINT_FOUR),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PRONET_NAT_OECMS.layers, EsriLayerId.PRONET_ZERO_POINT_TWO_TO_ZERO_POINT_FOUR)
							}
						},
						{
							legend: MapLegend.ZERO_POINT_FOUR_TO_ZERO_POINT_SIX,
							esriLayerNames: [EsriLayerId.PRONET_ZERO_POINT_FOUR_TO_ZERO_POINT_SIX],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PRONET_NAT.layers, EsriLayerId.PRONET_ZERO_POINT_FOUR_TO_ZERO_POINT_SIX),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PRONET_NAT_OECMS.layers, EsriLayerId.PRONET_ZERO_POINT_FOUR_TO_ZERO_POINT_SIX)
							}
						},
						{
							legend: MapLegend.ZERO_POINT_SIX_TO_ZERO_POINT_EIGHT,
							esriLayerNames: [EsriLayerId.PRONET_ZERO_POINT_SIX_TO_ZERO_POINT_EIGHT],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PRONET_NAT.layers, EsriLayerId.PRONET_ZERO_POINT_SIX_TO_ZERO_POINT_EIGHT),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PRONET_NAT_OECMS.layers, EsriLayerId.PRONET_ZERO_POINT_SIX_TO_ZERO_POINT_EIGHT)
							}
						},
						{
							legend: MapLegend.LESS_THAN_ONE,
							esriLayerNames: [EsriLayerId.PRONET_COUNTRIES_LESS_THAN_ONE],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PRONET_NAT.layers, EsriLayerId.PRONET_COUNTRIES_LESS_THAN_ONE),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PRONET_NAT_OECMS.layers, EsriLayerId.PRONET_COUNTRIES_LESS_THAN_ONE)
							}
						},
						{
							legend: MapLegend.DATA_DEFICITENT,
							esriLayerNames: [EsriLayerId.PRONET_DATA_DEFICIENT],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PRONET_NAT.layers, EsriLayerId.PRONET_DATA_DEFICIENT),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PRONET_NAT_OECMS.layers, EsriLayerId.PRONET_DATA_DEFICIENT)
							}
						}
					]
				}
			},
			[MapArea.SUB_REGIONAL]: {
				[MapRealm.TERRESTRIAL]: {
					legends: [
						{
							legend: MapLegend.LESS_THAN_ZERO_POINT_TWO,
							esriLayerNames: [
								EsriLayerId.PRONET_LESS_THAN_ZERO_POINT_TWO_REG
							],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PRONET_REG.layers, EsriLayerId.PRONET_LESS_THAN_ZERO_POINT_TWO_REG),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PRONET_REG_OECMS.layers, EsriLayerId.PRONET_LESS_THAN_ZERO_POINT_TWO_REG)
							}
						},
						{
							legend: MapLegend.ZERO_POINT_TWO_TO_ZERO_POINT_FOUR,
							esriLayerNames: [
								EsriLayerId.PRONET_ZERO_POINT_TWO_TO_ZERO_POINT_FOUR_REG
							],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PRONET_REG.layers, EsriLayerId.PRONET_ZERO_POINT_TWO_TO_ZERO_POINT_FOUR_REG),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PRONET_REG_OECMS.layers, EsriLayerId.PRONET_ZERO_POINT_TWO_TO_ZERO_POINT_FOUR_REG)
							}
						},
						{
							legend: MapLegend.ZERO_POINT_FOUR_TO_ZERO_POINT_SIX,
							esriLayerNames: [
								EsriLayerId.PRONET_ZERO_POINT_FOUR_TO_ZERO_POINT_SIX_REG
							],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PRONET_REG.layers, EsriLayerId.PRONET_ZERO_POINT_FOUR_TO_ZERO_POINT_SIX_REG),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PRONET_REG_OECMS.layers, EsriLayerId.PRONET_ZERO_POINT_FOUR_TO_ZERO_POINT_SIX_REG)
							}
						},
						{
							legend: MapLegend.ZERO_POINT_SIX_TO_ZERO_POINT_EIGHT,
							esriLayerNames: [
								EsriLayerId.PRONET_ZERO_POINT_SIX_TO_ZERO_POINT_EIGHT_REG
							],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PRONET_REG.layers, EsriLayerId.PRONET_ZERO_POINT_SIX_TO_ZERO_POINT_EIGHT_REG),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PRONET_REG_OECMS.layers, EsriLayerId.PRONET_ZERO_POINT_SIX_TO_ZERO_POINT_EIGHT_REG)
							}
						},
						{
							legend: MapLegend.LESS_THAN_ONE,
							esriLayerNames: [
								EsriLayerId.PRONET_LESS_THAN_ONE_REG
							],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PRONET_REG.layers, EsriLayerId.PRONET_LESS_THAN_ONE_REG),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PRONET_REG_OECMS.layers, EsriLayerId.PRONET_LESS_THAN_ONE_REG)
							}
						},
						{
							legend: MapLegend.DATA_DEFICITENT,
							esriLayerNames: [
								EsriLayerId.PRONET_DATA_DEFICIENT_REG
							],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PRONET_REG.layers, EsriLayerId.PRONET_DATA_DEFICIENT_REG),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PRONET_REG_OECMS.layers, EsriLayerId.PRONET_DATA_DEFICIENT_REG)
							}
						}
					]
				}
			}
		}
	}
}
function paiMapInfo(): MapInfo {
	const PP_LIVE_24_CPT3_KBA_NAT = getResourceTileInfo(DISPLAY_DATA_OF_MAP.PAI, MAP_RESOURCES.PP_LIVE_24_CPT6_PAI_NAT)
	const PP_LIVE_24_CPT6_PAI_REG = getResourceTileInfo(DISPLAY_DATA_OF_MAP.PAI, MAP_RESOURCES.PP_LIVE_24_CPT6_PAI_REG)
	const resources: Resource[] = [
		{
			id: MAP_RESOURCES.PP_LIVE_24_CPT6_PAI_NAT,
			area: MapArea.NATIONAL,
			type: MapAreaType.PA_OECMS,
			resource: PP_LIVE_24_CPT3_KBA_NAT.resource,
			allLayers: PP_LIVE_24_CPT3_KBA_NAT.layers
		},
		{
			id: MAP_RESOURCES.PP_LIVE_24_CPT6_PAI_REG,
			area: MapArea.SUB_REGIONAL,
			type: MapAreaType.PA_OECMS,
			resource: PP_LIVE_24_CPT6_PAI_REG.resource,
			allLayers: PP_LIVE_24_CPT6_PAI_REG.layers
		}
	]
	return {
		resources,
		areas: {
			[MapArea.NATIONAL]: {
				[MapRealm.TERRESTRIAL]: {
					legends: [
						{
							legend: MapLegend.LESS_THAN_FOUR_POINT_FOUR,
							esriLayerNames: [EsriLayerId.PAI_LESS_THAN_FOUR],
							legendColours: {
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT3_KBA_NAT.layers, EsriLayerId.PAI_LESS_THAN_FOUR)
							}
						},
						{
							legend: MapLegend.FOUR_POINT_FOUR_TO_SIX_POINT_TWO,
							esriLayerNames: [EsriLayerId.PAI_FOUR_TO_SIX],
							legendColours: {
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT3_KBA_NAT.layers, EsriLayerId.PAI_FOUR_TO_SIX)
							}
						},
						{
							legend: MapLegend.SIX_POINT_TWO_TO_EIGHT_POINT_ONE,
							esriLayerNames: [EsriLayerId.PAI_SIX_TO_EIGHT],
							legendColours: {
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT3_KBA_NAT.layers, EsriLayerId.PAI_SIX_TO_EIGHT)
							}
						},
						{
							legend: MapLegend.ABOVE_EIGHT_POINT_ONE,
							esriLayerNames: [EsriLayerId.PAI_ABOVE_EIGHT],
							legendColours: {
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT3_KBA_NAT.layers, EsriLayerId.PAI_ABOVE_EIGHT)
							}
						},
						{
							legend: MapLegend.DATA_DEFICITENT,
							esriLayerNames: [EsriLayerId.PAI_DATA_DEFICIENT],
							legendColours: {
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT3_KBA_NAT.layers, EsriLayerId.PAI_DATA_DEFICIENT)
							}
						}
					]
				}
			},
			[MapArea.SUB_REGIONAL]: {
				[MapRealm.TERRESTRIAL]: {
					legends: [
						{
							legend: MapLegend.LESS_THAN_FOUR_POINT_TWO,
							esriLayerNames: [EsriLayerId.PAI_REG_LESS_THAN_FOUR],
							legendColours: {
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PAI_REG.layers, EsriLayerId.PAI_REG_LESS_THAN_FOUR)
							}
						},
						{
							legend: MapLegend.FOUR_POINT_TWO_TO_FIVE_POINT_SEVEN,
							esriLayerNames: [EsriLayerId.PAI_REG_FOUR_TO_FIVE],
							legendColours: {
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PAI_REG.layers, EsriLayerId.PAI_REG_FOUR_TO_FIVE)
							}
						},
						{
							legend: MapLegend.FIVE_POINT_SEVEN_TO_SEVEN_POINT_ZERO,
							esriLayerNames: [EsriLayerId.PAI_REG_FIVE_TO_SEVEN],
							legendColours: {
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PAI_REG.layers, EsriLayerId.PAI_REG_FIVE_TO_SEVEN)
							}
						},
						{
							legend: MapLegend.ABOVE_SEVEN,
							esriLayerNames: [EsriLayerId.PAI_REG_ABOVE_SEVEN],
							legendColours: {
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PAI_REG.layers, EsriLayerId.PAI_REG_ABOVE_SEVEN)
							}
						},
						{
							legend: MapLegend.DATA_DEFICITENT,
							esriLayerNames: [EsriLayerId.PAI_REG_DATA_DEFICIENT],
							legendColours: {
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PAI_REG.layers, EsriLayerId.PAI_REG_DATA_DEFICIENT)
							}
						}
					]
				}
			}
		}
	}
}
function protConnMapInfo(): MapInfo {
	const PP_LIVE_24_CPT6_PROTCONN = getResourceTileInfo(DISPLAY_DATA_OF_MAP.PROT_CONN, MAP_RESOURCES.PP_LIVE_24_CPT6_PROTCONN)
	const PP_LIVE_24_CPT6_PROTCONN_OECMS = getResourceTileInfo(DISPLAY_DATA_OF_MAP.PROT_CONN, MAP_RESOURCES.PP_LIVE_24_CPT6_PROTCONN_OECMS)
	const PP_LIVE_24_CPT6_PROTCONN_REG = getResourceTileInfo(DISPLAY_DATA_OF_MAP.PROT_CONN, MAP_RESOURCES.PP_LIVE_24_CPT6_PROTCONN_REG)
	const PP_LIVE_24_CPT6_PROTCONN_REG_OECMS = getResourceTileInfo(DISPLAY_DATA_OF_MAP.PROT_CONN, MAP_RESOURCES.PP_LIVE_24_CPT6_PROTCONN_REG_OECMS)

	const resources = [
		{
			id: MAP_RESOURCES.PP_LIVE_24_CPT6_PROTCONN,
			area: MapArea.NATIONAL,
			type: MapAreaType.PA,
			resource: PP_LIVE_24_CPT6_PROTCONN.resource,
			allLayers: PP_LIVE_24_CPT6_PROTCONN.layers
		},
		{
			id: MAP_RESOURCES.PP_LIVE_24_CPT6_PROTCONN_OECMS,
			area: MapArea.NATIONAL,
			type: MapAreaType.PA_OECMS,
			resource: PP_LIVE_24_CPT6_PROTCONN_OECMS.resource,
			allLayers: PP_LIVE_24_CPT6_PROTCONN_OECMS.layers
		},
		{
			id: MAP_RESOURCES.PP_LIVE_24_CPT6_PROTCONN_REG,
			area: MapArea.SUB_REGIONAL,
			type: MapAreaType.PA,
			resource: PP_LIVE_24_CPT6_PROTCONN_REG.resource,
			allLayers: PP_LIVE_24_CPT6_PROTCONN_REG.layers
		},
		{
			id: MAP_RESOURCES.PP_LIVE_24_CPT6_PROTCONN_REG_OECMS,
			area: MapArea.SUB_REGIONAL,
			type: MapAreaType.PA_OECMS,
			resource: PP_LIVE_24_CPT6_PROTCONN_REG_OECMS.resource,
			allLayers: PP_LIVE_24_CPT6_PROTCONN_REG_OECMS.layers
		}
	]
	return {
		resources,
		areas: {
			[MapArea.NATIONAL]: {
				[MapRealm.TERRESTRIAL]: {
					legends: [
						{
							legend: MapLegend.LESS_THAN_TEN_PERCENT,
							esriLayerNames: [EsriLayerId.PROTCONN_COUNTRIES_LESS_THAN_TEN],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PROTCONN.layers, EsriLayerId.PROTCONN_COUNTRIES_LESS_THAN_TEN),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PROTCONN_OECMS.layers, EsriLayerId.PROTCONN_COUNTRIES_LESS_THAN_TEN)
							}
						},
						{
							legend: MapLegend.TEN_TO_TWENTY_PERCENT,
							esriLayerNames: [EsriLayerId.PROTCONN_COUNTRIES_TEN_TO_TWENTY],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PROTCONN.layers, EsriLayerId.PROTCONN_COUNTRIES_TEN_TO_TWENTY),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PROTCONN_OECMS.layers, EsriLayerId.PROTCONN_COUNTRIES_TEN_TO_TWENTY)
							}
						},
						{
							legend: MapLegend.TWENTY_TO_FOURTY_PERCENT,
							esriLayerNames: [EsriLayerId.PROTCONN_COUNTRIES_TWENTY_TO_FOURTY],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PROTCONN.layers, EsriLayerId.PROTCONN_COUNTRIES_TWENTY_TO_FOURTY),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PROTCONN_OECMS.layers, EsriLayerId.PROTCONN_COUNTRIES_TWENTY_TO_FOURTY)
							}
						},
						{
							legend: MapLegend.FOURTY_TO_SIXTY_PERCENT,
							esriLayerNames: [EsriLayerId.PROTCONN_COUNTRIES_FOURTY_TO_SIXTY],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PROTCONN.layers, EsriLayerId.PROTCONN_COUNTRIES_FOURTY_TO_SIXTY),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PROTCONN_OECMS.layers, EsriLayerId.PROTCONN_COUNTRIES_FOURTY_TO_SIXTY)
							}
						},
						{
							legend: MapLegend.SIXTY_TO_HUNDARD_PERCENT,
							esriLayerNames: [EsriLayerId.PROTCONN_COUNTRIES_SIXTY_TO_THUNDARD],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PROTCONN.layers, EsriLayerId.PROTCONN_COUNTRIES_SIXTY_TO_THUNDARD),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PROTCONN_OECMS.layers, EsriLayerId.PROTCONN_COUNTRIES_SIXTY_TO_THUNDARD)
							}
						},
						{
							legend: MapLegend.DATA_DEFICITENT,
							esriLayerNames: [EsriLayerId.PROTCONN_DATA_DEFICIENT],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PROTCONN.layers, EsriLayerId.PROTCONN_DATA_DEFICIENT),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PROTCONN_OECMS.layers, EsriLayerId.PROTCONN_DATA_DEFICIENT)
							}
						}
					]
				}
			},
			[MapArea.SUB_REGIONAL]: {
				[MapRealm.TERRESTRIAL]: {
					legends: [
						{
							legend: MapLegend.LESS_THAN_TEN_PERCENT,
							esriLayerNames: [
								EsriLayerId.SUB_REGIONS_LESS_THAN_TEN
							],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PROTCONN_REG.layers, EsriLayerId.SUB_REGIONS_LESS_THAN_TEN),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PROTCONN_REG_OECMS.layers, EsriLayerId.SUB_REGIONS_LESS_THAN_TEN)
							}
						},
						{
							legend: MapLegend.TEN_TO_TWENTY_PERCENT,
							esriLayerNames: [
								EsriLayerId.SUB_REGIONS_TEN_TO_TWENTY
							],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PROTCONN_REG.layers, EsriLayerId.SUB_REGIONS_TEN_TO_TWENTY),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PROTCONN_REG_OECMS.layers, EsriLayerId.SUB_REGIONS_TEN_TO_TWENTY)
							}
						},
						{
							legend: MapLegend.TWENTY_TO_FOURTY_PERCENT,
							esriLayerNames: [
								EsriLayerId.SUB_REGIONS_TWENTY_TO_FOURTY
							],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PROTCONN_REG.layers, EsriLayerId.SUB_REGIONS_TWENTY_TO_FOURTY),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PROTCONN_REG_OECMS.layers, EsriLayerId.SUB_REGIONS_TWENTY_TO_FOURTY)
							}
						},
						{
							legend: MapLegend.FOURTY_TO_SIXTY_PERCENT,
							esriLayerNames: [
								EsriLayerId.SUB_REGIONS_FOURTY_TO_SIXTY
							],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PROTCONN_REG.layers, EsriLayerId.SUB_REGIONS_FOURTY_TO_SIXTY),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PROTCONN_REG_OECMS.layers, EsriLayerId.SUB_REGIONS_FOURTY_TO_SIXTY)
							}
						},
						{
							legend: MapLegend.SIXTY_TO_HUNDARD_PERCENT,
							esriLayerNames: [
								EsriLayerId.SUB_REGIONS_MORE_THAN_SIXTY
							],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PROTCONN_REG.layers, EsriLayerId.SUB_REGIONS_MORE_THAN_SIXTY),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PROTCONN_REG_OECMS.layers, EsriLayerId.SUB_REGIONS_MORE_THAN_SIXTY)
							}
						},
						{
							legend: MapLegend.DATA_DEFICITENT,
							esriLayerNames: [
								EsriLayerId.SUB_REG_DATA_DEFICIENT
							],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PROTCONN_REG.layers, EsriLayerId.SUB_REG_DATA_DEFICIENT),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PROTCONN_REG_OECMS.layers, EsriLayerId.SUB_REG_DATA_DEFICIENT)
							}
						}
					]
				}
			}
		}
	}
}
function parcMapInfo(): MapInfo {
	const PP_LIVE_24_CPT6_PARC_NAT = getResourceTileInfo(DISPLAY_DATA_OF_MAP.PARC, MAP_RESOURCES.PP_LIVE_24_CPT6_PARC_NAT)
	const PP_LIVE_24_CPT6_PARC_NAT_OECMS = getResourceTileInfo(DISPLAY_DATA_OF_MAP.PARC, MAP_RESOURCES.PP_LIVE_24_CPT6_PARC_NAT_OECMS)
	const PP_LIVE_24_CPT6_PARC_REG = getResourceTileInfo(DISPLAY_DATA_OF_MAP.PARC, MAP_RESOURCES.PP_LIVE_24_CPT6_PARC_REG)
	const PP_LIVE_24_CPT6_PARC_REG_OECMS = getResourceTileInfo(DISPLAY_DATA_OF_MAP.PARC, MAP_RESOURCES.PP_LIVE_24_CPT6_PARC_REG_OECMS)

	const resources = [
		{
			id: MAP_RESOURCES.PP_LIVE_24_CPT6_PARC_NAT,
			area: MapArea.NATIONAL,
			type: MapAreaType.PA,
			resource: PP_LIVE_24_CPT6_PARC_NAT.resource,
			allLayers: PP_LIVE_24_CPT6_PARC_NAT.layers
		},
		{
			id: MAP_RESOURCES.PP_LIVE_24_CPT6_PARC_NAT_OECMS,
			area: MapArea.NATIONAL,
			type: MapAreaType.PA_OECMS,
			resource: PP_LIVE_24_CPT6_PARC_NAT_OECMS.resource,
			allLayers: PP_LIVE_24_CPT6_PARC_NAT_OECMS.layers
		},
		{
			id: MAP_RESOURCES.PP_LIVE_24_CPT6_PARC_REG,
			area: MapArea.SUB_REGIONAL,
			type: MapAreaType.PA,
			resource: PP_LIVE_24_CPT6_PARC_REG.resource,
			allLayers: PP_LIVE_24_CPT6_PARC_REG.layers
		},
		{
			id: MAP_RESOURCES.PP_LIVE_24_CPT6_PARC_REG_OECMS,
			area: MapArea.SUB_REGIONAL,
			type: MapAreaType.PA_OECMS,
			resource: PP_LIVE_24_CPT6_PARC_REG_OECMS.resource,
			allLayers: PP_LIVE_24_CPT6_PARC_REG_OECMS.layers
		}
	]
	return {
		resources,
		areas: {
			[MapArea.NATIONAL]: {
				[MapRealm.TERRESTRIAL]: {
					legends: [
						{
							legend: MapLegend.LESS_THAN_ZERO_POINT_TWO,
							esriLayerNames: [EsriLayerId.PARC_LESS_THAN_ZERO_POINT_TWO],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PARC_NAT.layers, EsriLayerId.PARC_LESS_THAN_ZERO_POINT_TWO),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PARC_NAT_OECMS.layers, EsriLayerId.PARC_LESS_THAN_ZERO_POINT_TWO)
							}
						},
						{
							legend: MapLegend.ZERO_POINT_TWO_TO_ZERO_POINT_FOUR,
							esriLayerNames: [
								EsriLayerId.PARC_ZERO_POINT_TWO_TO_ZERO_POINT_FOUR,
								EsriLayerId.PARC_ZERO_POINT_TWO_TO_ZERO_POINT_FOUR_OECMS
							],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PARC_NAT.layers, EsriLayerId.PARC_ZERO_POINT_TWO_TO_ZERO_POINT_FOUR),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PARC_NAT_OECMS.layers, EsriLayerId.PARC_ZERO_POINT_TWO_TO_ZERO_POINT_FOUR_OECMS)
							}
						},
						{
							legend: MapLegend.ZERO_POINT_FOUR_TO_ZERO_POINT_SIX,
							esriLayerNames: [EsriLayerId.PARC_ZERO_POINT_FOUR_ZERO_POINT_SIX],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PARC_NAT.layers, EsriLayerId.PARC_ZERO_POINT_FOUR_ZERO_POINT_SIX),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PARC_NAT_OECMS.layers, EsriLayerId.PARC_ZERO_POINT_FOUR_ZERO_POINT_SIX)
							}
						},
						{
							legend: MapLegend.ZERO_POINT_SIX_TO_ZERO_POINT_EIGHT,
							esriLayerNames: [EsriLayerId.PARC_ZERO_POINT_SIX_ZERO_POINT_EIGHT],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PARC_NAT.layers, EsriLayerId.PARC_ZERO_POINT_SIX_ZERO_POINT_EIGHT),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PARC_NAT_OECMS.layers, EsriLayerId.PARC_ZERO_POINT_SIX_ZERO_POINT_EIGHT)
							}
						},
						{
							legend: MapLegend.ZERO_POINT_EIGHT_TO_ONE,
							esriLayerNames: [EsriLayerId.PARC_LESS_THAN_ONE],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PARC_NAT.layers, EsriLayerId.PARC_LESS_THAN_ONE),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PARC_NAT_OECMS.layers, EsriLayerId.PARC_LESS_THAN_ONE)
							}
						},
						{
							legend: MapLegend.DATA_DEFICITENT,
							esriLayerNames: [EsriLayerId.PARC_DATA_DEFICIENT],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PARC_NAT.layers, EsriLayerId.PARC_DATA_DEFICIENT),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PARC_NAT_OECMS.layers, EsriLayerId.PARC_DATA_DEFICIENT)
							}
						}
					]
				}
			},
			[MapArea.SUB_REGIONAL]: {
				[MapRealm.TERRESTRIAL]: {
					legends: [
						{
							legend: MapLegend.LESS_THAN_ZERO_POINT_FIVE,
							esriLayerNames: [
								EsriLayerId.PARC_REG_ZERO_POINT_FIVE
							],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PARC_REG.layers, EsriLayerId.PARC_REG_ZERO_POINT_FIVE),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PARC_REG_OECMS.layers, EsriLayerId.PARC_REG_ZERO_POINT_FIVE)
							}
						},
						{
							legend: MapLegend.ZERO_POINT_FIVE_TO_ZERO_POINT_SIX,
							esriLayerNames: [
								EsriLayerId.PARC_REG_ZERO_POINT_FIVE_TO_ZERO_POINT_SIX
							],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PARC_REG.layers, EsriLayerId.PARC_REG_ZERO_POINT_FIVE_TO_ZERO_POINT_SIX),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PARC_REG_OECMS.layers, EsriLayerId.PARC_REG_ZERO_POINT_FIVE_TO_ZERO_POINT_SIX)
							}
						},
						{
							legend: MapLegend.ZERO_POINT_SIX_TO_ZERO_POINT_SEVEN,
							esriLayerNames: [
								EsriLayerId.PARC_REG_ZERO_POINT_SIX_TO_ZERO_POINT_SEVEN
							],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PARC_REG.layers, EsriLayerId.PARC_REG_ZERO_POINT_SEVEN_TO_ZERO_POINT_EIGHT),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PARC_REG_OECMS.layers, EsriLayerId.PARC_REG_ZERO_POINT_SIX_TO_ZERO_POINT_SEVEN)
							}
						},
						{
							legend: MapLegend.ZERO_POINT_SEVEN_TO_ZERO_POINT_EIGHT,
							esriLayerNames: [
								EsriLayerId.PARC_REG_ZERO_POINT_SEVEN_TO_ZERO_POINT_EIGHT
							],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PARC_REG.layers, EsriLayerId.PARC_REG_ZERO_POINT_SEVEN_TO_ZERO_POINT_EIGHT),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PARC_REG_OECMS.layers, EsriLayerId.PARC_REG_ZERO_POINT_SEVEN_TO_ZERO_POINT_EIGHT)
							}
						},
						{
							legend: MapLegend.ZERO_POINT_EIGHT_TO_ONE,
							esriLayerNames: [
								EsriLayerId.PARC_REG_ZERO_POINT_EIGHT_TO_ONE
							],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PARC_REG.layers, EsriLayerId.PARC_REG_ZERO_POINT_EIGHT_TO_ONE),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PARC_REG_OECMS.layers, EsriLayerId.PARC_REG_ZERO_POINT_EIGHT_TO_ONE)
							}
						},
						{
							legend: MapLegend.DATA_DEFICITENT,
							esriLayerNames: [
								EsriLayerId.PARC_REG_DATA_DEFICIENT
							],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PARC_REG.layers, EsriLayerId.PARC_REG_DATA_DEFICIENT),
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT6_PARC_REG_OECMS.layers, EsriLayerId.PARC_REG_DATA_DEFICIENT)
							}
						}
					]
				}
			}
		}
	}
}
function pameMapInfo(): MapInfo {
	const PP_LIVE_24_CPT7_PAME_NAT = getResourceTileInfo(
		DISPLAY_DATA_OF_MAP.MANAGEMENT_EFFECTIVENESS_ASSESSMENTS_PER_COUNTRY_SUB_REGION,
		MAP_RESOURCES.PP_LIVE_24_CPT7_PAME_NAT)
	const PP_LIVE_24_CPT7_PAME_REG = getResourceTileInfo(
		DISPLAY_DATA_OF_MAP.MANAGEMENT_EFFECTIVENESS_ASSESSMENTS_PER_COUNTRY_SUB_REGION,
		MAP_RESOURCES.PP_LIVE_24_CPT7_PAME_REG)
	const resources: Resource[] = [
		{
			id: MAP_RESOURCES.PP_LIVE_24_CPT7_PAME_NAT,
			area: MapArea.NATIONAL,
			type: MapAreaType.PA,
			resource: PP_LIVE_24_CPT7_PAME_NAT.resource,
			allLayers: PP_LIVE_24_CPT7_PAME_NAT.layers
		},
		{
			id: MAP_RESOURCES.PP_LIVE_24_CPT7_PAME_REG,
			area: MapArea.SUB_REGIONAL,
			type: MapAreaType.PA,
			resource: PP_LIVE_24_CPT7_PAME_REG.resource,
			allLayers: PP_LIVE_24_CPT7_PAME_REG.layers
		}
	]
	return {
		resources,
		areas: {
			[MapArea.NATIONAL]: {
				[MapRealm.TERRESTRIAL]: {
					legends: [
						{
							legend: MapLegend.LESS_THAN_FIVE_PERCENT,
							esriLayerNames: [EsriLayerId.PAME_TERRESTRAIL_LESS_THAN_FIVE],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT7_PAME_NAT.layers, EsriLayerId.PAME_TERRESTRAIL_LESS_THAN_FIVE)
							}
						},
						{
							legend: MapLegend.FIVE_TO_TEN_PERCENT,
							esriLayerNames: [EsriLayerId.PAME_TERRESTRAIL_FIVE_TO_TEN],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT7_PAME_NAT.layers, EsriLayerId.PAME_TERRESTRAIL_FIVE_TO_TEN)
							}
						},
						{
							legend: MapLegend.TEN_TO_THIRTY_PERCENT,
							esriLayerNames: [EsriLayerId.PAME_TERRESTRAIL_TEN_TO_THIRTY],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT7_PAME_NAT.layers, EsriLayerId.PAME_TERRESTRAIL_TEN_TO_THIRTY)
							}
						},
						{
							legend: MapLegend.THIRTY_TO_SIXTY_PERCENT,
							esriLayerNames: [EsriLayerId.PAME_TERRESTRAIL_THIRTY_TO_SIXTY],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT7_PAME_NAT.layers, EsriLayerId.PAME_TERRESTRAIL_THIRTY_TO_SIXTY)
							}
						},
						{
							legend: MapLegend.SIXTY_TO_HUNDARD_PERCENT,
							esriLayerNames: [EsriLayerId.PAME_TERRESTRAIL_LESS_THAN_HUNDARD],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT7_PAME_NAT.layers, EsriLayerId.PAME_TERRESTRAIL_LESS_THAN_HUNDARD)
							}
						},
						{
							legend: MapLegend.DATA_DEFICITENT,
							esriLayerNames: [EsriLayerId.PAME_TERRESTRAIL_DATA_DEFICIENT],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT7_PAME_NAT.layers, EsriLayerId.PAME_TERRESTRAIL_DATA_DEFICIENT)
							}
						}
					]
				},
				[MapRealm.MARINE]: {
					legends: [
						{
							legend: MapLegend.LESS_THAN_FIVE_PERCENT,
							esriLayerNames: [EsriLayerId.PAME_MARINE_LESS_THAN_FIVE_PERCENT],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT7_PAME_NAT.layers, EsriLayerId.PAME_MARINE_LESS_THAN_FIVE_PERCENT)
							}
						},
						{
							legend: MapLegend.FIVE_TO_TEN_PERCENT,
							esriLayerNames: [EsriLayerId.PAME_MARINE_FIVE_TO_TEN_PERCENT],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT7_PAME_NAT.layers, EsriLayerId.PAME_MARINE_FIVE_TO_TEN_PERCENT)
							}
						},
						{
							legend: MapLegend.TEN_TO_TWENTY_PERCENT,
							esriLayerNames: [EsriLayerId.PAME_MARINE_TEN_TO_TWENTY_PERCENT],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT7_PAME_NAT.layers, EsriLayerId.PAME_MARINE_TEN_TO_TWENTY_PERCENT)
							}
						},
						{
							legend: MapLegend.TWENTY_TO_THIRTY_PERCENT,
							esriLayerNames: [EsriLayerId.PAME_MARINE_TWENTY_TO_THIRTY_PERCENT],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT7_PAME_NAT.layers, EsriLayerId.PAME_MARINE_TWENTY_TO_THIRTY_PERCENT)
							}
						},
						{
							legend: MapLegend.DATA_DEFICITENT,
							esriLayerNames: [EsriLayerId.PAME_MARINE_DATA_DEFICIENT],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT7_PAME_NAT.layers, EsriLayerId.PAME_MARINE_DATA_DEFICIENT)
							}
						}
					]
				}
			},
			[MapArea.SUB_REGIONAL]: {
				[MapRealm.TERRESTRIAL]: {
					legends: [
						{
							legend: MapLegend.LESS_THAN_THREE_PERCENT,
							esriLayerNames: [EsriLayerId.PAME_REG_TERRESTRAIL_LESS_THAN_THREE],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT7_PAME_REG.layers, EsriLayerId.PAME_REG_TERRESTRAIL_LESS_THAN_THREE)
							}
						},
						{
							legend: MapLegend.THREE_TO_SIX_PERCENT,
							esriLayerNames: [EsriLayerId.PAME_REG_TERRESTRAIL_THREE_TO_SIX],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT7_PAME_REG.layers, EsriLayerId.PAME_REG_TERRESTRAIL_THREE_TO_SIX)
							}
						},
						{
							legend: MapLegend.SIX_TO_THIRTEEN_PERCENT,
							esriLayerNames: [EsriLayerId.PAME_REG_TERRESTRAIL_SIX_TO_THIRTEEN],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT7_PAME_REG.layers, EsriLayerId.PAME_REG_TERRESTRAIL_SIX_TO_THIRTEEN)
							}
						},
						{
							legend: MapLegend.DATA_DEFICITENT,
							esriLayerNames: [EsriLayerId.PAME_REG_TERRESTRAIL_DATA_DEFICIENT],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT7_PAME_REG.layers, EsriLayerId.PAME_REG_TERRESTRAIL_DATA_DEFICIENT)
							}
						}
					]
				},
				[MapRealm.MARINE]: {
					legends: [
						{
							legend: MapLegend.LESS_THAN_TWO_PERCENT,
							esriLayerNames: [EsriLayerId.PAME_REG_MARINE_LESS_THAN_TWO],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT7_PAME_REG.layers, EsriLayerId.PAME_REG_MARINE_LESS_THAN_TWO)
							}
						},
						{
							legend: MapLegend.TWO_TO_FIVE_PERCENT,
							esriLayerNames: [EsriLayerId.PAME_REG_MARINE_TWO_TO_FIVE],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT7_PAME_REG.layers, EsriLayerId.PAME_REG_MARINE_TWO_TO_FIVE)
							}
						},
						{
							legend: MapLegend.FIVE_TO_TEN_PERCENT,
							esriLayerNames: [EsriLayerId.PAME_REG_MARINE_FIVE_TO_TEN],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT7_PAME_REG.layers, EsriLayerId.PAME_REG_MARINE_FIVE_TO_TEN)
							}
						},
						{
							legend: MapLegend.DATA_DEFICITENT,
							esriLayerNames: [EsriLayerId.PAME_REG_MARINE_DATA_DEFICIENT],
							legendColours: {
								[MapAreaType.PA]: getColourFromEsriLayers(PP_LIVE_24_CPT7_PAME_REG.layers, EsriLayerId.PAME_REG_MARINE_DATA_DEFICIENT)
							}
						}
					]
				}
			}
		}
	}
}
function nationalSubRegionalCoveragePaAndOecmsAndITTMapInfo(): MapInfo {
	const PP_LIVE_24_CPT9_OECMS_NAT = getResourceTileInfo(DISPLAY_DATA_OF_MAP.NATIONAL_SUB_REGIONAL_COVERAGE_PA_AND_OECMS_ITTS, MAP_RESOURCES.PP_LIVE_24_CPT9_OECMS_NAT)
	const PP_LIVE_24_CPT9_OECMS_REG = getResourceTileInfo(DISPLAY_DATA_OF_MAP.NATIONAL_SUB_REGIONAL_COVERAGE_PA_AND_OECMS_ITTS, MAP_RESOURCES.PP_LIVE_24_CPT9_OECMS_REG)
	const PP_LIVE_24_CPT9_ITTS_NAT = getResourceTileInfo(DISPLAY_DATA_OF_MAP.NATIONAL_SUB_REGIONAL_COVERAGE_PA_AND_OECMS_ITTS, MAP_RESOURCES.PP_LIVE_24_CPT9_ITTS_NAT)
	const PP_LIVE_24_CPT9_ITTS_REG = getResourceTileInfo(DISPLAY_DATA_OF_MAP.NATIONAL_SUB_REGIONAL_COVERAGE_PA_AND_OECMS_ITTS, MAP_RESOURCES.PP_LIVE_24_CPT9_ITTS_REG)

	const resources: Resource[] = [
		{
			id: MAP_RESOURCES.PP_LIVE_24_CPT9_ITTS_NAT,
			area: MapArea.NATIONAL,
			type: MapAreaType.PA_OECMS_ITTS,
			resource: PP_LIVE_24_CPT9_ITTS_NAT.resource,
			allLayers: PP_LIVE_24_CPT9_ITTS_NAT.layers
		},
		{
			id: MAP_RESOURCES.PP_LIVE_24_CPT9_ITTS_REG,
			area: MapArea.SUB_REGIONAL,
			type: MapAreaType.PA_OECMS_ITTS,
			resource: PP_LIVE_24_CPT9_ITTS_REG.resource,
			allLayers: PP_LIVE_24_CPT9_ITTS_REG.layers
		},
		{
			id: MAP_RESOURCES.PP_LIVE_24_CPT9_OECMS_NAT,
			area: MapArea.NATIONAL,
			type: MapAreaType.PA_OECMS,
			resource: PP_LIVE_24_CPT9_OECMS_NAT.resource,
			allLayers: PP_LIVE_24_CPT9_OECMS_NAT.layers
		},
		{
			id: MAP_RESOURCES.PP_LIVE_24_CPT9_OECMS_REG,
			area: MapArea.SUB_REGIONAL,
			type: MapAreaType.PA_OECMS,
			resource: PP_LIVE_24_CPT9_OECMS_REG.resource,
			allLayers: PP_LIVE_24_CPT9_OECMS_REG.layers
		}
	]
	return {
		resources,
		areas: {
			[MapArea.NATIONAL]: {
				[MapRealm.TERRESTRIAL]: {
					legends: [
						{
							legend: MapLegend.LESS_THAN_FIVE_PERCENT,
							esriLayerNames: [
								EsriLayerId.LAND_LESS_THAN_FIVE
							],
							legendColours: {
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT9_OECMS_NAT.layers, EsriLayerId.LAND_LESS_THAN_FIVE),
								[MapAreaType.PA_OECMS_ITTS]: getColourFromEsriLayers(PP_LIVE_24_CPT9_ITTS_NAT.layers, EsriLayerId.LAND_LESS_THAN_FIVE)
							}
						},
						{
							legend: MapLegend.FIVE_TO_TEN_PERCENT,
							esriLayerNames: [
								EsriLayerId.LAND_FIVE_TO_TEN
							],
							legendColours: {
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT9_OECMS_NAT.layers, EsriLayerId.LAND_FIVE_TO_TEN),
								[MapAreaType.PA_OECMS_ITTS]: getColourFromEsriLayers(PP_LIVE_24_CPT9_ITTS_NAT.layers, EsriLayerId.LAND_FIVE_TO_TEN)
							}
						},
						{
							legend: MapLegend.TEN_TO_SEVENTEEN_PERCENT,
							esriLayerNames: [
								EsriLayerId.LAND_TEN_TO_SEVENTEEN
							],
							legendColours: {
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT9_OECMS_NAT.layers, EsriLayerId.LAND_TEN_TO_SEVENTEEN),
								[MapAreaType.PA_OECMS_ITTS]: getColourFromEsriLayers(PP_LIVE_24_CPT9_ITTS_NAT.layers, EsriLayerId.LAND_TEN_TO_SEVENTEEN)
							}
						},
						{
							legend: MapLegend.SEVENTEEN_TO_THIRTY_PERCENT,
							esriLayerNames: [
								EsriLayerId.LAND_SEVENTEEN_TO_THIRTY
							],
							legendColours: {
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT9_OECMS_NAT.layers, EsriLayerId.LAND_SEVENTEEN_TO_THIRTY),
								[MapAreaType.PA_OECMS_ITTS]: getColourFromEsriLayers(PP_LIVE_24_CPT9_ITTS_NAT.layers, EsriLayerId.LAND_SEVENTEEN_TO_THIRTY)
							}
						},
						{
							legend: MapLegend.MORE_THAN_THIRTY_PERCENT,
							esriLayerNames: [
								EsriLayerId.LAND_MORE_THAN_THIRTY
							],
							legendColours: {
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT9_OECMS_NAT.layers, EsriLayerId.LAND_MORE_THAN_THIRTY),
								[MapAreaType.PA_OECMS_ITTS]: getColourFromEsriLayers(PP_LIVE_24_CPT9_ITTS_NAT.layers, EsriLayerId.LAND_MORE_THAN_THIRTY)
							}
						},
						{
							legend: MapLegend.DATA_DEFICITENT,
							esriLayerNames: [
								EsriLayerId.LAND_DATA_DEFICIENT
							],
							legendColours: {
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT9_OECMS_NAT.layers, EsriLayerId.LAND_DATA_DEFICIENT),
								[MapAreaType.PA_OECMS_ITTS]: getColourFromEsriLayers(PP_LIVE_24_CPT9_ITTS_NAT.layers, EsriLayerId.LAND_DATA_DEFICIENT)
							}
						}
					]
				}
			},
			[MapArea.SUB_REGIONAL]: {
				[MapRealm.TERRESTRIAL]: {
					legends: [
						{
							legend: MapLegend.LESS_THAN_FIVE_PERCENT,
							esriLayerNames: [
								EsriLayerId.LAND_SUBREGION_LESS_THAN_FIVE,
								EsriLayerId.LAND_SUBREGION_LESS_THAN_FIVE_REG_ITT
							],
							legendColours: {
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT9_OECMS_REG.layers, EsriLayerId.LAND_SUBREGION_LESS_THAN_FIVE),
								[MapAreaType.PA_OECMS_ITTS]: getColourFromEsriLayers(PP_LIVE_24_CPT9_ITTS_REG.layers, EsriLayerId.LAND_SUBREGION_LESS_THAN_FIVE_REG_ITT)
							}
						},
						{
							legend: MapLegend.FIVE_TO_TEN_PERCENT,
							esriLayerNames: [
								EsriLayerId.LAND_SUBREGION_FIVE_TO_TEN,
								EsriLayerId.LAND_SUBREGION_FIVE_TO_TEN_REG_ITT
							],
							legendColours: {
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT9_OECMS_REG.layers, EsriLayerId.LAND_SUBREGION_FIVE_TO_TEN),
								[MapAreaType.PA_OECMS_ITTS]: getColourFromEsriLayers(PP_LIVE_24_CPT9_ITTS_REG.layers, EsriLayerId.LAND_SUBREGION_FIVE_TO_TEN_REG_ITT)
							}
						},
						{
							legend: MapLegend.TEN_TO_SEVENTEEN_PERCENT,
							esriLayerNames: [
								EsriLayerId.LAND_SUBREGION_TEN_TO_SEVENTEEN,
								EsriLayerId.LAND_SUBREGION_TEN_TO_SEVENTEEN_REG_ITT
							],
							legendColours: {
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT9_OECMS_REG.layers, EsriLayerId.LAND_SUBREGION_TEN_TO_SEVENTEEN),
								[MapAreaType.PA_OECMS_ITTS]: getColourFromEsriLayers(PP_LIVE_24_CPT9_ITTS_REG.layers, EsriLayerId.LAND_SUBREGION_TEN_TO_SEVENTEEN_REG_ITT)
							}

						},
						{
							legend: MapLegend.SEVENTEEN_TO_THIRTY_PERCENT,
							esriLayerNames: [
								EsriLayerId.LAND_SUBREGION_SEVENTEEN_TO_THIRTY,
								EsriLayerId.LAND_SUBREGION_SEVENTEEN_TO_THIRTY_REG_ITT
							],
							legendColours: {
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT9_OECMS_REG.layers, EsriLayerId.LAND_SUBREGION_SEVENTEEN_TO_THIRTY),
								[MapAreaType.PA_OECMS_ITTS]: getColourFromEsriLayers(PP_LIVE_24_CPT9_ITTS_REG.layers, EsriLayerId.LAND_SUBREGION_SEVENTEEN_TO_THIRTY_REG_ITT)
							}
						},
						{
							legend: MapLegend.MORE_THAN_THIRTY_PERCENT,
							esriLayerNames: [
								EsriLayerId.LAND_SUBREGION_MORE_THAN_THIRTY,
								EsriLayerId.LAND_SUBREGION_MORE_THAN_THIRTY_REG_ITT
							],
							legendColours: {
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT9_OECMS_REG.layers, EsriLayerId.LAND_SUBREGION_MORE_THAN_THIRTY),
								[MapAreaType.PA_OECMS_ITTS]: getColourFromEsriLayers(PP_LIVE_24_CPT9_ITTS_REG.layers, EsriLayerId.LAND_SUBREGION_MORE_THAN_THIRTY_REG_ITT)
							}
						},
						{
							legend: MapLegend.DATA_DEFICITENT,
							esriLayerNames: [
								EsriLayerId.LAND_SUBREGION_DATA_DEFICIENT,
								EsriLayerId.LAND_SUBREGION_DATA_DEFICIENT_REG_ITT
							],
							legendColours: {
								[MapAreaType.PA_OECMS]: getColourFromEsriLayers(PP_LIVE_24_CPT9_OECMS_REG.layers, EsriLayerId.LAND_SUBREGION_DATA_DEFICIENT),
								[MapAreaType.PA_OECMS_ITTS]: getColourFromEsriLayers(PP_LIVE_24_CPT9_ITTS_REG.layers, EsriLayerId.LAND_SUBREGION_DATA_DEFICIENT_REG_ITT)
							}
						}
					]
				}
			}
		}
	}
}
function mapsInfo(): MapsInfo | undefined {
	const esriLayersInfo = window.frontendData?.maps
	if (!esriLayersInfo)
		return undefined
	const _mapsInfo: MapsInfo = {
		[DISPLAY_DATA_OF_MAP.DISTRIBUTION_THE_WORLDS_PA_AND_OECMS]: distributionTheWorldsPaAndOecmsMapInfo(),
		[DISPLAY_DATA_OF_MAP.NATIONAL_SUB_REGIONAL_COVERAGE_PA_AND_OECMS]: nationalSubRegionalCoveragePaAndOecmsMapInfo(),
		[DISPLAY_DATA_OF_MAP.NCP]: ncpMapInfo(),
		[DISPLAY_DATA_OF_MAP.KEY_BIODIVERSITY_AREAS]: keyBiodiversityAreasMapInfo(),
		[DISPLAY_DATA_OF_MAP.EXTENT_PA_OECM_COVERAGE_ECOREGIONS]: extendPaOecmCoverageEcoregionsMapInfo(),
		[DISPLAY_DATA_OF_MAP.SPI]: spiMapInfo(),
		[DISPLAY_DATA_OF_MAP.PRO_NET]: proNetMapInfo(),
		[DISPLAY_DATA_OF_MAP.PAI]: paiMapInfo(),
		[DISPLAY_DATA_OF_MAP.PROT_CONN]: protConnMapInfo(),
		[DISPLAY_DATA_OF_MAP.PARC]: parcMapInfo(),
		[DISPLAY_DATA_OF_MAP.MANAGEMENT_EFFECTIVENESS_ASSESSMENTS_PER_COUNTRY_SUB_REGION]: pameMapInfo(),
		[DISPLAY_DATA_OF_MAP.NATIONAL_SUB_REGIONAL_COVERAGE_PA_AND_OECMS_ITTS]: nationalSubRegionalCoveragePaAndOecmsAndITTMapInfo()
	}

	return _mapsInfo
}
function getAreas(mapInfo: MapInfo | undefined, translations: MapTranslation) {
	const areas: MapOption<MapArea>[] = []
	if (Array.isArray(mapInfo?.resources)) {
		for (const resource of mapInfo.resources) {
			const area = resource.area
			if (!areas.find(_area => _area.value === area)) {
				areas.push({ label: translations.areas[area], value: resource.area })
			}
		}
	}
	return areas
}
function getTypes(mapInfo: MapInfo | undefined, translations: MapTranslation): MapOption<MapAreaType>[] {
	const types: MapOption<MapAreaType>[] = []
	if (Array.isArray(mapInfo?.resources)) {
		const typesTranslation = translations.types
		for (const { type } of mapInfo.resources) {
			if (!types.find(_type => _type.value === type)) {
				switch (type) {
					case MapAreaType.PA:
						types.push({ label: typesTranslation.pa, value: type })
						break
					case MapAreaType.PA_OECMS:
						types.push({ label: typesTranslation.pa_oecms, value: type })
						break
					case MapAreaType.PA_OECMS_ITTS:
						types.push({ label: typesTranslation.pa_oecms_itts, value: type })
						break
					default:
						break
				}
			}
		}
	}
	return types
}
function getLayerId(legendId: EsriLayerId, resourceId: MAP_RESOURCES) {
	return `${legendId}--${resourceId}`
}
function getMapInfo(displayDataOf: DISPLAY_DATA_OF_MAP | string) {
	const _mapsInfo = mapsInfo()
	return computed<MapInfo | undefined>(() => _mapsInfo ? _mapsInfo[displayDataOf as DISPLAY_DATA_OF_MAP] ?? undefined : undefined)
}
function getStyles(translations: MapTranslation): MapStyleInfo[] {
	const { VITE_MAPBOX_STYLE_SATELLITE, VITE_MAPBOX_STYLE_TERRAIN } = useEnvs()
	return [
		{
			title: translations.style.terrain,
			link: VITE_MAPBOX_STYLE_TERRAIN
		},
		{
			title: translations.style.satellite,
			link: VITE_MAPBOX_STYLE_SATELLITE
		}

	]
}
function getDefaultAreas(mapInfo: MapInfo | undefined, translations: MapTranslation) {
	const areas = computed (() => getAreas(mapInfo, translations))
	const defaultAreaOption = computed(() => areas.value.length > 0 ? areas.value[0].value : undefined)

	return {
		areas,
		defaultAreaOption
	}
}
function getDefaultRealms(mapInfo: MapInfo | undefined, translations: MapTranslation, chosenArea: Ref<MapArea | undefined>) {
	const realms = computed(() => {
		if (chosenArea.value && mapInfo) {
			return (Object.keys(mapInfo.areas[chosenArea.value] ?? []) as MapRealm[]).map(key => ({
				label: translations.realms[key],
				value: key
			}))
		}
		return []
	})
	const defaultRealmOption = computed(() => realms.value.length > 0 ? realms.value[0].value : undefined)

	return {
		realms,
		defaultRealmOption
	}
}
function getDefaultLayers(
	mapInfo: MapInfo | undefined,
	translations: MapTranslation,
	chosenArea: Ref<MapArea | undefined>,
	chosenRealm: Ref<MapRealm | undefined>,
	chosenType: Ref<MapAreaType | undefined>,
	parentIso3Chosen: Ref<boolean>
) {
	const layers = computed<(MapOption<MapLegend> & { colour: string | undefined })[]>(() => {
		if (mapInfo && mapInfo.areas && chosenArea.value && chosenRealm.value) {
			const area = mapInfo.areas[chosenArea.value]
			if (area) {
				const chosenRealmLayers = area[chosenRealm.value]?.legends
				if (chosenRealmLayers) {
					return chosenRealmLayers.map((layer) => {
						let colour = undefined
						if (chosenType.value) {
							if (parentIso3Chosen.value) {
								if (layer.legendColours.iso !== undefined) {
									colour = layer.legendColours.iso[chosenType.value]
								}
							}
							else {
								colour = layer.legendColours[chosenType.value]
							}
						}
						const convertedLayer = {
							/**
							 * If you see typescript errors here is because you don't have a legend(s) defined in app/frontend/types/translations.ts.
							 * It is likely you are adding/updating legends. so
							 * - Add translation to config/locales/en.yml in map legends
							 * - Add the type to legends in app/frontend/types/translations.ts.
							 * - Reload the page to check if the page is showing your translations
							 * - The error should go away now
							 * - We deliberately have typescript for translations as to remind you backend also needs adding
							 */
							label: translations.legends[layer.legend] ?? layer.legend,
							value: layer.legend,
							colour
						}
						return convertedLayer
					})
				}
			}
		}
		return []
	})
	const defaultLayers = computed<MapLegend[]>(() => layers.value.length > 0 ? layers.value.map(layer => layer.value) : [])

	return {
		layers,
		defaultLayers
	}
}
function getDefaultTypes(mapInfo: MapInfo | undefined, translations: MapTranslation) {
	const types = computed<MapOption<MapAreaType>[]>(() => getTypes(mapInfo, translations))
	const defaultType = computed<MapOption<MapAreaType> | undefined>(() => types.value.length > 0 ? types.value[0] : undefined)
	return {
		types,
		defaultType
	}
}
function getMapAppliedEsriLayers(map: Map) {
	// All Esri layers have metadata in them
	return map.getStyle().layers.filter(layer => (layer as LayerSpecificationWithExtra).metadata?.esriLayerId !== undefined) as LayerSpecificationWithExtra[]
}
function getAllAppliedEsriLayers(layers: LayerSpecification[]) {
	return layers.filter(layer => (layer as LayerSpecificationWithExtra).metadata?.esriLayerId !== undefined) as LayerSpecificationWithExtra[]
}
export function useMap() {
	return {
		getMapInfo,
		getStyles,
		getTypes,
		getAreas,
		getLayerId,
		getDefaultAreas,
		getDefaultRealms,
		getDefaultLayers,
		getDefaultTypes,
		getMapAppliedEsriLayers,
		convertColoursToSameAsLegendColour,
		getAllAppliedEsriLayers,
		getInfoFromESRIServer
	}
}
export default useMap
