function activeOnlyWhenEnteringViewpoint(element: HTMLElement, func: () => void) {
	const observer = new IntersectionObserver((entries) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting && (entry.target as { offsetLeft: number } & Element).offsetLeft > 0) {
				func()
				observer.unobserve(element)
			}
		})
	}, {
		root: null,
		rootMargin: "30% 0% 30% 0%",
		threshold: 0
	})
	observer.observe(element)
}
export function useCommon() {
	return {
		freezeBackground: (freeze: boolean) => (document.body.style.overflow = freeze ? "hidden" : ""),
		freezeVideo: (freeze: boolean, element: HTMLVideoElement) => freeze ? element.pause() : element.play(),
		activeOnlyWhenEnteringViewpoint
	}
}
export default useCommon
