/**
 * the values below also used as translation (rails i18n) keys config/locales/en.yml as well as the layer names in ESRI
 * so if there is a change in ESRI then you need to change here + translation
 *  */
/**
 * 	If anything names changed in ESRI layers then change here will be good enough
 *	You also need to change vector tiles info in /app/helpers/application_helper.rb
 */
export enum MapArea {
	GLOBAL = "global",
	NATIONAL = "national",
	SUB_REGIONAL = "sub_regional"
}
export enum MapRealm {
	DEFAULT = "default",
	TERRESTRIAL = "terrestrial",
	MARINE = "marine",
	ALL_KBAS = "all_kbas",
	FRESHWATER = "freshwater",
	POTENTIAL = "potential",
	REALIZED = "realized"

}
export enum EsriLayerId {
	// PP_LIVE_24_CPT3_WDPA PP_LIVE_24_CPT3_WDOECM
	ALL_TERRESTRIAL_POLYGON = "WDPA_poly_Aug2024/Terrestrial/1",
	ALL_TERRESTRIAL_POINT = "WDPA_point_Aug2024/Terrestrial",

	ALL_TERRESTRIAL_POLYGON_OECMS = "WDOECM_poly_Aug2024/Terrestrial/1",
	ALL_TERRESTRIAL_POINT_OECMS = "WDOECM_point_Aug2024/Terrestrial",

	ALL_MARINE_POLYGON = "this_layer_added_from_backend_PP_Live_24_Cpt3_WDPA_polygon/all_marine",
	ALL_MARINE_POINT = "this_layer_added_from_backend_PP_Live_24_Cpt3_WDPA_point/all_marine",
	ALL_MARINE_ABNJ = "this_layer_added_from_backend_PP_Live_24_Cpt3_WDPA_polygon/all_marine_abnj",
	ALL_MARINE_POLYGON_OECMS = "this_layer_added_from_backend_PP_Live_24_Cpt3_WDOECM_polygon/all_marine",
	ALL_MARINE_POINT_OECMS = "this_layer_added_from_backend_PP_Live_24_Cpt3_WDOECM_point/all_marine",

	POLYGON_ABNJ = "WDPA_poly_Aug2024_ABNJ/ABNJ/1", // where "==", "_symbol",2
	NATIONAL_WATER_POLY_1 = "WDPA_poly_Aug2024/NationalWaters/1:1",
	NATIONAL_WATER_POLY_2 = "WDPA_poly_Aug2024/NationalWaters/1:2",
	NATIONAL_WATER_POINT = "WDPA_point_Aug2024/NationalWaters",

	MARINE_NATIONAL_WATERS_POINT_OECMS = "WDOECM_point_Aug2024/Marine", // where "==", "_symbol",1
	MARINE_NATIONAL_WATERS_POLYGON_OECMS = "WDOECM_poly_Aug2024/Marine/1:1", // where "==", "_symbol",1
	MARINE_NATIONAL_WATERS_POLYGON_OECMS_1 = "WDOECM_poly_Aug2024/Marine/1:2", // where "==", "_symbol",2

	// PP_LIVE_24_CPT3_WDPA_NAT PP_LIVE_24_CPT3_WDPA_NAT_OECMS PP_Live_24_Cpt3_WDPA_Nat_Parent
	LAND_DATA_DEFICIENT = "Land/Data deficient",
	LAND_LESS_THAN_FIVE = "Land/\u003C5",
	LAND_FIVE_TO_TEN = "Land/5\u003C10",
	LAND_TEN_TO_SEVENTEEN = "Land/10\u003C17",
	LAND_SEVENTEEN_TO_THIRTY = "Land/17\u003C30",
	LAND_MORE_THAN_THIRTY = "Land/\u003E30",
	MARINE_DATA_DEFICIENT = "Marine/Data deficient",
	MARINE_LESS_THAN_FIVE = "Marine/\u003C5",
	MARINE_FIVE_TO_TEN = "Marine/5\u003C10",
	MARINE_TEN_TO_SEVENTEEN = "Marine/10\u003C17",
	MARINE_SEVENTEEN_TO_THIRTY = "Marine/17\u003C30",
	MARINE_MORE_THAN_THIRTY = "Marine/\u003E30",
	LAND_DATA_DEFICIENT_ISO3 = "Countries_Parent/Data deficient",
	LAND_LESS_THAN_FIVE_ISO3 = "Countries_Parent/\u003C5",
	LAND_FIVE_TO_TEN_ISO3 = "Countries_Parent/5\u003C10",
	LAND_TEN_TO_SEVENTEEN_ISO3 = "Countries_Parent/10\u003C17",
	LAND_SEVENTEEN_TO_THIRTY_ISO3 = "Countries_Parent/17\u003C30",
	LAND_MORE_THAN_THIRTY_ISO3 = "Countries_Parent/\u003E30",
	MARINE_DATA_DEFICIENT_ISO3 = "Marine_Parent/Data deficient",
	MARINE_LESS_THAN_FIVE_ISO3 = "Marine_Parent/\u003C5",
	MARINE_FIVE_TO_TEN_ISO3 = "Marine_Parent/5\u003C10",
	MARINE_TEN_TO_SEVENTEEN_ISO3 = "Marine_Parent/10\u003C17",
	MARINE_SEVENTEEN_TO_THIRTY_ISO3 = "Marine_Parent/17\u003C30",
	MARINE_MORE_THAN_THIRTY_ISO3 = "Marine_Parent/\u003E30",

	// PP_LIVE_24_CPT3_WDPA_REG PP_LIVE_24_CPT3_WDPA_REG_OECMS
	LAND_SUBREGION_DATA_DEFICIENT = "Land_SubRegion/Data deficient",
	LAND_SUBREGION_LESS_THAN_FIVE = "Land_SubRegion/\u003C5",
	LAND_SUBREGION_FIVE_TO_TEN = "Land_SubRegion/5\u003C10",
	LAND_SUBREGION_TEN_TO_SEVENTEEN = "Land_SubRegion/10\u003C17",
	LAND_SUBREGION_SEVENTEEN_TO_THIRTY = "Land_SubRegion/17\u003C30",
	LAND_SUBREGION_MORE_THAN_THIRTY = "Land_SubRegion/\u003E30",
	MARINE_SUBREGION_DATA_DEFICIENT = "Marine_SubRegion/Data deficient",
	MARINE_SUBREGION_LESS_THAN_FIVE = "Marine_SubRegion/\u003C5",
	MARINE_SUBREGION_FIVE_TO_TEN = "Marine_SubRegion/5\u003C10",
	MARINE_SUBREGION_TEN_TO_SEVENTEEN = "Marine_SubRegion/10\u003C17",
	MARINE_SUBREGION_SEVENTEEN_TO_THIRTY = "Marine_SubRegion/17\u003C30",
	MARINE_SUBREGION_MORE_THAN_THIRTY = "Marine_SubRegion/\u003E30",

	// PP_LIVE_24_CPT3_KBA_NAT PP_LIVE_24_CPT3_KBA_OECMS
	KBA_FULLY_COVERED = "KBAsGlobal_2024_June_02_POL_PA/Fully covered",
	KBA_PARTIALLY_COVERED = "KBAsGlobal_2024_June_02_POL_PA/Partially covered",
	KBA_NOT_COVERED = "KBAsGlobal_2024_June_02_POL_PA/Not covered",
	KBA_FULLY_COVERED_OECMS = "KBAsGlobal_2024_June_PA_OECM/Fully covered",
	KBA_PARTIALLY_COVERED_OECMS = "KBAsGlobal_2024_June_PA_OECM/Partially covered",
	KBA_NOT_COVERED_OECMS = "KBAsGlobal_2024_June_PA_OECM/Not covered",

	// PP_LIVE_24_CPT4_NCP_NAT PP_LIVE_24_CPT4_NCP_NAT_OECMS PP_LIVE_24_CPT4_NCP_REG PP_LIVE_24_CPT4_NCP_REG_OECMS
	REALISED_LESS_THEN_TWENTY = "Realised/\u003C20",
	REALISED_TWENTY_TO_FOURTY = "Realised/20\u003C40",
	REALISED_FOURTY_TO_SIXTY = "Realised/40\u003C60",
	REALISED_SIXTY_TO_EIGHTY = "Realised/60\u003C80",
	REALISED_LESS_THEN_HUNDARD = "Realised/\u003C100",
	POTENTIAL_LESS_THEN_TWENTY	= "Potential/\u003C20",
	POTENTIAL_TWENTY_TO_FOURTY = "Potential/20\u003C40",
	POTENTIAL_FOURTY_TO_SIXTY = "Potential/40\u003C60",
	POTENTIAL_SIXTY_TO_EIGHTY = "Potential/60\u003C80",
	POTENTIAL_LESS_THEN_HUNDARD = "Potential/\u003C100",

	// PP_LIVE_24_CPT5_ECO_MARINE_WDPAS PP_LIVE_24_CPT5_ECO_TERR_WDPAS PP_LIVE_24_CPT5_ECO_FRESH_WDPAS PP_LIVE_24_CPT5_ECO_TERR_OECMS PP_LIVE_24_CPT5_ECO_MARINE_OECMS PP_LIVE_24_CPT5_ECO_FRESH_OECMS
	FRESHWATER_ECOREGIONS_LESS_THEN_FIVE = "freshwater_ecoregions/\u003C5",
	FRESHWATER_ECOREGIONS_FIVE_TO_SIXTEEN = "freshwater_ecoregions/5-16",
	FRESHWATER_ECOREGIONS_SIXTEEN_TO_TWENTY_NINE = "freshwater_ecoregions/16-29",
	FRESHWATER_ECOREGIONS_MORE_THAN_OR_EQUAL_THIRTY = "freshwater_ecoregions/\u003E30",

	MARINE_ECOREGIONS_LESS_THEN_TEN = "marine_ecoregions/\u003C10",
	MARINE_ECOREGIONS_TEN_TO_NINETEEN = "marine_ecoregions/10-19",
	MARINE_ECOREGIONS_NINETEEN_TO_TWENTY_NINE = "marine_ecoregions/19-29",
	MARINE_ECOREGIONS_MORE_THAN_OR_EQUAL_THIRTY = "marine_ecoregions/\u003E30",

	TERRESTRIAL_ECOREGIONS_LESS_THEN_FIVE = "terrestrial_ecoregions/\u003C5",
	TERRESTRIAL_ECOREGIONS_FIVE_TO_SIXTEEN = "terrestrial_ecoregions/5-16",
	TERRESTRIAL_ECOREGIONS_SIXTEEN_TO_TWENTY_NINE = "terrestrial_ecoregions/16-29",
	TERRESTRIAL_ECOREGIONS_MORE_THAN_OR_EQUAL_THIRTY = "terrestrial_ecoregions/\u003E30",

	//  PP_Live_24_Cpt5_SPI PP_Live_24_Cpt5_SPI_OECMs PP_Live_24_Cpt5_SPI_Reg PP_Live_24_Cpt5_SPI_Reg_Oecms
	// eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
	SPI_LAND_DATA_DEFICIENT = "Land/Data deficient",
	SPI_LAND_LESS_THAN_TWENTY = "Land/\u003C20",
	SPI_LAND_TWENTY_TO_FOURTY = "Land/20\u003C40",
	SPI_LAND_FOURTY_TO_SIXTY = "Land/40\u003C60",
	SPI_LAND_SIXTY_To_EIGHTY = "Land/60\u003C80",
	SPI_LAND_MORE_THAN_EIGHTY = "Land/\u003E80",
	// eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
	SPI_MARINE_DATA_DEFICIENT = "Marine/Data deficient",
	SPI_MARINE_LESS_THAN_TWENTY = "Marine/\u003C20",
	SPI_MARINE_TWENTY_TO_FOURTY = "Marine/20-40",
	SPI_MARINE_FOURTY_TO_SIXTY = "Marine/40-60",
	SPI_MARINE_SIXTY_To_EIGHTY = "Marine/60-80",
	SPI_MARINE_EIGHTY_TO_HUNDARD = "Marine/80-100",
	// eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
	SPI_LAND_DATA_DEFICIENT_SUBREGION = "Land_SubRegion/Data deficient",
	SPI_LAND_LESS_THAN_TWENTY_SUBREGION = "Land_SubRegion/\u003C20",
	SPI_LAND_TWENTY_TO_FOURTY_SUBREGION = "Land_SubRegion/20\u003C40",
	SPI_LAND_FOURTY_TO_SIXTY_SUBREGION = "Land_SubRegion/40\u003C60",
	SPI_LAND_SIXTY_To_EIGHTY_SUBREGION = "Land_SubRegion/60\u003C80",
	SPI_LAND_MORE_THAN_EIGHTY_SUBREGION = "Land_SubRegion/\u003E80",
	// eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
	SPI_MARINE_DATA_DEFICIENT_SUBREGION = "Marine_SubRegion/Data deficient",
	SPI_MARINE_LESS_THAN_TWENTY_SUBREGION = "Marine_SubRegion/\u003C20",
	SPI_MARINE_TWENTY_TO_FOURTY_SUBREGION = "Marine_SubRegion/20\u003C40",
	SPI_MARINE_FOURTY_TO_SIXTY_SUBREGION = "Marine_SubRegion/40\u003C60",
	SPI_MARINE_SIXTY_To_EIGHTY_SUBREGION = "Marine_SubRegion/60\u003C80",
	SPI_MARINE_MORE_THAN_EIGHTY_SUBREGION = "Marine_SubRegion/\u003E80",

	//	PP_LIVE_24_CPT6_PRONET_NAT PP_LIVE_24_CPT6_PRONET_NAT_OECMS PP_LIVE_24_CPT6_PRONET_REG PP_LIVE_24_CPT6_PRONET_REG_OECMS
	PRONET_DATA_DEFICIENT = "Countries/Data deficient",
	PRONET_LESS_THAN_ZERO_POINT_TWO = "Countries/\u003C0.2",
	PRONET_ZERO_POINT_TWO_TO_ZERO_POINT_FOUR = "Countries/0.2-0.4",
	PRONET_ZERO_POINT_FOUR_TO_ZERO_POINT_SIX = "Countries/0.4-0.6",
	PRONET_ZERO_POINT_SIX_TO_ZERO_POINT_EIGHT = "Countries/0.6-0.8",
	PRONET_COUNTRIES_LESS_THAN_ONE = "Countries/\u003C1",
	// eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
	PRONET_DATA_DEFICIENT_REG = "Land_SubRegion/Data deficient",
	PRONET_LESS_THAN_ZERO_POINT_TWO_REG = "Land_SubRegion/\u003C0.2",
	PRONET_ZERO_POINT_TWO_TO_ZERO_POINT_FOUR_REG = "Land_SubRegion/0.2-0.4",
	PRONET_ZERO_POINT_FOUR_TO_ZERO_POINT_SIX_REG = "Land_SubRegion/0.4-0.6",
	PRONET_ZERO_POINT_SIX_TO_ZERO_POINT_EIGHT_REG = "Land_SubRegion/0.6-0.8",
	PRONET_LESS_THAN_ONE_REG = "Land_SubRegion/\u003C1",

	// PP_Live_24_Cpt6_PAI_Nat PP_Live_24_Cpt6_PAI_Reg
	// eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
	PAI_DATA_DEFICIENT = "Land/Data deficient",
	PAI_LESS_THAN_FOUR = "Land/\u003C4.4",
	PAI_FOUR_TO_SIX = "Land/4.4 - 6.2",
	PAI_SIX_TO_EIGHT = "Land/6.2- 8.1",
	PAI_ABOVE_EIGHT = "Land/\u003E8.1",
	// eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
	PAI_REG_DATA_DEFICIENT = "Land_SubRegion/Data deficient",
	PAI_REG_LESS_THAN_FOUR = "Land_SubRegion/\u003C4.2",
	PAI_REG_FOUR_TO_FIVE = "Land_SubRegion/4.2 - 5.7",
	PAI_REG_FIVE_TO_SEVEN = "Land_SubRegion/5.7 - 7.0",
	PAI_REG_ABOVE_SEVEN = "Land_SubRegion/\u003E7.0",

	// PP_Live_24_Cpt6_ProtConn PP_Live_24_Cpt6_ProtConn_OECMs
	PROTCONN_COUNTRIES_LESS_THAN_TEN = "Countries/\u003C10",
	PROTCONN_COUNTRIES_TEN_TO_TWENTY = "Countries/10-20",
	PROTCONN_COUNTRIES_TWENTY_TO_FOURTY = "Countries/20-40",
	PROTCONN_COUNTRIES_FOURTY_TO_SIXTY = "Countries/40-60",
	PROTCONN_COUNTRIES_SIXTY_TO_THUNDARD = "Countries/60-100",
	// eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
	PROTCONN_DATA_DEFICIENT = "Countries/Data deficient",

	// PP_Live_24_Cpt6_ProtConn_Reg PP_Live_24_Cpt6_ProtConn_Reg_OECMs
	// eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
	SUB_REG_DATA_DEFICIENT = "Land_SubRegion/Data deficient",
	SUB_REGIONS_LESS_THAN_TEN = "Land_SubRegion/\u003C10",
	SUB_REGIONS_TEN_TO_TWENTY = "Land_SubRegion/10\u003C20",
	// eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
	SUB_REGIONS_TWENTY_TO_FOURTY = "Land_SubRegion/20\u003C40",
	// eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
	SUB_REGIONS_FOURTY_TO_SIXTY = "Land_SubRegion/40\u003C60",
	SUB_REGIONS_MORE_THAN_SIXTY = "Land_SubRegion/\u003E60",

	//  PP_Live_24_Cpt6_PARC_Nat PP_Live_24_Cpt6_PARC_Nat_OECMs
	// eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
	PARC_DATA_DEFICIENT = "Countries/Data deficient",
	// eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
	PARC_LESS_THAN_ZERO_POINT_TWO = "Countries/\u003C0.2",
	PARC_ZERO_POINT_TWO_TO_ZERO_POINT_FOUR = "Countries/0.2\u003C0.4",
	PARC_ZERO_POINT_TWO_TO_ZERO_POINT_FOUR_OECMS = "Countries/0.2-\u003C0.4",
	PARC_ZERO_POINT_FOUR_ZERO_POINT_SIX = "Countries/0.4\u003C0.6",
	PARC_ZERO_POINT_SIX_ZERO_POINT_EIGHT = "Countries/0.6\u003C0.8",
	// eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
	PARC_LESS_THAN_ONE = "Countries/\u003C1",
	// PP_Live_24_Cpt6_PARC_Reg PP_Live_24_Cpt6_PARC_Reg_OECMs
	// eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
	PARC_REG_DATA_DEFICIENT = "Land_SubRegion/Data deficient",
	PARC_REG_ZERO_POINT_FIVE = "Land_SubRegion/\u003C0.5",
	PARC_REG_ZERO_POINT_FIVE_TO_ZERO_POINT_SIX = "Land_SubRegion/0.5-0.6",
	PARC_REG_ZERO_POINT_SIX_TO_ZERO_POINT_SEVEN = "Land_SubRegion/0.6-0.7",
	PARC_REG_ZERO_POINT_SEVEN_TO_ZERO_POINT_EIGHT = "Land_SubRegion/0.7-0.8",
	PARC_REG_ZERO_POINT_EIGHT_TO_ONE = "Land_SubRegion/0.8-1",

	// PP_Live_24_Cpt7_PAME_Nat
	PAME_TERRESTRAIL_DATA_DEFICIENT = "Land/Data deficient/1",
	// eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
	PAME_TERRESTRAIL_LESS_THAN_FIVE = "Land/\u003C5",
	// eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
	PAME_TERRESTRAIL_FIVE_TO_TEN = "Land/5\u003C10",
	PAME_TERRESTRAIL_TEN_TO_THIRTY = "Land/10\u003C30",
	PAME_TERRESTRAIL_THIRTY_TO_SIXTY = "Land/30\u003C60",
	PAME_TERRESTRAIL_LESS_THAN_HUNDARD = "Land/\u003C100",
	PAME_MARINE_DATA_DEFICIENT = "Marine/Data deficient/1",
	// eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
	PAME_MARINE_LESS_THAN_FIVE_PERCENT = "Marine/\u003C5",
	PAME_MARINE_FIVE_TO_TEN_PERCENT = "Marine/5-10",
	PAME_MARINE_TEN_TO_TWENTY_PERCENT = "Marine/10-20",
	PAME_MARINE_TWENTY_TO_THIRTY_PERCENT = "Marine/20-30",
	// PP_Live_24_Cpt7_PAME_Reg

	PAME_REG_TERRESTRAIL_DATA_DEFICIENT = "Land_SubRegion/Data deficient/1",
	PAME_REG_TERRESTRAIL_LESS_THAN_THREE = "Land_SubRegion/\u003C3",
	PAME_REG_TERRESTRAIL_THREE_TO_SIX = "Land_SubRegion/3-6",
	PAME_REG_TERRESTRAIL_SIX_TO_THIRTEEN = "Land_SubRegion/6-13",
	PAME_REG_MARINE_DATA_DEFICIENT = "Marine_SubRegion/Data deficient/1",
	PAME_REG_MARINE_LESS_THAN_TWO = "Marine_SubRegion/\u003C2",
	PAME_REG_MARINE_TWO_TO_FIVE = "Marine_SubRegion/2-5",
	PAME_REG_MARINE_FIVE_TO_TEN = "Marine_SubRegion/5-10",

	// PP_Live_24_Cpt9_ITTs_Reg
	// eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
	LAND_SUBREGION_DATA_DEFICIENT_REG_ITT = "Land_SubRegion/Data deficient",
	// eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
	LAND_SUBREGION_LESS_THAN_FIVE_REG_ITT = "Land_SubRegion/\u003C5",
	// eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
	LAND_SUBREGION_FIVE_TO_TEN_REG_ITT = "Land_SubRegion/5\u003C10",
	// eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
	LAND_SUBREGION_TEN_TO_SEVENTEEN_REG_ITT = "Land_SubRegion/10\u003C17",
	// eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
	LAND_SUBREGION_SEVENTEEN_TO_THIRTY_REG_ITT = "Land_SubRegion/17\u003C30",
	// eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
	LAND_SUBREGION_MORE_THAN_THIRTY_REG_ITT = "Land_SubRegion/\u003E30"
}

export enum MapAreaType {
	PA = "pa",
	PA_OECMS = "pa_oecms",
	PA_OECMS_ITTS = "pa_oecms_itts"
}
