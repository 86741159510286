<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.6861 6.2721H10.0287V7.9371C10.5102 6.9795 11.745 6.1191 13.5999 6.1191C17.1558 6.1191 18 8.0253 18 11.5227V18H14.4V12.3192C14.4 10.3275 13.9185 9.2043 12.6927 9.2043C10.9926 9.2043 10.2861 10.4148 10.2861 12.3183V18H6.6861V6.2721ZM0.513 17.847H4.113V6.1191H0.513V17.847ZM4.6287 2.295C4.62883 2.59675 4.56899 2.89551 4.45265 3.17393C4.33631 3.45235 4.16579 3.70486 3.951 3.9168C3.51576 4.34936 2.92663 4.59149 2.313 4.59C1.70045 4.58959 1.11268 4.34808 0.6768 3.9177C0.46279 3.70504 0.292847 3.45224 0.176709 3.17379C0.0605713 2.89534 0.000521219 2.5967 0 2.295C0 1.6857 0.243 1.1025 0.6777 0.6723C1.1132 0.241343 1.70122 -0.00026929 2.3139 2.25238e-07C2.9277 2.25238e-07 3.5163 0.2421 3.951 0.6723C4.3848 1.1025 4.6287 1.6857 4.6287 2.295Z"
      fill="currentColor"
    />
  </svg>
</template>
