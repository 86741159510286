<template>
  <div
    class="ct-chart-clustered-bar"
    :class="`ct-chart-clustered-bar--${cmsInfo?.displayDataOf}`"
  >
    <span
      v-if="showTitle"
      class="ct-chart-clustered-bar__text"
      v-html="title"
    />
    <div
      ref="chartRef"
      class="ct-chart-clustered-bar__chart"
      :style="{'height': `${chartHeight}rem`}"
    />
  </div>
</template>

<script setup lang="ts">
import type { CmsInfoChartClusteredBar, TargetLine } from "@/types/entrypoints"
import { computed, onMounted, onUnmounted } from "vue"
import * as am5 from "@amcharts/amcharts5/index"
import * as am5xy from "@amcharts/amcharts5/xy"
import { ref } from "vue"
import { ClusteredBarChartData } from "@/types/chartsData"
import { ChartSettings } from "@/types/backend"
import useChart from "@/composables/useChart"
import colours from "../../../../tailwind/colours"

const props = defineProps<{
	chartSettings: ChartSettings
	cmsInfo: CmsInfoChartClusteredBar | undefined
	chartData: ClusteredBarChartData
	title: string
	showTitle: boolean
	targetLinesInfo?: TargetLine[] | undefined
}>()
const chartRef = ref()
const { getXAxisLabelSettings, xRendererLabelLimitWidth, legendFont, getChartTheme, getInitlisedVariables } = useChart()
let chart: am5xy.XYChart | undefined
const DATA_AXIS_KEY = "y_axis"
const xAxisIntervalCount = computed(() => Number.isInteger(props.chartSettings?.show_data_in_every_x_interval) ? (props.chartSettings?.show_data_in_every_x_interval ?? 70) : 70)
const { allLegends, allColours, min, max, unit, showYAxisLabels } = getInitlisedVariables(props.chartSettings, props.cmsInfo?.displayDataOf)
function addSeries({ root, xAxis, yAxis }: {
	root: am5.Root
	xAxis: am5xy.ValueAxis<am5xy.AxisRenderer>
	yAxis: am5xy.CategoryAxis<am5xy.AxisRenderer>
}) {
	const seriess = []
	if (chart) {
		for (const legend of allLegends.value) {
			const config: am5xy.IXYSeriesSettings = {
				name: legend,
				xAxis: xAxis,
				yAxis: yAxis,
				valueXField: legend,
				categoryYField: DATA_AXIS_KEY,
				sequencedInterpolation: true,
				tooltip: am5.Tooltip.new(root, {
					pointerOrientation: "horizontal",
					labelText: `{name}: [bold]{valueX}${unit.value}[/]`
				})
			}
			const colour = allColours.value[legend]
			if (colour) {
				config.fill = colour
			}
			let series = chart.series.push(
				am5xy.ColumnSeries.new(root, config)
			)

			series.columns.template.setAll({
				height: am5.p100,
				strokeOpacity: 0
				// tooltipText: `{name}: [bold]{valueYWorking.formatNumber('#.#')}${unit.value}[/]`,
				// tooltipY: am5.percent(80)
			})
			series.bullets.push(function () {
				return am5.Bullet.new(root, {
					sprite: am5.Label.new(root, { })
				})
			})
			if (props.chartData) {
				series.data.setAll(props.chartData)
			}
			series.appear()
			seriess.push(series)
		}
	}
	return seriess
}
function addYAxis({ root, chart: _chart }: { root: am5.Root
	chart: am5xy.XYChart
}) {
	const yRenderer = am5xy.AxisRendererY.new(root, {
		inversed: true,
		cellStartLocation: 0.1,
		minorGridEnabled: true
	})
	yRenderer.grid.template.setAll({
		visible: false
	})
	let yAxis = _chart.yAxes.push(
		am5xy.CategoryAxis.new(root, {
			categoryField: DATA_AXIS_KEY,
			renderer: yRenderer,
			visible: showYAxisLabels.value
		})
	)

	yAxis.data.setAll(props.chartData)
	return yAxis
}
function addXAxis({ root, chart: _chart }: { root: am5.Root
	chart: am5xy.XYChart
}) {
	const xRenderer = am5xy.AxisRendererX.new(root, {
		strokeOpacity: 0.1,
		minGridDistance: xAxisIntervalCount.value
	})
	xRenderer.grid.template.setAll({
		visible: false
	})
	xRenderer.labels.template.setAll({ ...xRendererLabelLimitWidth() })
	const config: am5xy.IValueAxisSettings<am5xy.AxisRenderer> = {
		numberFormat: unit.value ? `,###'${unit.value}'` : ",###",
		strictMinMax: true,
		visible: true,
		calculateTotals: true,
		renderer: xRenderer
	}
	if (typeof min.value === "number") {
		config.min = min.value
	}
	if (typeof max.value === "number") {
		config.max = max.value
	}
	let xAxis = _chart.xAxes.push(
		am5xy.ValueAxis.new(root, config))
	const xAxisTitle = props.chartSettings?.x_axis_label
	if (xAxisTitle) {
		xAxis.children.moveValue(am5.Label.new(root, {
			...getXAxisLabelSettings(xAxisTitle)
		}))
	}
	return xAxis
}
function addLegends({ root, chart: _chart }: { root: am5.Root
	chart: am5xy.XYChart
}) {
	let legend = _chart.children.push(
		am5.Legend.new(root, {
			centerX: am5.p50,
			x: am5.p50,
			clickTarget: "none"
		})
	)
	legend.labels.template.setAll({ ...legendFont })
	legend.data.setAll(_chart.series.values)
}
function addCursors({ root, chart: _chart }: { root: am5.Root
	chart: am5xy.XYChart
}) {
	let cursor = _chart.set(
		"cursor",
		am5xy.XYCursor.new(root, {
			behavior: "zoomY"
		})
	)
	cursor.lineY.set("forceHidden", true)
	cursor.lineX.set("forceHidden", true)
}
const chartHeight = computed(() => props.chartData.length > 5 ? props.chartData.length * 8 : props.chartData.length * 12)
function addTargetLines({ seriess, targetLinesInfo, xAxis }: {
	root: am5.Root
	targetLinesInfo: TargetLine[]
	seriess: am5xy.ColumnSeries[]
	xAxis: am5xy.ValueAxis<am5xy.AxisRenderer>
}) {
	let graciousRange = 100
	const series = seriess[0]
	if (series) {
		for (const targetLineInfo of targetLinesInfo) {
			if (targetLineInfo) {
				const colourCode = colours.colors.chart[targetLineInfo.targetType.internalId] as string
				const colour = am5.color(colourCode)
				let dataValue = (max.value ?? 0) * graciousRange
				if (unit.value === "%") {
					dataValue = Number(targetLineInfo.targetPercentage)
				}
				var seriesRangeDataItem = xAxis.makeDataItem({ value: dataValue, endValue: 0 })
				series.createAxisRange(seriesRangeDataItem)
				seriesRangeDataItem.get("grid")?.setAll({
					strokeOpacity: 1,
					visible: true,
					stroke: colour,
					strokeWidth: 3,
					strokeDasharray: [10, 10],
					fill: colour
				})
				seriesRangeDataItem.get("label")?.setAll({
					location: 0,
					visible: true,
					inside: true,
					text: targetLineInfo.targetType.label,
					centerX: 0,
					centerY: am5.percent((chartHeight.value * 60) - graciousRange),
					fontWeight: "bold",
					fill: colour
				})
				graciousRange = graciousRange + 100
			}
		}
	}
}

function initChart() {
	if (Array.isArray(props.chartData)) {
		const root = am5.Root.new(chartRef.value)
		const theme = getChartTheme(root)
		root.setThemes([theme])
		chart = root.container.children.push(
			am5xy.XYChart.new(root, {
				layout: root.verticalLayout
			})
		)
		const xAxis = addXAxis({ chart, root })
		const yAxis = addYAxis({ chart, root })
		const seriess = addSeries({ root, xAxis, yAxis })
		addLegends({ chart, root })
		addCursors({ chart, root })
		if (Array.isArray(props.targetLinesInfo)) {
			addTargetLines({ root, seriess, targetLinesInfo: props.targetLinesInfo, xAxis })
		}
		chart.appear(1000, 100)
	}
}
onMounted(() => {
	initChart()
})
onUnmounted(() => {
	if (chart) chart.dispose()
})
</script>

<style scoped lang="postcss">
.ct-chart-clustered-bar {
  @apply flex flex-col gap-1;

  &__text {
    @apply custom-font__lrg-body-text-1;
  }

  &__chart {
    @apply w-[35rem] md:w-full;
  }
}
</style>
