<template>
  <div
    class="ct-content-block"
    :style="backgroundStyle"
  >
    <ImageCitationShowOnPage
      class="ct-content-block__container--citation"
      :citations="contentBlock?.backgroundImageInfo.citations"
      :makeTextWhite="true"
    >
      <div
        class="ct-content-block__container--main"
        :class="{
          'ct-content-block__container--centre': contentBlock?.centreAllNestedElements
        }"
      >
        <div class="ct-content-block__container--top">
          <h2
            v-if="contentBlock?.title"
            class="ct-content-block__title ct-content-block__title--desktop"
            v-html="contentBlock?.title"
          />
          <div class="ct-content-block__buttons">
            <DropdownSelect
              v-if="languageOptions.length > 1"
              v-model="currentLanguage"
              v-bind="{
                elementId: '',
                background: DropdownSelectBackground.TRANSPARENT,
                icon: DropdownSelectIcon.ARROW,
                optionsAreLinks: false,
                options: languageOptions,
                dropdownType: DropdownTypeIs.SELECT
              }"
            />
            <DropdownSelect
              v-for="(downloadButton, index) of allDropdowns"
              :key="`${index}DropdownInExecutiveSummary`"
              v-bind="{
                ...downloadButton,
                dropdownType: DropdownTypeIs.SELECT
              }"
            />
          </div>
        </div>
        <div class="ct-content-block__container--middle">
          <h2
            v-if="contentBlock?.title"
            class="ct-content-block__title ct-content-block__title--mobile"
            v-html="contentBlock?.title"
          />
          <ContentBlockText
            v-for="(eachSummary, index) in props.contentBlock?.list"
            v-show="eachSummary.language === currentLanguage"
            :key="`${index}eachSummaryOutside`"
            v-bind="{
              summary: eachSummary,
              currentLanguage,
              showPanel,
              title: contentBlock?.title
            }"
            @closePanel="togglePanel(false)"
          />
        </div>
        <div>
          <button
            v-if="contentBlock?.viewMoreButtonTitle"
            class="ct-content-block__view-more"
            @click="togglePanel(true)"
          >
            <span v-html="contentBlock?.viewMoreButtonTitle" />
          </button>
        </div>
      </div>
    </ImageCitationShowOnPage>
  </div>
</template>

<script setup lang="ts">
import type { DropdownsRaw, ContentBlock, DropdownOptionText } from "@/types/entrypoints"
import DropdownSelect from "@/components/DropdownSelect/Index.vue"
import ContentBlockText from "@/components/ContentBlock/Text.vue"
import { computed, ref } from "vue"
import { DropdownTypeIs } from "@/types/Components/dropdownSelect"
import { DropdownSelectBackground, DropdownSelectIcon } from "@/types/backend"
import ImageCitationShowOnPage from "@/components/ImageCitation/ShowOnPage.vue"

const props = defineProps<{
	contentBlock?: ContentBlock
	dropdownsRaw?: DropdownsRaw
}>()
const backgroundStyle = computed(() => {
	if (!props.contentBlock?.backgroundImageInfo.imageUrl)
		return
	return `background: linear-gradient(270deg, rgba(0, 0, 0, 0.00) -21.67%, rgba(0, 0, 0, 0.75) 44.17%), url('${props.contentBlock?.backgroundImageInfo.imageUrl}') lightgray 50% / cover no-repeat;`
})
const languageOptions = computed<DropdownOptionText[]>(
	() =>
		props.contentBlock?.list.map(executiveSummaryA => ({
			text: executiveSummaryA.language,
			value: executiveSummaryA.language
		})) ?? []
)
const currentLanguage = ref(languageOptions.value.length > 0 ? languageOptions.value[0].value : "")
const showPanel = ref(false)
function togglePanel(toggle: boolean) {
	showPanel.value = toggle
}
const allDropdowns = computed(() => {
	const _allDropdowns = []
	if (props.contentBlock && props.dropdownsRaw) {
		for (const nestedElement of props.contentBlock.allNestedElements) {
			const nestedDropdown = props.dropdownsRaw[nestedElement.id]
			if (nestedDropdown && nestedDropdown?.options?.length > 0) {
				_allDropdowns.push(nestedDropdown)
			}
		}
	}
	return _allDropdowns
})
</script>

<style scoped lang="postcss">
.ct-content-block {
  @apply min-h-[27.88rem] md:min-h-[32rem] px-4 py-12 md:p-[4.5rem] flex flex-col;

  &__container {
    &--citation{
      @apply grow;
    }

    &--main {
      @apply w-full lg:w-[53.44rem] flex flex-col justify-center gap-12 shrink-0;
    }

    &--top {
      @apply flex justify-end md:justify-between items-center;
    }

    &--middle {
      @apply flex flex-col gap-6;
    }

    &--centre {
      @apply flex items-center justify-center text-center w-full;
    }
  }

  &__buttons {
    @apply flex items-start gap-4;

    :deep(.ct-dropdown__options--default) {
      @apply w-full;
    }
  }

  &__title {
    @apply text-white custom-font__title-text-1;

    &--mobile {
      @apply block md:hidden;
    }

    &--desktop {
      @apply hidden md:block;
    }
  }

  &__view-more {
    @apply shared-tailwind__button;
  }
}
</style>
