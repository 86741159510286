<template>
  <ul class="ct-social-media-list">
    <template
      v-for="(socialMedia, index) in socialMediaList"
      :key="`${index}SocialMedia`"
    >
      <li
        v-if="socialMedia.value"
        class="ct-social-media-list"
      >
        <a
          class="ct-social-media-list__href"
          :href="socialMedia.value"
          target="_blank"
        >
          <Facebook
            v-if="socialMedia.is === VITE_LINKS.FACEBOOK"
            class="ct-social-media-list__icon"
          />
          <X
            v-else-if="socialMedia.is === VITE_LINKS.X"
            class="ct-social-media-list__icon"
          />
          <LinkedIn
            v-else-if="socialMedia.is === VITE_LINKS.LINKEDIN"
            class="ct-social-media-list__icon"
          />
          <Email
            v-else
            class="ct-social-media-list__icon"
          />
        </a>
      </li>
    </template>
  </ul>
</template>

<script setup lang="ts">
import useLinks from "@/composables/useLinks"
import { SocialMediaNames } from "@/types/entrypoints"
import { VITE_LINKS } from "@/types/backend"
import Email from "@/components/Icon/Email.vue"
import Facebook from "@/components/Icon/Facebook.vue"
import X from "@/components/Icon/X.vue"
import LinkedIn from "@/components/Icon/linkedin.vue"

const props = defineProps<{ socialMediaNames: SocialMediaNames }>()
const { socialMediaList } = useLinks(props.socialMediaNames)
</script>

<style scoped lang="postcss">
.ct-social-media-list {
  @apply flex items-center gap-2.5;

  &__href {
    @apply text-white flex w-9 h-9 lg:w-12 lg:h-12 flex-col justify-center items-center gap-2.5 border border-white px-[0.3125rem] py-2.5 rounded-full border-solid;
  }

  &__icon {
    @apply h-3 lg:h-[1.125rem];
  }
}
</style>
