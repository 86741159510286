import { VITE_LINKS } from "@/types/backend"
import useEnvs from "./useEnvs"
import { DropdownOptionLink, SocialMediaNames } from "@/types/entrypoints"
import IconWcmc from "@/components/Icon/Wcmc.vue"
import IconIucn from "@/components/Icon/Iucn.vue"
import IconWcpa from "@/components/Icon/Wcpa.vue"
import IconPp from "@/components/Icon/Logo.vue"

export function useLinks(socialMediaNames?: SocialMediaNames) {
	type SocialMediaLinks = (DropdownOptionLink & { is: VITE_LINKS })[]
	const {
		VITE_LINK_X,
		VITE_LINK_EMAIL,
		VITE_LINK_FACEBOOK,
		VITE_LINK_LINKEDIN,
		VITE_LINK_IUCN,
		VITE_LINK_PP,
		VITE_LINK_WCMC,
		VITE_LINK_WCPA,
		VITE_SHARE_REPORT_LINK_FACEBOOK,
		VITE_SHARE_REPORT_LINK_LINKEDIN,
		VITE_SHARE_REPORT_LINK_X
	} = useEnvs()
	const siteTitle = window.siteTitle
	const socialMediaList: SocialMediaLinks = socialMediaNames
		? [
				{
					is: VITE_LINKS.EMAIL,
					text: socialMediaNames[VITE_LINKS.EMAIL],
					value: VITE_LINK_EMAIL ? `mailto:${VITE_LINK_EMAIL}?subject=${siteTitle}` : undefined,
					downloadFileInNewWindow: true
				},
				{
					is: VITE_LINKS.LINKEDIN,
					text: socialMediaNames[VITE_LINKS.LINKEDIN],
					value: VITE_LINK_LINKEDIN,
					downloadFileInNewWindow: true
				},
				{
					is: VITE_LINKS.FACEBOOK,
					text: socialMediaNames[VITE_LINKS.FACEBOOK],
					value: VITE_LINK_FACEBOOK,
					downloadFileInNewWindow: true
				},
				{
					is: VITE_LINKS.X,
					text: socialMediaNames[VITE_LINKS.X],
					value: VITE_LINK_X,
					downloadFileInNewWindow: true
				}
			]
		: []
	const shareReportList: SocialMediaLinks = socialMediaNames
		? [
				{
					is: VITE_LINKS.EMAIL,
					text: socialMediaNames[VITE_LINKS.EMAIL],
					value: `mailto:?subject=Check out the ${siteTitle}&body=${window.siteBaseUrl}`,
					downloadFileInNewWindow: true
				},
				{
					is: VITE_LINKS.LINKEDIN,
					text: socialMediaNames[VITE_LINKS.LINKEDIN],
					value: VITE_SHARE_REPORT_LINK_LINKEDIN,
					downloadFileInNewWindow: true
				},
				{
					is: VITE_LINKS.FACEBOOK,
					text: socialMediaNames[VITE_LINKS.FACEBOOK],
					value: VITE_SHARE_REPORT_LINK_FACEBOOK,
					downloadFileInNewWindow: true
				},
				{
					is: VITE_LINKS.X,
					text: socialMediaNames[VITE_LINKS.X],
					value: VITE_SHARE_REPORT_LINK_X,
					downloadFileInNewWindow: true
				}
			]
		: []
	const logoLinks = [
		{
			is: VITE_LINKS.WCMC,
			value: VITE_LINK_WCMC,
			component: IconWcmc
		},
		{
			is: VITE_LINKS.IUCN,
			value: VITE_LINK_IUCN,
			component: IconIucn
		},
		{
			is: VITE_LINKS.WCPA,
			value: VITE_LINK_WCPA,
			component: IconWcpa
		},
		{
			is: VITE_LINKS.PP,
			value: VITE_LINK_PP,
			component: IconPp
		}
	]
	return {
		socialMediaList,
		shareReportList,
		logoLinks
	}
}
export default useLinks
