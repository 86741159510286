<template>
  <div
    ref="mapContainer"
    class="ct-map-base"
  >
    <span
      class="ct-map-base__title"
      v-text="info.title"
    />
    <div class="ct-map-base__container">
      <Panel
        v-if="mapInfo"
        v-model:chosenLayers="chosenLayers"
        v-model:chosenArea="chosenArea"
        v-model:parentIso3Chosen="chosenParentIso3"
        v-model:chosenRealm="chosenRealm"
        v-model:chosenType="chosenType"
        class="ct-map-base__panel"
        :mapInfo
        :translations
        :disclaimerHtml="info.disclaimerHtml"
        :downloadButtonsHtml="info.downloadButtonsHtml"
        @showDisclaimerPanel="toggleDisclaimerPanel"
      >
        <template #title>
          {{ info.title }}
        </template>
      </Panel>

      <div
        :id="mapId"
        ref="mapElement"
        class="ct-map-base__mapbox"
      />
      <Styles
        v-model="chosenStyle"
        class="ct-map-base__styles"
        :map
        :translations
      />
      <Disclaimer
        v-if="showClaimer"
        :translations
        @closePanel="toggleDisclaimerPanel"
      >
        <DisclaimerDescription />
      </Disclaimer>
    </div>
    <span
      class="ct-map-base__description-after-map"
      v-html="info.descriptionAfterMap"
    />
  </div>
</template>

<script setup lang="ts">
import Disclaimer from "@/components/Map/Disclaimer.vue"
import useEnvs from "@/composables/useEnvs"
import { MapInfo } from "@/types/entrypoints"
import maplibregl from "maplibre-gl"
import type { LayerSpecification, Map } from "maplibre-gl"
import "maplibre-gl/dist/maplibre-gl.css"
import { computed, defineComponent, onMounted, onUnmounted, ref, watch } from "vue"
import Panel from "@/components/Map/Panel.vue"
import { MapTranslation } from "@/types/translations"
import useMap from "@/composables/useMap"
import { LayerMetaData, LayerSpecificationWithExtra, MapLegend, Resource } from "@/types/Components/map"
import { MapArea, MapRealm, MapAreaType } from "@/types/esri"
import Styles from "@/components/Map/Styles.vue"
import useCommon from "@/composables/useCommon"
import { isMapboxURL, transformMapboxUrl } from "maplibregl-mapbox-request-transformer"
import { DISPLAY_DATA_OF_MAP } from "@/types/backend"
import { LAYER_ADDED_AFTER_THIS_LAYER_TERRAIN } from "../../../../constants"

const { VITE_MAPBOX_TOKEN, VITE_ENVIROMENT } = useEnvs()
const props = defineProps<{
	info: MapInfo
	translations: MapTranslation
}>()
const { getMapInfo,
	getLayerId,
	getDefaultAreas,
	getDefaultRealms,
	getDefaultLayers,
	getDefaultTypes,
	convertColoursToSameAsLegendColour,
	getAllAppliedEsriLayers } = useMap()
const { activeOnlyWhenEnteringViewpoint } = useCommon()
const DisclaimerDescription = defineComponent({ template: props.info.disclaimerHtml ?? "" })

const mapInfo = getMapInfo(props.info.displayDataOf)
const map = ref<Map>()
const mapId = computed(() => `${props.info.elementId}-mapbox`)
const resizeObserver = new ResizeObserver(() => {
	map.value?.resize()
})
const { defaultAreaOption } = getDefaultAreas(mapInfo.value, props.translations)
const chosenArea = ref<MapArea | undefined>(defaultAreaOption.value)
const { defaultRealmOption } = getDefaultRealms(mapInfo.value, props.translations, chosenArea)
const chosenRealm = ref<MapRealm | undefined>(defaultRealmOption.value)
const chosenParentIso3 = ref(false)

const { defaultType } = getDefaultTypes(mapInfo.value, props.translations)
const chosenType = ref<MapAreaType | undefined>(defaultType.value?.value)
const { defaultLayers } = getDefaultLayers(mapInfo.value, props.translations, chosenArea, chosenRealm, chosenType, chosenParentIso3)
const chosenLayers = ref<MapLegend[]>(defaultLayers.value)
const chosenStyle = ref<string>()
const mapSettings = computed(() => ({
	chosenArea: chosenArea.value,
	chosenRealm: chosenRealm.value,
	chosenParentIso3: chosenParentIso3.value,
	chosenLayers: chosenLayers.value,
	chosenType: chosenType.value,
	chosenStyle: chosenStyle.value
}))
const mapElement = ref()
const mapContainer = ref<HTMLElement>()

function showCurrentLayer(layerInfo: LayerSpecification) {
	const { chosenType, chosenLayers, chosenRealm, chosenArea, chosenParentIso3 } = mapSettings.value
	if (!layerInfo.metadata) {
		return false
	}
	else if ((layerInfo as LayerSpecificationWithExtra).metadata.resource === undefined) {
		return false
	}
	if (chosenType === undefined)
		return false
	const _layerInfo = layerInfo as LayerSpecificationWithExtra
	const legend = _layerInfo.metadata.legend
	const resourceType = _layerInfo.metadata.resource.type
	const realm = _layerInfo.metadata.realm
	const area = _layerInfo.metadata.resource.area
	const isIsoLlayer = _layerInfo.metadata.resource.isISO3Layer
	if (chosenParentIso3 === true && isIsoLlayer !== true) {
		return false
	}
	else if (chosenParentIso3 === false && isIsoLlayer === true) {
		return false
	}
	if (chosenLayers.includes(legend) && chosenRealm === realm && chosenArea === area) {
		// because in this distribution_the_worlds OECM map resource doesn't include PA data so we need to display PA data here
		if (props.info.displayDataOf === DISPLAY_DATA_OF_MAP.DISTRIBUTION_THE_WORLDS_PA_AND_OECMS && chosenType === MapAreaType.PA_OECMS) {
			return true
		}
		// checks types if it is not for distribution_the_worlds OECM map
		else if (chosenType === resourceType) {
			return true
		}
	}
	return false
}

function watchSettingsChanges() {
	watch(() => mapSettings.value, () => {
		if (map.value) {
			// Filter out the layers that are not from ESRI
			const allAppliedLayers = getAllAppliedEsriLayers(map.value.getStyle().layers)
			const showTheseLayers = []
			for (const layer of allAppliedLayers) {
				const showThisLayer = showCurrentLayer(layer)
				if (showThisLayer) {
					showTheseLayers.push(layer)
				}
				map.value.setLayoutProperty(layer.id, "visibility", showThisLayer ? "visible" : "none")
			}
			if (VITE_ENVIROMENT !== "production") {
				console.log(
					"The click triggers to show these layers. The console log is only showing in development and staging mode",
					showTheseLayers)
			}
		}
	})
}
function populateLayersResourcesForTheMap() {
	const layers: LayerSpecificationWithExtra[] = []
	const resources: Resource[] = []
	// let index = 1
	if (mapInfo.value) {
		for (const resource of mapInfo.value.resources) {
			resources.push(resource)
			for (const [area, realms] of Object.entries(mapInfo.value.areas)) {
				for (const [realmKey, realmInfo] of Object.entries(realms)) {
					if (realmInfo?.legends) {
						for (const { legend, esriLayerNames, legendColours } of realmInfo.legends) {
							for (const esriLayerName of esriLayerNames) {
								const layerInfo = resource.allLayers.find(layerName => layerName.id === esriLayerName)
								if (layerInfo) {
									const convertedEsriLayerId = getLayerId(layerInfo.id, resource.id)
									const metadata: LayerMetaData = {
										resource,
										esriLayerId: layerInfo.id,
										realm: realmKey as MapRealm,
										legend,
										area: area as MapArea
									}
									const convertedLayerInfo: LayerSpecificationWithExtra = {
										...layerInfo,
										id: convertedEsriLayerId,
										source: resource.id,
										layout: {
											// Hide the layer by default,unless this layer is default option
											visibility: "none"
										},
										metadata
									}
									// beacuse DISTRIBUTION_THE_WORLDS_PA_AND_OECMS map oecm should use PA colour
									if (props.info.displayDataOf !== DISPLAY_DATA_OF_MAP.DISTRIBUTION_THE_WORLDS_PA_AND_OECMS) {
										// Make sure the colours are same as legends
										convertColoursToSameAsLegendColour(convertedLayerInfo, resource.type, legendColours)
									}
									if (convertedLayerInfo.layout === undefined) {
										convertedLayerInfo.layout = {}
									}
									if (showCurrentLayer(convertedLayerInfo)) {
										convertedLayerInfo.layout.visibility = "visible"
									}
									layers.push(convertedLayerInfo)
								}
							}
						}
					}
				}
			}
		}
	}

	return { layers, resources }
}
function loadSources(resources: Resource[]) {
	if (map.value) {
		for (const resource of resources) {
			if (resource.resource) {
				map.value.addSource(resource.id, {
					tiles: [resource.resource],
					type: "vector"
				})
			}
		}
	}
}

function loadLayers(layers: LayerSpecificationWithExtra[]) {
	for (const layer of layers) {
		const layerNotAppliedYet = !map.value?.getStyle().layers.find(_layer => layer.id === _layer.id)
		if (map.value && layerNotAppliedYet) {
			map.value.addLayer(layer, LAYER_ADDED_AFTER_THIS_LAYER_TERRAIN)
			if (VITE_ENVIROMENT !== "production") {
				map.value.on("click", layer.id, (e) => {
					if (e.features)
						console.log(
							"The click triggers to show the polygon/point details. The console log is only showing in development and staging mode",
							e.features[0]
						)
				})
			}
		}
	}
}
function loadlayersSources() {
	const { resources, layers } = populateLayersResourcesForTheMap()

	if (VITE_ENVIROMENT !== "production") {
		console.log(
			"The console log is only showing in development and staging mode",
			layers
		)
	}
	loadSources(resources)
	loadLayers(layers)
}
function loadZoomControl() {
	if (map.value)
		map.value.addControl(new maplibregl.NavigationControl({ showCompass: false }))
}
function initMapbox() {
	if (mapElement.value) {
		const transformRequest = (url: string, resourceType: string) => {
			if (isMapboxURL(url)) {
				return transformMapboxUrl(url, resourceType, VITE_MAPBOX_TOKEN)
			}
			// Do any other transforms you want
			return { url }
		}
		// init all mapbox settings
		map.value = new maplibregl.Map({
			accessToken: VITE_MAPBOX_TOKEN,
			container: mapElement.value,
			style: chosenStyle.value,
			zoom: 1.2,
			maxZoom: 14,
			attributionControl: { compact: false },
			// @ts-expect-error , this is expected to have typescript error
			transformRequest
		})
		map.value.on("load", () => {
			if (mapInfo.value) {
				watchSettingsChanges()
			}
		})
		map.value.on("style.load", () => {
			if (mapInfo.value) {
				// load all resources, layers and start watching what the user clicks
				loadlayersSources()
			}
		})
		map.value.scrollZoom.disable()
	}
	else {
		console.error(`map element id: ${mapId.value} not found`)
	}
	loadZoomControl()
	resizeObserver.observe(mapElement.value)
}
onMounted(() => {
	if (mapContainer.value) {
		activeOnlyWhenEnteringViewpoint(mapContainer.value, initMapbox)
	}
})
onUnmounted(() => {
	map.value?.remove()
	if (mapElement.value) resizeObserver.unobserve(mapElement.value)
})

const showClaimer = ref(false)
function toggleDisclaimerPanel() {
	showClaimer.value = !showClaimer.value
}
</script>

<style lang="postcss" scoped>
.ct-map-base {
	@apply w-full flex flex-col relative;

	&__container{
		@apply w-full flex flex-col relative;
	}

	&__panel {
		@apply order-2 lg:order-1 lg:absolute lg:w-[20rem] lg:shared-tailwind__z-index--items-over-map-base lg:left-2 lg:top-2;
	}

	&__styles {
		@apply absolute order-1 right-1 lg:right-4 top-[19.2rem] md:top-[18.8rem] lg:top-auto lg:bottom-6 shared-tailwind__z-index--items-over-map-base;
	}

	&__title {
		@apply flex items-center bg-black px-6 py-3 text-white custom-font__title-text-3;
	}

	&__mapbox {
		@apply w-full order-1 lg:order-2 h-[22.5rem] lg:h-[47rem] shared-tailwind__z-index--map-base;

		:deep(.maplibregl-ctrl-top-right) {
			@apply top-6 right-6;

			.maplibregl-ctrl-group {
				@apply m-0 shadow-none rounded-none;

				button {
					@apply rounded-none flex w-6 h-6 md:w-9 md:h-9 flex-col justify-center items-center gap-2.5 border border-naturalDarkGrey bg-white border-solid;
				}

				.maplibregl-ctrl-icon {
					@apply w-6 h-6 lg:w-10 lg:h-10 text-naturalGrey fill-naturalGrey;
				}
			}
		}
	}

    &__description-after-map{
      @apply italic text-naturalGrey custom-font__small-text-2;
    }
}
</style>
