<template>
  <div
    ref="chapterListRef"
    class="ct-chapter-list"
  >
    <div class="ct-chapter-list__container">
      <ChapterFilter
        v-bind="{
          items: chaptersInfo.chapterOptions,
          chaptersTranslation,
          currentChapterInfo,
          currentChapter,
          currentChapterTextByNumbering: currentChapterTextByNumbering.text
        }"
        @click="toggleChapter"
      />
      <ChapterHero
        class="ct-chapter-list__hero"
        v-bind="{
          chaptersTranslation,
          currentChapterInfo,
          currentChapterTextByNumbering: currentChapterTextByNumbering.text
        }"
      />
      <div class="ct-chapter-list__content">
        <div class="ct-chapter-list__chapter-menu--container">
          <ChapterMenu
            class="ct-chapter-list__chapter-menu"
            v-bind="{
              menuItems: chaptersInfo.chapterOptions,
              currentItem: currentChapter,
              collapseTabMenu
            }"
            @collapseMenu="collapseMenu"
            @click="toggleChapter"
          />
        </div>
        <div
          class="ct-chapter-list__content--main-content"
          :class="{
            'ct-chapter-list__content--longer': collapseMenu
          }"
        >
          <TabMenuRow
            v-if="tabOptions.length > 1"
            class="ct-chapter-list__tabs"
            v-bind="{
              menuItems: tabOptions,
              currentItem: currentTab
            }"
            @click="toggleTab"
          />
          <ChapterBlock
            v-for="(contentBlock, index) in chaptersInfo.chaptersContentBlocks"
            :key="`${index}ChapterBlock`"
            class="ct-chapter-list__content-block"
            v-bind="{
              contentBlock,
              currentChapter,
              currentTab,
              collapseTabMenu
            }"
          />
          <div class="ct-chapter-list__go-to-top--container">
            <GoBackToTarget
              class="ct-chapter-list__go-to-top"
              @click="goToChapters"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ChapterIs } from "@/types/backend"
import type { ChapterContentBlock, Chapter, ChaptersRaw } from "@/types/entrypoints"
import ChapterMenu from "@/components/Chapter/Menu.vue"
import TabMenuRow from "@/components/Tab/MenuRow.vue"
import ChapterFilter from "@/components/Chapter/Filter.vue"
import ChapterBlock from "@/components/Chapter/Block.vue"
import ChapterHero from "@/components/Chapter/Hero.vue"
import { computed, ref } from "vue"
import { TabMenuItems } from "@/types/Components/tab"
import { ChaptersTranslation } from "@/types/translations"
import GoBackToTarget from "@/components/GoBackToTarget.vue"

const props = defineProps<{
	chaptersRaw?: ChaptersRaw
	chaptersTranslation: ChaptersTranslation
}>()
const chapterListRef = ref()
const queryString = window.location.search
const urlParams = new URLSearchParams(queryString)

const chaptersInfo = computed(() => {
	const _chaptersInfo: {
		chapterOptions: TabMenuItems
		chaptersContentBlocks: ChapterContentBlock[]
	} = {
		chapterOptions: [],
		chaptersContentBlocks: []
	}
	if (!props.chaptersRaw) return _chaptersInfo
	props.chaptersRaw.forEach((chaptersInfo, chapterId) => {
		const chapters = chaptersInfo.chapterContentBlocks
		_chaptersInfo.chapterOptions.push({
			title: chaptersInfo.chapterTitle ?? "",
			id: chapterId
		})
		if (chapters) {
			for (const chapter of Object.values(chapters)) {
				_chaptersInfo.chaptersContentBlocks.push(chapter)
			}
		}
	})
	return _chaptersInfo
})
const tabOptions = computed(() => {
	const _tabOptions: TabMenuItems = []
	if (props.chaptersRaw && currentChapter.value !== undefined) {
		const currentChapterInfo = props.chaptersRaw.get(currentChapter.value)
		const chapters = currentChapterInfo?.chapterContentBlocks
		if (chapters) {
			for (const tab of Object.values(chapters)) {
				_tabOptions.push({
					title: props.chaptersTranslation.tabs[tab.chapterType] ?? tab.chapterType,
					id: tab.chapterType
				})
			}
		}
	}
	return _tabOptions
})
const firstChapter = (function () {
	if (props.chaptersRaw) {
		return props.chaptersRaw.keys().next().value
	}
	return undefined
}())
const CHPATER_URL_PARAM = "chapter"
const getChapterFromURL = (function () {
	const chapterKeyInUrl = Number(urlParams.get(CHPATER_URL_PARAM))
	if (props.chaptersRaw !== undefined && props.chaptersRaw.size >= chapterKeyInUrl) {
		const chapterKey = Array.from(props.chaptersRaw.keys())[chapterKeyInUrl]
		return chapterKey
	}
	return undefined
}())
const currentChapter = ref<number | undefined>(getChapterFromURL ?? firstChapter)
const currentChapterInfo = computed<Chapter | null>(() => {
	if (!currentChapter.value) return null
	if (props.chaptersRaw === undefined) return null
	const _currentChapterInfo = props.chaptersRaw.get(currentChapter.value)
	if (_currentChapterInfo) {
		return _currentChapterInfo
	}
	return null
})
const currentChapterTextByNumbering = computed(() => {
	let text = `${props.chaptersTranslation.chapter}`
	let numbering = undefined
	if (props.chaptersRaw && currentChapter.value) {
		const numbering = Array.from(props.chaptersRaw.keys()).indexOf(currentChapter.value)
		text = `${text} ${numbering}`
	}
	return { text, numbering }
})
const defaultTabForCurrentChapter = computed(() =>
	currentChapterInfo.value?.chapterContentBlocks?.CHAPTER ? ChapterIs.CHAPTER : ChapterIs.RECOMMENDATION
)
const currentTab = ref<ChapterIs>(defaultTabForCurrentChapter.value)
function goToChapters() {
	chapterListRef.value.scrollIntoView({ behavior: "smooth" })
}
function toggleChapter(chapterID: number | string) {
	goToChapters()
	// To let the screen go back to top first to give a smoother expericence
	setTimeout(function () {
		currentChapter.value = chapterID as number
		currentTab.value = defaultTabForCurrentChapter.value
		if (props.chaptersRaw) {
			const chapterIndex = Array.from(props.chaptersRaw.keys()).indexOf(Number(chapterID) as number)
			const url = new URL(window.location.href)
			url.searchParams.set(CHPATER_URL_PARAM, String (chapterIndex))
			history.pushState({}, "", url)
		}
	}, 300)
}
function toggleTab(tab: string | number) {
	currentTab.value = tab as ChapterIs
}
const collapseTabMenu = ref(true)

function collapseMenu(collapse: boolean) {
	collapseTabMenu.value = collapse
}
</script>

<style scoped lang="postcss">
.ct-chapter-list {
  @apply bg-naturalLightGrey lg:flex lg:justify-center pb-12 lg:pb-[3.375rem];

  &__container {
    @apply bg-naturalLightGrey lg:w-[81rem];
  }

  &__hero {
    @apply mx-3 mt-3 lg:mx-0 lg:my-[4.5rem];
  }

  &__content {
    @apply flex mx-3 lg:mx-0 gap-0 lg:gap-6;

    &--main-content {
      @apply flex flex-col lg:gap-[3.375rem] grow lg:w-[60rem] pb-12;
    }

    &--longer {
      @apply w-[74.125rem];
    }
  }

  &__chapter-menu {
    &--container {
      @apply lg:grow;
    }
  }

  &__go-to-top{
   &--container{
    @apply flex justify-end;
   }
  }
}
</style>
