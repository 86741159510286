<template>
  <div class="ct-layout-burger-menu">
    <div class="ct-layout-burger-menu__container">
      <div class="ct-layout-burger-menu__close-panel">
        <button
          class="ct-layout-burger-menu__close-panel--button"
          @click="closePanel"
        >
          <IconPlus class="ct-layout-burger-menu__close-panel--icon" />
        </button>
      </div>
      <DropdownSelect
        v-for="(dropdown, index) in allDropdowns"
        :key="`${index}dropdown`"
        class="ct-layout-burger-menu__dropdowns"
        v-bind="{
          ...dropdown,
          expandAll: true,
          disableDefaultStyling: true,
          disableClickOutside: true,
          dropdownType: DropdownTypeIs.DROPDOWN
        }"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { DropdownOptions } from "@/types/entrypoints"
import DropdownSelect from "@/components/DropdownSelect/Index.vue"
import IconPlus from "@/components/Icon/Plus.vue"
import { DropdownTypeIs } from "@/types/Components/dropdownSelect"

defineProps<{
	allDropdowns: DropdownOptions[]
}>()
const emit = defineEmits<{
	closePanel: []
}>()
function closePanel() {
	emit("closePanel")
}
</script>

<style scoped>
.ct-layout-burger-menu {
  @apply overflow-visible left-0 top-0 bottom-0 right-0 h-full w-full fixed shared-tailwind__z-index--full-screen flex justify-end bg-black/75;

  &__close-panel {
    @apply flex justify-end w-full;

    &--button {
      @apply flex w-9 h-9 justify-center items-center gap-2.5 shrink-0 bg-primary p-2.5;
    }

    &--icon {
      @apply w-2.5 h-2.5 shrink-0 rotate-45;
    }
  }

  &__container {
    @apply w-[23.625rem] h-full flex flex-col items-start shrink-0 bg-extraDarkGrey px-4 py-6;
  }

  &__dropdowns {
    @apply flex flex-col items-start w-full;

    :deep(.ct-dropdown-button--custom) {
      @apply flex justify-start items-center gap-3 w-full py-4 text-white;
    }

    :deep(.ct-dropdown__options--custom) {
      @apply flex flex-col gap-2.5 self-stretch;
    }

    :deep(.ct-dropdown-option--custom) {
      @apply p-4 text-white custom-font__lrg-body-text-2;
    }

    :deep(.ct-dropdown-button__text--custom) {
      @apply flex gap-4 items-center custom-font__lrg-body-text-1;
    }
  }
}
</style>
