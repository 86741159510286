<template>
  <div
    class="ct-chart-stacked"
    :class="`ct-chart-stacked--${cmsInfo?.displayDataOf}`"
  >
    <span
      v-if="showTitle"
      class="ct-chart-stacked__text"
      v-html="title"
    />
    <div
      :id="chartId"
      ref="chartRef"
      class="ct-chart-stacked__chart"
      :style="{'height': `${chartHeight}rem`}"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from "vue"
import * as am5 from "@amcharts/amcharts5/index"
import * as am5xy from "@amcharts/amcharts5/xy"
import useCommon from "@/composables/useCommon"
import { CmsInfoChartStacked, TargetLine } from "@/types/entrypoints"
import colours from "../../../../../tailwind/colours"
import useChart from "@/composables/useChart"
import { ChartSettings } from "@/types/backend"

const props = defineProps<{
	cmsInfo: CmsInfoChartStacked | undefined
	chartSettings: ChartSettings
	chartData: unknown[]
	id?: string
	title: string | number
	showTitle: boolean
	targetLinesInfo?: TargetLine[] | undefined
	allColours?: Record<string, am5.Color | undefined>
}>()
const chartRef = ref()
const { activeOnlyWhenEnteringViewpoint } = useCommon()
const { getChartTheme, legendFont, getYAxisLabelSettings, getXAxisLabelSettings, getInitlisedVariables } = useChart()
const { allLegends, allColours: _allColours, min, max, unit, showYAxisLabels } = getInitlisedVariables(props.chartSettings, props.cmsInfo?.displayDataOf)
const allColours = props.allColours ? computed(() => props.allColours as Record<string, am5.Color | undefined>) : _allColours
const DATA_AXIS_KEY = "y_axis"
const yAxisLegth = ref(0)
const chartId = computed(() => `armchart-${props.title}-${props.cmsInfo?.elementId}-${props.id ?? ""}`)
let chart: am5xy.XYChart | undefined
function addYAxis({ root, chart: _chart }: { root: am5.Root, chart: am5xy.XYChart }) {
	const yRenderer = am5xy.AxisRendererY.new(root, {
		inversed: true
	})
	yRenderer.labels.template.setAll({
		oversizedBehavior: "wrap",
		maxWidth: 300

	})
	const yAxis = _chart.yAxes.push(
		am5xy.CategoryAxis.new(root, {
			categoryField: DATA_AXIS_KEY,
			renderer: yRenderer,
			visible: showYAxisLabels.value
		})
	)
	const yAxisTitle = props.chartSettings?.y_axis_label
	if (yAxisTitle) {
		yAxis.children.unshift(am5.Label.new(root, {
			...getYAxisLabelSettings(yAxisTitle)
		}))
	}
	yRenderer.grid.template.setAll({
		location: 1
	})
	yAxis.data.setAll(props.chartData)
	yAxisLegth.value = yAxis.dataItems.length
	return yAxis
}
function addXAxis({ root, chart: _chart }: { root: am5.Root, chart: am5xy.XYChart }) {
	const xAxisConfig: am5xy.IValueAxisSettings<am5xy.AxisRenderer> = {
		numberFormat: unit.value ? `,###'${unit.value}'` : ",###",
		strictMinMax: true,
		visible: true,
		calculateTotals: true,
		renderer: am5xy.AxisRendererX.new(root, {
			strokeOpacity: 0.1
		})
	}
	if (typeof min.value === "number") {
		xAxisConfig.min = min.value
	}
	if (typeof max.value === "number") {
		xAxisConfig.max = max.value
	}
	const xAxis = _chart.xAxes.push(
		am5xy.ValueAxis.new(root, xAxisConfig)
	)
	const xAxisTitle = props.chartSettings?.x_axis_label
	if (xAxisTitle) {
		xAxis.children.moveValue(am5.Label.new(root, {
			...getXAxisLabelSettings(xAxisTitle)
		}))
	}
	return xAxis
}

function addSeries({ root, xAxis, yAxis, legend }: {
	root: am5.Root
	xAxis: am5xy.ValueAxis<am5xy.AxisRenderer>
	yAxis: am5xy.CategoryAxis<am5xy.AxisRenderer>
	legend: am5.Legend
}) {
	const seriess = []
	if (chart) {
		for (const legendName of allLegends.value) {
			const config: am5xy.IXYSeriesSettings = {
				name: legendName,
				stacked: true,
				xAxis: xAxis,
				yAxis: yAxis,
				valueXField: legendName,
				valueXShow: "valueXWorking",
				categoryYField: DATA_AXIS_KEY
			}
			const colour = allColours.value[legendName]
			if (colour) {
				config.fill = colour
			}
			else {
				console.warn(`The category '${legendName}' in the chart '${props.cmsInfo?.title}' does not have a colour code added in tailwind config! So we are using default colour codes for the category.`)
			}
			let series = chart.series.push(am5xy.ColumnSeries.new(root, config))
			series.columns.template.setAll({
				tooltipText: `{name}: [bold]{valueXWorking}${unit.value}[/]`,
				tooltipY: am5.percent(80)
			})
			series.data.setAll(props.chartData)
			series.appear()
			series.bullets.push(function () {
				return am5.Bullet.new(root, {
					sprite: am5.Label.new(root, {
						fill: root.interfaceColors.get("alternativeText"),
						centerY: am5.p50,
						centerX: am5.p50,
						populateText: true
					})
				})
			})
			legend.data.push(series)
			seriess.push(series)
		}
	}
	return seriess
}
function addLegends({ root, chart: _chart }: { root: am5.Root, chart: am5xy.XYChart }) {
	const legend = _chart.bottomAxesContainer.children.push(
		am5.Legend.new(root, {
			centerX: am5.p50,
			x: am5.p50,
			marginBottom: 20,
			clickTarget: "none"
		})
	)
	legend.labels.template.setAll({ ...legendFont })
	return legend
}
const chartHeight = computed(() => props.chartData.length > 5 ? props.chartData.length * 8 : props.chartData.length * 12)
function addTargetLines({ seriess, targetLinesInfo, xAxis }: {
	root: am5.Root
	targetLinesInfo: TargetLine[]
	seriess: am5xy.ColumnSeries[]
	xAxis: am5xy.ValueAxis<am5xy.AxisRenderer>
}) {
	let graciousRange = 100
	const series = seriess[0]
	if (series) {
		for (const targetLineInfo of targetLinesInfo) {
			if (targetLineInfo) {
				const colourCode = colours.colors.chart[targetLineInfo.targetType.internalId] as string
				const colour = am5.color(colourCode)
				let dataValue = (max.value ?? 0) * graciousRange

				if (unit.value === "%") {
					dataValue = Number(targetLineInfo.targetPercentage)
				}
				var seriesRangeDataItem = xAxis.makeDataItem({ value: dataValue, endValue: 0 })
				series.createAxisRange(seriesRangeDataItem)
				seriesRangeDataItem.get("grid")?.setAll({
					strokeOpacity: 1,
					visible: true,
					stroke: colour,
					strokeWidth: 3,
					strokeDasharray: [10, 10],
					fill: colour
				})
				seriesRangeDataItem.get("label")?.setAll({
					location: 0,
					visible: true,
					inside: true,
					marginTop: 30,
					text: targetLineInfo.targetType.label,
					centerX: 0,
					centerY: am5.percent((chartHeight.value * 55) - graciousRange),
					fontWeight: "bold",
					fill: colour
				})
				graciousRange = graciousRange + 100
			}
		}
	}
}
function initChart() {
	if (Array.isArray(props.chartData)) {
		let root = am5.Root.new(chartId.value)
		const theme = getChartTheme(root)
		// Remove background horizontal strokes
		theme.rule("Grid").setAll({
			strokeOpacity: 0,
			strokeWidth: 0
		})
		root.setThemes([theme])
		chart = root.container.children.push(
			am5xy.XYChart.new(root, { })
		)
		const xAxis = addXAxis({ root, chart })
		const yAxis = addYAxis({ root, chart })
		const legend = addLegends({ root, chart })
		const seriess = addSeries({ root, xAxis, yAxis, legend })
		if (Array.isArray(props.targetLinesInfo)) {
			addTargetLines({ root, seriess, targetLinesInfo: props.targetLinesInfo, xAxis })
		}
		chart.appear(1000, 100)
	}
}
onMounted(() => {
	activeOnlyWhenEnteringViewpoint(chartRef.value, initChart)
})
onUnmounted(() => {
	if (chart) chart.dispose()
})
</script>

<style scoped lang="postcss">
.ct-chart-stacked {
  @apply flex flex-col gap-1;

  &__text {
    @apply custom-font__small-text;
  }

  &__chart {
    @apply w-[35rem] md:w-full;
  }
}
</style>
