<template>
  <div
    class="ct-map-tooltip"
    :class="[{ 'ct-map-tooltip--active': isActive }]"
  >
    <button
      class="ct-map-tooltip__button"
      @mouseenter="toggleTooltip(true)"
      @mouseleave="toggleTooltip(false)"
    >
      <IconExclamationCircle class="ct-map-tooltip__trigger" />
    </button>
    <div
      v-show="isActive"
      role="tooltip"
      class="ct-map-tooltip__container"
    >
      <span class="ct-map-tooltip__container--arrow">
        <span
          class="ct-map-tooltip__arrow-icon"
        />
      </span>
      <div class="ct-map-tooltip__text">
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import IconExclamationCircle from "@/components/Icon/ExclamationCircle.vue"
import { ref } from "vue"

const isActive = ref(false)
function toggleTooltip(boolean: boolean) {
	isActive.value = typeof boolean === "boolean" ? boolean : !boolean
}
</script>
  <style lang="postcss" scoped>
  .ct-map-tooltip {
    @apply relative flex items-center;

    &__trigger {
      @apply w-3 h-3 text-white cursor-pointer;
    }

    &__container {
      @apply absolute top-full z-10 min-w-[10rem]  mt-4 -left-[185%];

      &--arrow {
        @apply relative block;
      }
    }

    &__arrow-icon {
      @apply absolute -z-10 top-0 -translate-x-1/2 -translate-y-1/2 left-[15%] transform rotate-45 w-4 h-4 bg-primary border-l border-t border-primary;

    }

    &__text {
      @apply p-2.5 bg-primary text-white custom-font__small-text-2 text-nowrap;
    }
  }
  </style>
