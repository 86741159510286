<template>
  <button
    class="ct-go-back-to-target"
    @click="goBackToTarget"
  >
    <IconArrow class="ct-go-back-to-target__icon" />
  </button>
</template>

<script setup lang="ts">
import IconArrow from "@/components/Icon/Arrow.vue"

const emits = defineEmits<{
	click: []
}>()
function goBackToTarget() {
	emits("click")
}
</script>

<style scoped lang="postcss">
.ct-go-back-to-target {
    @apply cursor-pointer flex w-9 h-9 lg:w-12 lg:h-12 justify-center items-center gap-2.5 shrink-0 bg-primary p-2.5 rounded-full;

    &__icon {
      @apply rotate-180 w-3 h-3 lg:w-3 lg:h-3 shrink-0;
    }
}
</style>
