<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 25"
  >
    <g clip-path="url(#clip0_5962_461)">
      <path
        d="M12.5 25C15.8152 25 18.9946 23.683 21.3388 21.3388C23.683 18.9946 25 15.8152 25 12.5C25 9.18479 23.683 6.00537 21.3388 3.66117C18.9946 1.31696 15.8152 0 12.5 0C9.18479 0 6.00537 1.31696 3.66117 3.66117C1.31696 6.00537 0 9.18479 0 12.5C0 15.8152 1.31696 18.9946 3.66117 21.3388C6.00537 23.683 9.18479 25 12.5 25ZM13.9531 10.2937L12.3906 17.6453C12.2812 18.1766 12.4359 18.4781 12.8656 18.4781C13.1688 18.4781 13.6266 18.3687 13.9375 18.0938L13.8 18.7437C13.3516 19.2844 12.3625 19.6781 11.5109 19.6781C10.4125 19.6781 9.94531 19.0187 10.2484 17.6172L11.4016 12.1984C11.5016 11.7406 11.4109 11.575 10.9531 11.4641L10.2484 11.3375L10.3766 10.7422L13.9547 10.2937H13.9531ZM12.5 8.59375C12.0856 8.59375 11.6882 8.42913 11.3951 8.1361C11.1021 7.84308 10.9375 7.44565 10.9375 7.03125C10.9375 6.61685 11.1021 6.21942 11.3951 5.9264C11.6882 5.63337 12.0856 5.46875 12.5 5.46875C12.9144 5.46875 13.3118 5.63337 13.6049 5.9264C13.8979 6.21942 14.0625 6.61685 14.0625 7.03125C14.0625 7.44565 13.8979 7.84308 13.6049 8.1361C13.3118 8.42913 12.9144 8.59375 12.5 8.59375Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_5962_461">
        <rect
          width="25"
          height="25"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
