<template>
  <div class="ct-logo-list">
    <a
      v-for="(logo, index) in list"
      :key="`${index}logo`"
      :href="logo.value"
      target="_blank"
    >
      <component
        :is="logo.component"
        :class="logo.classes"
      />
    </a>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import useLinks from "@/composables/useLinks"

const { logoLinks } = useLinks()
const list = computed(() => {
	const _list = []
	for (const logo of logoLinks) {
		_list.push({
			...logo,
			classes: [`ct-logo-list__icon ct-logo-list__icon--${logo.is}`]
		})
	}
	return _list
})
</script>

<style scoped>
.ct-logo-list {
  @apply flex flex-wrap min-h-[4.125rem] justify-between lg:justify-normal items-center gap-6 opacity-75 text-white;

  &__icon {
    &--wcmc {
      @apply w-[6.2rem] md:w-[11.5275rem];
    }

    &--iucn {
      @apply w-[2.2rem] md:w-[3.533rem];
    }

    &--wcpa {
      @apply w-[6.2rem] md:w-[10.219rem];
    }

    &--pp {
      @apply w-[6.2rem] md:w-[11.147rem];
    }
  }
}
</style>
