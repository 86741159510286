<template>
  <div
    ref="chartContainer"
    class="ct-chart-base"
    :class="`ct-chart-base--${cmsInfo.displayDataOf}`"
  >
    <div class="ct-chart-base__top">
      <span
        class="ct-chart-base__title"
        v-html="cmsInfo.title"
      />
      <Dropdowns class="ct-chart-base__dropdown" />
    </div>
    <span
      class="ct-chart-base__description"
      v-html="cmsInfo.description"
    />
    <template v-if="chartsData !== undefined">
      <div
        v-if="mountCharts"
        class="ct-chart-base__container"
      >
        <template v-if="chartType === ChartType.LINE">
          <Line
            v-for="(chartData, title) in chartsData"
            :key="`${title}-${cmsInfo.elementId}-chart`"
            :chartSettings
            :cmsInfo="(cmsInfo as CmsInfoChartLine)"
            :chartData="(chartData as YearTypeLineChartData)"
            :title
            :showTitle
            :targetLinesInfo
          />
        </template>
        <template v-else-if="chartType === ChartType.PIE">
          <Pie
            v-for="(chartData, year) in chartsData"
            :key="`${year}-pie-chart`"
            :chartSettings
            :cmsInfo="(cmsInfo as CmsInfoChartPie)"
            :chartData="(chartData as PieChartData)"
            :title="year"
            :showTitle
          />
        </template>
        <template v-else-if="chartType === ChartType.STACKED">
          <BiogeoGraphicRegionsList
            v-if="cmsInfo.displayDataOf === DISPLAY_DATA_OF_STACKED_CHART.COVERAGE_OF_BIOGEOGRAPHIC_REGIONS"
            :cmsInfo="(cmsInfo as CmsInfoChartStacked)"
            :chartsInfo="(chartsData as Record<string, StackedChartsBiogeoGraphicRegionsData>)"
            :chartSettings
            :chartTranslation
          />
          <template v-else>
            <Stacked
              v-for="(chartData, year) in chartsData"
              :key="`${year}stackedChart`"
              :cmsInfo="(cmsInfo as CmsInfoChartStacked)"
              :chartData="(chartData as StackedChartData)"
              :chartSettings
              :title="year"
              :showTitle
              :targetLinesInfo
            />
          </template>
        </template>
        <template v-else-if="chartType === ChartType.ClUSTERED_BAR">
          <ClusteredBar
            v-for="(chartData, year) in chartsData"
            :key="`${year}stackedChart`"
            :cmsInfo="(cmsInfo as CmsInfoChartClusteredBar)"
            :chartData="(chartData as ClusteredBarChartData)"
            :chartSettings
            :title="year"
            :showTitle
            :targetLinesInfo
          />
        </template>
      </div>
    </template>
    <span
      v-else
      class="ct-chart-base__description ct-chart-base__description--not-found"
      v-text="noDataText"
    />
    <span
      class="ct-chart-base__description-after-chart"
      v-html="cmsInfo.descriptionAfterChart"
    />
  </div>
</template>

<script setup lang="ts">
import type { FrontendData, CmsInfo, CmsInfoChartLine, CmsInfoChartPie, CmsInfoChartStacked, CmsInfoChartClusteredBar, TargetLine } from "@/types/entrypoints"
import { ChartType } from "@/types/entrypoints"
import { computed, defineComponent, onMounted } from "vue"
import { ref } from "vue"
import useCommon from "@/composables/useCommon"
import Line from "@/components/Chart/Line.vue"
import Pie from "@/components/Chart/Pie.vue"
import Stacked from "@/components/Chart/Stacked/Index.vue"
import ClusteredBar from "@/components/Chart/ClusteredBar.vue"
import { AllChartsDataTypes, BiogeoGraphicRegionsStackedChartDataInfo, ClusteredBarChartData, ClusteredBarChartDataInfo, LineChartDataInfo, PieChartData, PieChartDataInfo, StackedChartData, StackedChartDataInfo, StackedChartsBiogeoGraphicRegionsData, YearTypeLineChartData } from "@/types/chartsData"
import { ChartSettings, DISPLAY_DATA_OF_CLUSTERED_BAR_CHART, DISPLAY_DATA_OF_LINE_CHART, DISPLAY_DATA_OF_PIE_CHART, DISPLAY_DATA_OF_STACKED_CHART } from "@/types/backend"
import { ChartTranslation } from "@/types/translations"
import BiogeoGraphicRegionsList from "@/components/Chart/Stacked/BiogeoGraphicRegionsList.vue"

const props = defineProps<{
	frontendData: FrontendData
	cmsInfo: CmsInfo
	chartType: ChartType
	targetLinesInfo?: TargetLine[] | undefined
	chartTranslation: ChartTranslation
}>()
const chartContainer = ref()
const Dropdowns = defineComponent({
	template: props.cmsInfo.downloadButtonsHtml || ""
})
const { activeOnlyWhenEnteringViewpoint } = useCommon()
const mountCharts = ref(false)
const noDataText = `${props.cmsInfo.title} ${props.chartTranslation.chart_data_not_avaliable}`
const chartSettings = computed<ChartSettings>(() => {
	const displayDataOf = props.cmsInfo.displayDataOf
	let settings
	switch (props.chartType) {
		case ChartType.LINE: {
			const charts = props.frontendData.charts as LineChartDataInfo
			if (charts) {
				settings = charts[displayDataOf as DISPLAY_DATA_OF_LINE_CHART]?.settings
			}
			break
		}
		case ChartType.PIE: {
			const charts = props.frontendData.charts as PieChartDataInfo
			if (charts) {
				settings = charts[displayDataOf as DISPLAY_DATA_OF_PIE_CHART]?.settings
			}
			break
		}
		case ChartType.STACKED: {
			const charts = props.frontendData.charts as StackedChartDataInfo
			if (charts) {
				settings = charts[displayDataOf as DISPLAY_DATA_OF_STACKED_CHART]?.settings
			}
			break
		}
		case ChartType.ClUSTERED_BAR: {
			const charts = props.frontendData.charts as ClusteredBarChartDataInfo
			if (charts) {
				settings = charts[displayDataOf as DISPLAY_DATA_OF_CLUSTERED_BAR_CHART]?.settings
			}
			break
		}
		default:
			break
	}
	return settings
})
const chartsData = computed(() => {
	const displayDataOf = props.cmsInfo.displayDataOf
	let data: AllChartsDataTypes | undefined = undefined
	switch (props.chartType) {
		case ChartType.LINE: {
			const charts = props.frontendData.charts as LineChartDataInfo
			if (charts) {
				data = charts[displayDataOf as DISPLAY_DATA_OF_LINE_CHART]?.data
			}
			break
		}
		case ChartType.PIE: {
			const charts = props.frontendData.charts as PieChartDataInfo
			if (charts) {
				data = charts[displayDataOf as DISPLAY_DATA_OF_PIE_CHART]?.data
			}
			break
		}
		case ChartType.STACKED: {
			const charts = props.frontendData.charts as StackedChartDataInfo | BiogeoGraphicRegionsStackedChartDataInfo
			if (charts) {
				data = charts[displayDataOf as DISPLAY_DATA_OF_STACKED_CHART]?.data
			}
			break
		}
		case ChartType.ClUSTERED_BAR: {
			const charts = props.frontendData.charts as ClusteredBarChartDataInfo
			if (charts) {
				data = charts[displayDataOf as DISPLAY_DATA_OF_CLUSTERED_BAR_CHART]?.data
			}
			break
		}
		default:
			break
	}
	return data
})
const showTitle = computed(() => Object.keys(chartsData.value ?? {}).length > 1)
onMounted(() => {
	activeOnlyWhenEnteringViewpoint(chartContainer.value, () => {
		mountCharts.value = true
	})
})
</script>

<style scoped lang="postcss">
.ct-chart-base {
  @apply flex flex-col gap-1;

  &__top {
    @apply flex gap-1 items-center justify-between;
  }

  &__title {
    @apply custom-font__title-text-3;
  }

  &__dropdown{
    @apply shrink-0;
  }

  &__chart {
    @apply w-full h-[30rem];
  }

  &__container {
    @apply flex flex-col gap-6 w-full overflow-x-scroll md:overflow-auto;
  }

  &__description {
    @apply custom-font__sml-body-text-2;

    &--not-found {
      @apply text-center py-6 text-error;
    }
  }

  &__description-after-chart {
    @apply italic text-naturalGrey custom-font__small-text-2;
  }
}
</style>
