<template>
  <div class="ct-map-panel">
    <div class="ct-map-panel__top">
      <span
        class="ct-map-panel__title"
        v-text="translations.panel_title"
      />
      <IconPlus
        v-if="showContent"
        class="ct-map-panel__icon ct-map-panel__icon--rotate"
        @click="toggleContentPanel(false)"
      />
      <IconPlus
        v-else
        class="ct-map-panel__icon"
        @click="toggleContentPanel(true)"
      />
    </div>
    <div
      v-show="showContent"
      class="ct-map-panel__content"
    >
      <Tabs
        v-if="areas.length>1"
        v-model="chosenArea"
        :options="areas"
        :isFor="'tabs'"
      />
      <div class="ct-map-panel__content--main">
        <Tabs
          v-if="realms.length > 0"
          v-model="chosenRealm"
          class="ct-map-panel__content--white"
          :options="realms"
          :isFor="'options'"
        />

        <Options
          v-if="types.length > 0"
          v-model="chosenTypes"
          class="ct-map-panel__content--white"
          :options="types"
          :canSelectMultiple="false"
        >
          {{ translations.types.title }}
        </Options>
        <Options
          v-if="showLayersOptions"
          v-model="chosenLayers"
          class="ct-map-panel__content--white"
          :options="layers"
          :canSelectMultiple="true"
        >
          <div class="ct-map-panel__layer-title">
            <span v-text="translations.legends.title" />
            <MapTooltip class="ct-chapter-hero__citations">
              <div v-html="translations.click_to_see_layers" />
            </MapTooltip>
          </div>
        </Options>

        <div
          v-if="showIso3Toggle"
          class="ct-map-panel__iso3 ct-map-panel__content--white"
        >
          <span
            class="ct-map-panel__iso3-text"
            v-html="translations.iso3.title"
          />
          <Toggle
            v-model="parentIso3Chosen"
            :label="parentIso3Chosen? translations.toggle.turn_on : translations.toggle.turn_off"
          />
        </div>
        <div class="ct-map-panel__bottom">
          <DownloadButtons />
          <button
            class="ct-map-panel__disclaimer-button"
            @click="showDisclaimerPanel"
          >
            {{ translations.disclaimer.title }}
          </button>
        </div>
        <slot name="afterContent" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import IconPlus from "@/components/Icon/Plus.vue"
import { computed, defineComponent, ref, watch } from "vue"
import { MapArea, MapRealm, MapAreaType } from "@/types/esri"
import { MapInfo, MapLegend } from "@/types/Components/map"
import useMap from "@/composables/useMap"
import Tabs from "@/components/Map/Tabs.vue"
import { MapTranslation } from "@/types/translations"
import Options from "@/components/Map/Options.vue"
import Toggle from "@/components/Input/Toggle.vue"
import MapTooltip from "@/components/Map/Tooltip.vue"

const props = defineProps<{
	mapInfo: MapInfo
	translations: MapTranslation
	downloadButtonsHtml?: string | undefined
	disclaimerHtml?: string | undefined
}>()
const DownloadButtons = defineComponent({ template: props.downloadButtonsHtml ?? "" })
const { getDefaultAreas, getDefaultLayers, getDefaultTypes } = useMap()
const showContent = ref(true)
function toggleContentPanel(showHide: boolean) {
	showContent.value = showHide
}
const { areas } = getDefaultAreas(props.mapInfo, props.translations)
const chosenArea = defineModel<MapArea | undefined>("chosenArea")
const realms = computed(() => {
	if (chosenArea.value) {
		return (Object.keys(props.mapInfo.areas[chosenArea.value] ?? []) as MapRealm[]).map(key => ({
			label: props.translations.realms[key] ?? key,
			value: key
		}))
	}
	return []
})
const defaultRealmOption = computed(() => realms.value.length > 0 ? realms.value[0].value : undefined)
const chosenRealm = defineModel<MapRealm | undefined>("chosenRealm")
if (chosenRealm.value === undefined) {
	chosenRealm.value = defaultRealmOption.value
}
const { defaultType, types } = getDefaultTypes(props.mapInfo, props.translations)
const chosenType = defineModel<MapAreaType | undefined>("chosenType")
// it is always one type in the list
const chosenTypes = computed<MapAreaType[]>({
	set(value) {
		if (Array.isArray(value) && value.length > 0 && chosenType.value) {
			chosenType.value = value[0]
		}
	},
	get() {
		return chosenType.value ? [chosenType.value] : []
	}
})
const showIso3Toggle = computed(() => {
	if (chosenArea.value === undefined) {
		return false
	}
	const realmInfo = props.mapInfo.areas[chosenArea.value]
	if (realmInfo === undefined || chosenRealm.value === undefined) {
		return false
	}
	const info = realmInfo[chosenRealm.value]
	return info?.showISO3Toggle ?? false
})
const parentIso3Chosen = defineModel<boolean>("parentIso3Chosen", { default: false })
watch(() => [chosenArea.value, chosenRealm.value], () => {
	// reset parentIso3Chosen false to make sure iso3 is turned off if chosenArea, chosenRealm values are changed
	if (parentIso3Chosen.value === true) {
		parentIso3Chosen.value = false
	}
})
const { defaultLayers, layers } = getDefaultLayers(props.mapInfo, props.translations, chosenArea, chosenRealm, chosenType, parentIso3Chosen)
const chosenLayers = defineModel<MapLegend[]>("chosenLayers")
watch(() => [chosenRealm.value, chosenArea.value], () => {
//  If any of the above changes then reset layers and type
	resetLayersAndType()
})
function resetLayersAndType() {
	chosenLayers.value = defaultLayers.value
	chosenTypes.value = defaultType.value ? [defaultType.value.value] : []
}
const showLayersOptions = computed(() => layers.value.find(layer => layer.value === MapLegend.DEFAULT) === undefined)

const emit = defineEmits<{
	showDisclaimerPanel: [void]
}>()
function showDisclaimerPanel() {
	emit("showDisclaimerPanel")
}
</script>

<style lang="postcss" scoped>
.ct-map-panel {
  &__top {
    @apply hidden lg:flex justify-between items-center bg-black text-white px-6 py-3;
  }

  &__title {
    @apply custom-font__title-text-3 text-white;
  }

  &__icon{
    @apply w-6 cursor-pointer;

    &--rotate{
      @apply rotate-45;
    }
  }

  &__content {
    @apply bg-map-panel h-full flex flex-col;

    &--main{
      @apply flex flex-col items-start gap-3 pt-3 pb-6 px-3;
    }

    &--white{
      @apply text-white;
    }
  }

  &__layer-title{
    @apply flex items-center gap-1;
  }

  &__iso3 {
    @apply custom-font__sml-body-text-1 flex gap-2 items-center;
  }

  &__bottom{
    @apply flex lg:flex-col gap-3 lg:w-full;

    :deep(.ct-dropdown--default){
      @apply w-full;
    }

    :deep(.ct-dropdown-button--default){
      @apply w-full flex justify-center;
    }
  }

  &__disclaimer-button{
        @apply flex justify-center items-center gap-2.5 bg-extraDarkGrey px-4 py-3 custom-font__button-text text-white h-full;
    }
}
</style>
