<template>
  <div
    ref="containerRef"
    class="ct-pop-up"
    :class="{
      'ct-pop-up--no-background-image': !backgroundStyle
    }"
    :style="backgroundStyle"
  >
    <div
      v-if="popUp?.backgroundImageInfo.citations"
      class="ct-pop-up__container--top"
    >
      <Tooltip class="ct-pop-up__citations">
        <div v-html="popUp?.backgroundImageInfo.citations" />
      </Tooltip>
    </div>
    <div
      class="ct-pop-up__container--main"
      :class="{
        'ct-pop-up__container--is-multiple': !isSingle,
        'ct-pop-up__container--is-single': isSingle
      }"
    >
      <div
        class="ct-pop-up__title"
        v-html="popUp?.title"
      />
      <div
        v-if="popUp?.popupDescriptionContentBlock"
        class="ct-pop-up__description"
        v-html="popUp?.popupDescriptionContentBlock"
      />
      <button
        class="ct-pop-up__view-more"
        @click="togglePanel(true)"
      >
        <IconArrow class="ct-pop-up__view-more--icon" />
      </button>
      <PanelFullScreen
        v-show="showPanel"
        class="ct-data-management-record-display-multiple-records__container"
        :title="popUp?.title"
        :showPanel
        @close-panel="togglePanel(false)"
      >
        <div
          class="ct-pop-up__content-block alchemy-element-articles"
          v-html="popUp?.contentBlock"
        />
      </PanelFullScreen>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { PopUp } from "@/types/entrypoints"
import PanelFullScreen from "@/components/Panel/FullScreen.vue"
import { computed, onMounted, ref } from "vue"
import IconArrow from "@/components/Icon/Arrow.vue"
import Tooltip from "@/components/ImageCitation/Tooltip.vue"
import useCommon from "@/composables/useCommon"

const showPanel = ref(false)
const props = defineProps<{
	popUp?: PopUp
	isSingle: boolean
}>()
const { activeOnlyWhenEnteringViewpoint } = useCommon()
const containerRef = ref()
const loadBackground = ref(false)
onMounted(() => {
	activeOnlyWhenEnteringViewpoint(containerRef.value, () => {
		loadBackground.value = true
	})
})
const backgroundStyle = computed(() => {
	if (!props.popUp?.backgroundImageInfo.imageUrl || !loadBackground.value)
		return
	return [
		`background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), url('${props.popUp?.backgroundImageInfo.imageUrl}') lightgray 50% / cover no-repeat;`
	]
})
function togglePanel(openClose: boolean) {
	showPanel.value = openClose
}
</script>

<style scoped lang="postcss">
.ct-pop-up {
  @apply w-full px-3 py-6 lg:p-6 flex flex-col;

  &--no-background-image {
    @apply bg-black/50;
  }

  &__container {
    &--top{
      @apply flex justify-end w-full;
    }

    &--main{
      @apply grow flex flex-col justify-end;
    }

    &--is-single {
      @apply h-64 lg:h-[22.25rem] gap-6;
    }

    &--is-multiple {
      @apply min-h-[8.5rem] md:min-h-[15rem] gap-3 lg:gap-6;
    }
  }

  &__title {
    @apply text-white custom-font__title-text-3;
  }

  &__description {
    @apply text-white custom-font__sml-body-text-2;
  }

  &__view-more {
    @apply flex w-9 h-9 lg:w-12 lg:h-12 justify-center items-center gap-2.5 shrink-0 bg-primary p-2.5 rounded-full;

    &--icon {
      @apply -rotate-90 w-3 h-3 lg:w-3 lg:h-3 shrink-0;
    }
  }
}
</style>
