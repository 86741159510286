<template>
  <div
    v-show="showCurrentBlock"
    class="ct-chapter-block alchemy-element-articles"
    :class="{
      'ct-chapter-block--collapse': !collapseTabMenu
    }"
    v-html="contentBlock?.contentBlockHtml"
  />
</template>

<script setup lang="ts">
import { ChapterIs } from "@/types/backend"
import type { ChapterContentBlock } from "@/types/entrypoints"
import { computed } from "vue"

const props = defineProps<{
	contentBlock: ChapterContentBlock
	currentChapter?: number
	currentTab: ChapterIs
	collapseTabMenu: boolean
}>()
const showCurrentBlock = computed(
	() => props.contentBlock?.parentId === props.currentChapter && props.contentBlock?.chapterType === props.currentTab
)
</script>
<style lang="postcss" scoped>
.ct-chapter-block {
  @apply mt-12 lg:mt-0;

  :deep(article) {
    .alchemy-element-article {
      &__title {
        @apply lg:w-[32.625rem];
      }

      &__text {
        @apply lg:w-[46.875rem];
      }
    }
  }

  :deep(.alchemy-element-tab__article) {
    @apply grid grid-cols-1 gap-12;
  }

  &--collapse {
    @apply flex;

    > :deep(div) {
      @apply grow;
    }

    :deep(article) {
      @apply justify-self-center;
    }
  }
}
</style>
