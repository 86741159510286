<template>
  <div v-if="summary.language === currentLanguage">
    <h2
      class="ct-content-block-text__outside"
      v-html="summary.contentBlock.descriptionOutsidePopUp"
    />
    <PanelFullScreen
      v-if="showPanel"
      v-bind="{
        showPanel,
        title: title
      }"
      @closePanel="closePanel()"
    >
      <div class="ct-content-block-text__inside">
        <div v-html="summary.contentBlock.descriptionInPopUp" />
        <Image />
      </div>
    </PanelFullScreen>
  </div>
</template>

<script setup lang="ts">
import PanelFullScreen from "@/components/Panel/FullScreen.vue"
import { ContentBlockList } from "@/types/entrypoints"
import { defineComponent } from "vue"

const props = defineProps<{
	showPanel: boolean
	summary: ContentBlockList
	currentLanguage: string | number
	title?: string
}>()
const emit = defineEmits<{
	closePanel: []
}>()
function closePanel() {
	emit("closePanel")
}

const Image = defineComponent({ template: props.summary.imageInPopUpHtml || "" })
</script>

<style scoped lang="postcss">
.ct-content-block-text {
  &__outside {
    @apply text-white custom-font__sml-body-text-2 md:custom-font__lrg-body-text-2;
  }

  &__inside {
    @apply custom-font__sml-body-text-2 h-[45rem] overflow-y-auto flex flex-col gap-6;
  }

  &__image {
    @apply w-[99%] object-scale-down;
  }
}
</style>
