<template>
  <PanelFullScreen

    class="ct-map-disclaimer__pop-up"
    :title="translations.disclaimer.title"
    :showPanel="true"
    @closePanel="closePanel"
  >
    <div class="ct-map-disclaimer__content alchemy-element-articles">
      <slot />
    </div>
  </PanelFullScreen>
</template>

<script setup lang="ts">
import PanelFullScreen from "@/components/Panel/FullScreen.vue"
import { MapTranslation } from "@/types/translations"

defineProps<{
	translations: MapTranslation
}>()
const emit = defineEmits<{ closePanel: [void] }>()
function closePanel() {
	emit("closePanel")
}
</script>
