<template>
  <div class="ct-map-options">
    <span class="ct-map-options__title">
      <slot />
    </span>
    <ul
      class="ct-map-options__options"
    >
      <Option
        v-for="(option,index) in options"
        :key="`ct-map-options__option${index}`"
        v-model="selectedOptions"
        class="ct-map-options__option"
        :option
        :canSelectMultiple
      >
        {{ option.label }}
      </Option>
    </ul>
  </div>
</template>

<script setup lang="ts">
import Option from "@/components/Map/Option.vue"
import { MapOption } from "@/types/Components/map"

const selectedOptions = defineModel<string[] | undefined>()
defineProps<{
	options: (MapOption<string> & { colour?: string })[]
	canSelectMultiple: boolean
}>()
</script>

<style lang="postcss" scoped>
.ct-map-options{
    @apply flex flex-col gap-1.5 w-full max-h-[12.5rem] overflow-y-auto;

    &__title{
        @apply custom-font__sml-body-text-1;
    }

    &__options{
        @apply flex flex-col items-start gap-[0.1875rem] grow;
    }

    &__option{
        @apply bg-map-option w-full;
    }

    &__checkbox{
        @apply w-4 h-4 border-white border cursor-pointer relative;

        &--inner{
            @apply absolute bg-primary w-3 h-3 m-1;
        }
    }

    &__radio{
        @apply w-12;
    }

}

.ct-map-options__radio:checked::after{
    @apply rounded-full relative  after:bg-primary;
}
</style>
