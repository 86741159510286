<template>
  <div
    class="ct-chart-pie"
    :class="`ct-chart-pie--${cmsInfo?.displayDataOf}`"
  >
    <span
      v-if="showTitle"
      class="ct-chart-pie__title"
      v-html="title"
    />
    <div
      :id="chartId"
      class="ct-chart-pie__chart"
    />
  </div>
</template>

<script setup lang="ts">
import type { CmsInfoChartPie } from "@/types/entrypoints"
import { computed, onMounted, onUnmounted } from "vue"
import * as am5 from "@amcharts/amcharts5/index"
import * as am5percent from "@amcharts/amcharts5/percent"
import { PieChartData } from "@/types/chartsData"
import useChart from "@/composables/useChart"
import { ChartSettings } from "@/types/backend"

const props = defineProps<{
	chartSettings: ChartSettings
	cmsInfo: CmsInfoChartPie | undefined
	chartData: PieChartData
	title: string
	showTitle: boolean
}>()
const chartId = computed(() => `armchart-${props.title}-${props.cmsInfo?.elementId}`)
let chart: am5percent.PieChart | undefined
const { getChartTheme, legendFont, getInitlisedVariables } = useChart()
const { unit, allColours } = getInitlisedVariables(props.chartSettings, props.cmsInfo?.displayDataOf)
const labelText = computed(() => `{category}: [bold]{value}${unit.value}[\\]`)
const chartData = computed<PieChartData>(() => props.chartData.map((entry) => {
	const colourCode = allColours.value[entry.category]
	const _data = { ...entry }
	if (colourCode) {
		_data.sliceSettings = {
			fill: colourCode,
			stroke: colourCode
		}
	}
	return _data
}))
function applySeries({ root }: { root: am5.Root }): am5percent.PieSeries | undefined {
	if (chart) {
		let series = chart.series.push(
			am5percent.PieSeries.new(root, {
				// name: "Series",
				valueField: "value",
				categoryField: "label",
				tooltip: am5.Tooltip.new(root, {
					labelText: labelText.value
				})
			}))
		series.labels.template.setAll({
			text: labelText.value,
			interactive: false
		})
		series.labels.template.set("forceHidden", true)
		series.ticks.template.set("forceHidden", true)
		series.slices.template.setAll({ templateField: "sliceSettings" })
		series.data.setAll(chartData.value)
		return series
	}
}
function applyLegends({ series, root }: {
	root: am5.Root
	series: am5percent.PieSeries | undefined
}) {
	if (chart && series) {
		let legend = chart.children.push(am5.Legend.new(root, {
			centerX: am5.percent(50),
			x: am5.percent(50),
			marginBottom: 20,
			clickTarget: "none"
		}))
		legend.labels.template.setAll({ ...legendFont })
		legend.valueLabels.template.set("forceHidden", true)
		legend.data.setAll(series.dataItems)
	}
}
function initChart() {
	if (Array.isArray(chartData.value)) {
		let root = am5.Root.new(chartId.value)
		const theme = getChartTheme(root)
		root.setThemes([theme])
		chart = root.container.children.push(am5percent.PieChart.new(root, {
			layout: root.verticalLayout
		}))
		const series = applySeries({ root })
		applyLegends({ root, series })
	}
}
onMounted(() => {
	initChart()
})
onUnmounted(() => {
	if (chart) chart.dispose()
})
</script>

<style scoped lang="postcss">
.ct-chart-pie {
  @apply flex flex-col gap-1;

  &__text {
    @apply custom-font__lrg-body-text-1;
  }

  &__chart {
    @apply w-[35rem] md:w-full h-[40rem];
  }
}
</style>
