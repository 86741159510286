<template>
  <li
    class="ct-map-option"
    @click.self="clickOption"
  >
    <InputTickbox
      v-model="value"
      :displayStyle
      :colour="option.colour"
      @click.self="clickOption"
    />
    <span
      class="ct-map-option__label"
      @click.self="clickOption"
    >
      <slot />
    </span>
  </li>
</template>

<script setup lang="ts">
import InputTickbox from "@/components/Input/Tickbox.vue"
import { MapOption } from "@/types/Components/map"
import { computed } from "vue"

const selectedOptions = defineModel<string[] | undefined>()
const props = defineProps<{
	option: MapOption<string> & { colour?: string }
	canSelectMultiple: boolean
}>()
const displayStyle = computed(() => props.canSelectMultiple ? "square" : "round")
const isSelected = computed<boolean>(() => {
	if (selectedOptions.value) {
		return selectedOptions.value.filter(selectedOption => selectedOption === props.option.value).length > 0
	}
	return false
})
const value = computed<boolean>({
	get() {
		return isSelected.value
	},
	set(value) {
		if (selectedOptions.value === undefined)
			return
		if (props.canSelectMultiple) {
			if (value === true) {
				const _selectedOptions = [...selectedOptions.value]
				_selectedOptions.push(props.option.value)
				selectedOptions.value = _selectedOptions
			}
			else {
				selectedOptions.value = selectedOptions.value.filter(selectedOption => selectedOption !== props.option.value)
			}
		}
		// if only one option is allowed and the coming value is true then change to this option
		else if (value === true) {
			selectedOptions.value = [props.option.value]
		}
	}
})
function clickOption() {
	value.value = !value.value
}
</script>

<style lang="postcss" scoped>
.ct-map-option{
    @apply flex items-center p-3 cursor-pointer;

    &__label{
        @apply custom-font__sml-body-text-2 pl-3;
    }
  }

  </style>
