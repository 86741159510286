import { createApp } from "vue"
import { ChartType, type CmsData, type FrontendData } from "@/types/entrypoints"
import ChapterList from "@/components/Chapter/List.vue"
import PopUpList from "@/components/PopUp/List.vue"
import TabsList from "@/components/Tab/List.vue"
import LayoutHeader from "@/components/Layout/Header.vue"
import ContentBlock from "@/components/ContentBlock/Index.vue"

import LayoutHero from "@/components/Layout/Hero.vue"
import LayoutFooter from "@/components/Layout/Footer.vue"
import Links from "@/components/Link/List.vue"
import { Translations } from "@/types/translations"
import Cookie from "@/components/Cookies.vue"
import ChartBase from "@/components/Chart/Base.vue"
import ChartGaugeList from "@/components/Chart/GaugeList.vue"
import MapBase from "@/components/Map/Base.vue"
import useMap from "@/composables/useMap"
import useCommon from "@/composables/useCommon"
import { renderDropdown } from "./dropdown"

declare global {
	interface Window {
		siteTitle: string
		siteDescription: string
		siteBaseUrl: string
		frontendData?: FrontendData
		cmsData?: CmsData
		translations?: Translations
	}
}
// CMS Data
const frontendData = window.frontendData
const chaptersRaw = window.cmsData?.chaptersRaw
const contentBlocksRaw = window.cmsData?.contentBlocksRaw
const popUpsRaw = window.cmsData?.popUpsRaw
const tabsRaw = window.cmsData?.tabsRaw
const dropdownsRaw = window.cmsData?.dropdownsRaw
const heroRaw = window.cmsData?.heroRaw
const headerRaw = window.cmsData?.headerRaw
const footerRaw = window.cmsData?.footerRaw
const linksRaw = window.cmsData?.linksRaw
const cookiesRaw = window.cmsData?.cookiesRaw
const mapRaw = window.cmsData?.mapRaw
const chartStackedRaw = window.cmsData?.chartStackedRaw
const chartLineRaw = window.cmsData?.chartLineRaw
const chartClusteredBarRaw = window.cmsData?.chartClusteredBarRaw
const chartGaugeRaw = window.cmsData?.chartGaugeRaw
const chartPieRaw = window.cmsData?.chartPieRaw
const targetLinesRaw = window.cmsData?.targetLinesRaw
// Translations
const tabsTranslation = window.translations?.tabs
const headerTranslation = window.translations?.header
const footerTranslation = window.translations?.footer
const chaptersTranslation = window.translations?.chapters
const cookiesTranslation = window.translations?.cookies
const mapTranslation = window.translations?.maps
const chartTranslation = window.translations?.charts

if (process.env.NODE_ENV !== "production") {
	console.log("The console log is only showing in development and staging mode",
		{
			cmsData: window.cmsData,
			frontendData,
			translations: window.translations
		})
}
// Remove all scripts (that are already loaded above) in app/views/alchemy/elements
document.querySelectorAll(".frontend-data-script-block").forEach(el => el.remove())
if (footerRaw) {
	createApp(LayoutFooter, { footerRaw, linksRaw, footerTranslation }).mount(`#${footerRaw.elementId}`)
}
if (chaptersRaw !== undefined) {
	chaptersRaw.forEach((chaptersInfo) => {
		if (chaptersInfo.chapterContentBlocks) {
			for (const chapter of Object.values(chaptersInfo.chapterContentBlocks)) {
				if (chapter) {
					chapter.contentBlockHtml = document.getElementById(chapter.contentBlockId)?.outerHTML ?? ""
				}
			}
		}
	})
	createApp(ChapterList, { chaptersRaw, chaptersTranslation }).mount("#chapters")
}
if (contentBlocksRaw) {
	for (const contentBlock of Object.values(contentBlocksRaw)) {
		for (const eachSummary of contentBlock.list) {
			eachSummary.contentBlock = {
				descriptionInPopUp: document.getElementById(eachSummary.descriptionInPopUpElementId)?.outerHTML ?? "",
				descriptionOutsidePopUp: document.getElementById(eachSummary.descriptionOutPopUpElementId)?.outerHTML ?? ""
			}
		}
		createApp(ContentBlock, { contentBlock, dropdownsRaw }).mount(`#${contentBlock.elementId}`)
	}
}
if (heroRaw) {
	heroRaw.contentBlock = document.getElementById(heroRaw.elementId)?.outerHTML
	// because we use defineComponent so we need to register using createApp
	// https://github.com/fengyuanchen/vue-feather/issues/8
	createApp(LayoutHero, { heroRaw, headerRaw, dropdownsRaw, headerTranslation }).mount(`#${heroRaw.elementId}`)
}
// If hero is  present then don't render header as header is rendered in hero
if (headerRaw && heroRaw === undefined) {
	createApp(LayoutHeader, { headerRaw, dropdownsRaw, headerTranslation, inEditMode: true }).mount(
		`#${headerRaw.headerElementId}`
	)
}
if (cookiesRaw) {
	createApp(Cookie, {
		cookiesRaw,
		cookiesTranslation
	}).mount(`#${cookiesRaw.elementId}`)
}
/**
 * Make sure all elements below are only rendered/mouted after main components above are rendered (i,e Chapters) otherwise you might encounter unexpected behaviours.
 * Also components below have to be rendered in this order i,e maps can be used in popups so you need to render popups first then render maps,
 * Another approch is for example after mounting Chapters.vue then you can add the following inside Chapters component to register individual vue apps (i.e A Vue Map component)
 * but with this approch you will take care of this in each main component which will confuse you at some point try to find out where you put the registerApp command
  onMounted(()=>{
    createApp(Map, props).mount('#mapId')
  })
 */
// Tabs
if (tabsRaw) {
	for (const [tabId, tabsInfo] of Object.entries(tabsRaw)) {
		if (tabsInfo !== undefined) {
			for (const tab of tabsInfo) {
				tab.contentBlock = document.getElementById(tab.tabElementId)?.outerHTML ?? ""
			}
		}
		createApp(TabsList, {
			tabs: tabsInfo,
			tabsTranslation
		}).mount(`#${tabId}`)
	}
}
// Popups
if (popUpsRaw) {
	for (const [popupsId, popupsInfo] of Object.entries(popUpsRaw)) {
		if (popupsInfo !== undefined) {
			for (const popup of popupsInfo.popUpList) {
				if (popup.popupDescriptionId)
					popup.popupDescriptionContentBlock = document.getElementById(popup.popupDescriptionId)?.outerHTML ?? ""
				popup.contentBlock = document.getElementById(popup.popupElementId)?.outerHTML ?? ""
			}
		}
		createApp(PopUpList, popupsInfo).mount(`#${popupsId}`)
	}
}

// Map
if (mapRaw) {
	for (const [, info] of Object.entries(mapRaw)) {
		const elementId = info.elementId
		const element = document.getElementById(elementId)
		const disclaimerElement = document.getElementById(info.disclaimerElementId)
		if (element) {
			info.downloadButtonsHtml = element?.innerHTML || undefined
			info.disclaimerHtml = disclaimerElement?.innerHTML || undefined
			// TODO: search build_map_info_base for the reason we have to set this up like this
			const { getInfoFromESRIServer } = useMap()
			const { activeOnlyWhenEnteringViewpoint } = useCommon()
			activeOnlyWhenEnteringViewpoint(element, async () => {
				await getInfoFromESRIServer(info.displayDataOf)
				createApp(MapBase, {
					info,
					translations: mapTranslation
				}).mount(`#${elementId}`)
				renderDropdown(dropdownsRaw, elementId)
			})
			disclaimerElement?.remove()
		}
	}
}
// Charts
if (chartLineRaw) {
	for (const cmsInfo of Object.values(chartLineRaw)) {
		const elementId = cmsInfo.elementId
		const element = document.getElementById(elementId)
		const targetLinesInfo = targetLinesRaw ? targetLinesRaw[cmsInfo.cmsId] : undefined
		if (element) {
			cmsInfo.downloadButtonsHtml = element?.innerHTML || undefined
			createApp(ChartBase, {
				cmsInfo,
				frontendData,
				chartType: ChartType.LINE,
				targetLinesInfo,
				chartTranslation
			}).mount(`#${elementId}`)
		}
	}
}
if (chartPieRaw) {
	for (const cmsInfo of Object.values(chartPieRaw)) {
		const elementId = cmsInfo.elementId
		const element = document.getElementById(elementId)
		if (element) {
			cmsInfo.downloadButtonsHtml = element?.innerHTML || undefined
			createApp(ChartBase, {
				cmsInfo,
				frontendData,
				chartType: ChartType.PIE,
				chartTranslation
			}).mount(`#${elementId}`)
		}
	}
}
if (chartStackedRaw) {
	for (const cmsInfo of Object.values(chartStackedRaw)) {
		const elementId = cmsInfo.elementId
		const element = document.getElementById(elementId)
		const targetLinesInfo = targetLinesRaw ? targetLinesRaw[cmsInfo.cmsId] : undefined
		if (element) {
			cmsInfo.downloadButtonsHtml = element?.innerHTML || undefined
			createApp(ChartBase, {
				cmsInfo,
				frontendData,
				chartType: ChartType.STACKED,
				chartTranslation,
				targetLinesInfo
			}).mount(`#${elementId}`)
			// StackedChartsBiogeoGraphicRegions
		}
	}
}
if (chartClusteredBarRaw) {
	for (const cmsInfo of Object.values(chartClusteredBarRaw)) {
		const elementId = cmsInfo.elementId
		const element = document.getElementById(elementId)
		const targetLinesInfo = targetLinesRaw ? targetLinesRaw[cmsInfo.cmsId] : undefined
		if (element) {
			cmsInfo.downloadButtonsHtml = element?.innerHTML || undefined
			createApp(ChartBase, {
				cmsInfo,
				frontendData,
				chartType: ChartType.ClUSTERED_BAR,
				targetLinesInfo,
				chartTranslation
			}).mount(`#${elementId}`)
		}
	}
}
if (chartGaugeRaw) {
	for (const info of Object.values(chartGaugeRaw)) {
		const elementId = info.elementId
		const element = document.getElementById(elementId)
		if (element) {
			info.downloadButtonsHtml = element?.innerHTML || undefined
			createApp(ChartGaugeList, {
				info,
				frontendData
			}).mount(`#${elementId}`)
		}
	}
}
// dropdown Buttons
renderDropdown(dropdownsRaw)
if (linksRaw) {
	for (const [, linksInfo] of Object.entries(linksRaw)) {
		const elementId = linksInfo?.elementId ?? ""
		if (document.getElementById(elementId)) {
			createApp(Links, { linksInfo }).mount(`#${elementId}`)
		}
	}
}
// make sure all CMS content not seen before vue changes them
const loader = document.getElementById("loader")
if (loader) {
	loader.style.display = "none"
}
