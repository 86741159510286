<template>
  <div
    class="ct-chart-gauge"
    :class="`ct-chart-gauge--${cmsInfo?.displayDataOf}`"
  >
    <span
      class="ct-chart-gauge__title"
      v-html="title"
    />
    <div
      ref="chartRef"
      class="ct-chart-gauge__chart"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from "vue"
import * as am5 from "@amcharts/amcharts5/index"
import * as am5xy from "@amcharts/amcharts5/xy"
import * as am5radar from "@amcharts/amcharts5/radar"
import { TargetTrackerData } from "@/types/chartsData"
import { colours } from "../../../../tailwind/colours"
import { CmsInfoChartGauge } from "@/types/entrypoints"
import useChart from "@/composables/useChart"
import { ChartSettings, Legend } from "@/types/backend"

const props = defineProps<{
	index: number
	chartData: TargetTrackerData
	chartSettings: ChartSettings
	cmsInfo: CmsInfoChartGauge
}>()
const chartRef = ref()
let chart: am5xy.XYChart | undefined
const { legendFont: legendFont, getChartTheme } = useChart()
const title = computed(() => props.chartData.find(chart => chart.isTitle)?.title)
const unit = computed(() => props.chartSettings?.unit ?? "")
const VALUE_KEY = "value"
function addAxis({ root }: {
	root: am5.Root
}) {
	const axisDataItems: {
		axisConfig: am5.DataItem<am5xy.IValueAxisDataItem>
		value: number
	}[] = []
	if (chart) {
		for (const entry of props.chartData) {
			const legendColour = am5.color(colours.chart[entry.key as Legend] as string)
			const black = am5.color(colours.chart[Legend.BLACK] as string)
			let axisRenderer = am5radar.AxisRendererCircular.new(root, {
				strokeOpacity: 1,
				strokeWidth: 2,
				innerRadius: -25
			})
			axisRenderer.labels.template.setAll({
				visible: false
			})
			axisRenderer.grid.template.setAll({
				stroke: root.interfaceColors.get("background"),
				visible: true,
				strokeOpacity: 0.1
			})
			let xAxis = chart.xAxes.push(
				am5xy.ValueAxis.new(root, {
					maxDeviation: 0,
					min: props.chartSettings?.min,
					max: props.chartSettings?.max,
					strictMinMax: true,
					renderer: axisRenderer
				}))
			// append inner border
			chart.xAxes.push(
				am5xy.ValueAxis.new(root, {
					renderer: am5radar.AxisRendererCircular.new(root, {
						strokeOpacity: 1,
						strokeWidth: 2,
						radius: -25
					})
				}))
			let axisDataItem = xAxis.makeDataItem({
			// @ts-expect-error , for some reason fill is not typed but it is used
				fill: legendColour,
				name: entry.label
			})
			var axisRange = xAxis.createAxisRange(
				xAxis.makeDataItem({
					above: true,
					value: 0,
					endValue: entry.value
				})
			)
			axisRange.get("axisFill")?.setAll({
				visible: entry.showFillColour,
				fill: legendColour
			})
			let clockHand = am5radar.ClockHand.new(root, {
				pinRadius: 10,
				radius: am5.percent(100),
				bottomWidth: 5,
				topWidth: 5
			})
			clockHand.pin.setAll({ fill: black })
			// entry.guageHandUsesLegendColour ? legendColour : black
			clockHand.hand.setAll({ fill: legendColour })
			axisDataItem.set("bullet", am5xy.AxisBullet.new(root, {
				sprite: clockHand
			}))
			xAxis.createAxisRange(axisDataItem)
			axisDataItem.set(VALUE_KEY, 0)
			axisDataItems.push({
				axisConfig: axisDataItem,
				value: entry.value
			})
		}
	}
	return axisDataItems
}
function addLegends({ root, axisDataItems }: {
	root: am5.Root
	axisDataItems: ReturnType<typeof addAxis>
}) {
	if (chart) {
		let legend = chart.children.push(am5.Legend.new(root, {
			x: am5.percent(50),
			centerX: am5.percent(50),
			marginTop: 10,
			marginBottom: 20,
			clickTarget: "none"
		}))
		legend.labels.template.setAll({
			...legendFont
		})
		legend.data.setAll(axisDataItems.map(item => item.axisConfig))
	}
}
function animateLoadedData({ axisDataItems }: { axisDataItems: ReturnType<typeof addAxis> }) {
	for (const { axisConfig, value } of axisDataItems) {
		axisConfig.animate({
			key: VALUE_KEY,
			to: value,
			duration: 4000,
			easing: am5.ease.out(am5.ease.cubic)
		})
	}
}
function initChart() {
	let root = am5.Root.new(chartRef.value)
	const theme = getChartTheme(root)
	root.setThemes([theme])
	chart = root.container.children.push(am5radar.RadarChart.new(root, {
		panX: false,
		panY: false,
		startAngle: 180,
		endAngle: 360,
		radius: am5.percent(100),
		layout: root.verticalLayout
	}))
	chart.getNumberFormatter().set("numberFormat", `#'${unit.value}'`)
	const axisDataItems = addAxis({ root })
	addLegends({ root, axisDataItems })
	animateLoadedData({ axisDataItems })
	chart.bulletsContainer.set("mask", undefined)
	// Make stuff animate on load
	chart.appear(1000, 100)
}
onMounted(() => {
	initChart()
})
onUnmounted(() => {
	if (chart) chart.dispose()
})
</script>

<style scoped lang="postcss">
.ct-chart-gauge {
  @apply flex flex-col gap-1 md:w-1/2;

  &__top {
    @apply flex gap-1 items-center justify-between;
  }

  &__title{
	@apply custom-font__title-text-3;
  }

  &__chart {
    @apply w-[25rem] md:w-auto h-[13rem] lg:h-[13rem];
  }

}
</style>
