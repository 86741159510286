import { computed } from "vue"
import * as am5 from "@amcharts/amcharts5/index"
import { colours } from "../../../tailwind/colours"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated"
import { ChartSettings, DISPLAY_DATA_OF_ALL_CHARTS, Legend } from "@/types/backend"

const legendFont = {
	fontFamily: "hindSiliguri, system-ui"
}
function getChartTheme(root: am5.Root): am5.Theme {
	const theme = am5themes_Animated.new(root)
	theme.rule("Label").setAll({
		...legendFont
	})
	theme.rule("LabelSeries").setAll({
		labelFontFamily: legendFont.fontFamily
	})
	theme.rule("AxisLabel").setAll({
		...legendFont
	})
	theme.rule("AxisLabelRadial").setAll({
		...legendFont
	})
	theme.rule("RadialLabel").setAll({
		...legendFont
	})
	theme.rule("EditableLabel").setAll({
		...legendFont
	})
	return theme
}
function getColour(series: Legend, displayDataOf: DISPLAY_DATA_OF_ALL_CHARTS | undefined) {
	let colourCode = colours.chart[series]
	if (displayDataOf && colours.chart[displayDataOf])
		colourCode = colours.chart[displayDataOf][series]
	return colourCode ? am5.color(colourCode) : undefined
}
function getAllColours(_allSeries: string[] | undefined, allSeriesMap: Record<string, Legend> | undefined, displayDataOf: DISPLAY_DATA_OF_ALL_CHARTS | undefined) {
	return computed(() => {
		const _colours: Record<string, am5.Color | undefined> = {}
		const allSeries = _allSeries ?? []
		for (const seriesName of allSeries) {
			const series = allSeriesMap ? allSeriesMap[seriesName] : seriesName as Legend
			_colours[seriesName] = getColour(series, displayDataOf)
		}

		return _colours
	})
}
function getYAxisLabelSettings(text: string): am5.ILabelSettings {
	return {
		text,
		rotation: -90,
		textAlign: "center",
		y: am5.p50
	}
}
function getXAxisLabelSettings(text: string): am5.ILabelSettings {
	return {
		text,
		textAlign: "center",
		x: am5.p50,
		fontWeight: "bold",
		width: 50
	}
}
function xRendererLabelLimitWidth(): am5.ILabelSettings {
	return {
		oversizedBehavior: "wrap",
		maxWidth: 150
	}
}
function getInitlisedVariables(chartSettings: ChartSettings, displayDataOf: DISPLAY_DATA_OF_ALL_CHARTS | undefined) {
	return {
		allLegends: computed(() => chartSettings?.all_legends ?? []),
		allColours: getAllColours(chartSettings?.all_legends, chartSettings?.all_legends_map, displayDataOf),
		unit: computed(() => chartSettings?.unit ?? ""),
		min: computed(() => !Number.isNaN(chartSettings?.min) ? chartSettings?.min : undefined),
		max: computed(() => !Number.isNaN(chartSettings?.max) ? chartSettings?.max : undefined),
		// if undefined or false it becomes true
		showYAxisLabels: computed(() => chartSettings?.show_y_axis_labels ?? true)
	}
}
export function useChart() {
	return {
		getChartTheme,
		getAllColours,
		getColour,
		xRendererLabelLimitWidth,
		legendFont,
		getYAxisLabelSettings,
		getXAxisLabelSettings,
		getInitlisedVariables
	}
}
export default useChart
