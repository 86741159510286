<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 18"
    fill="none"
  >
    <path
      d="M10.8878 10.125L11.4712 6.86742H7.82358V4.75348C7.82358 3.86227 8.33312 2.99355 9.96675 2.99355H11.625V0.220078C11.625 0.220078 10.1202 0 8.68143 0C5.67754 0 3.71406 1.56023 3.71406 4.38469V6.86742H0.375V10.125H3.71406V18H7.82358V10.125H10.8878Z"
      fill="currentColor"
    />
  </svg>
</template>
