<template>
  <div
    :class="heroClasses.hero"
    :style="backgroundStyle"
  >
    <div :class="heroClasses.overlay" />
    <VideoBackgroundComponent
      :class="heroClasses.video"
    />
    <div :class="heroClasses.container">
      <LayoutHeader
        v-model="showMenu"
        :class="heroClasses.header"
        v-bind="{
          headerRaw,
          dropdownsRaw,
          headerTranslation,
          videoClassName
        }"
      />
      <ImageCitationShowOnPage
        :citations="videoBackgroundInfo?.citations"
        :makeTextWhite="true"
      >
        <div :class="heroClasses.text">
          <h1
            :class="heroClasses.title"
            v-html="heroRaw?.title"
          />
          <h2
            v-if="heroRaw?.contentBlock"
            :class="heroClasses.contentBlock"
            v-html="heroRaw?.contentBlock"
          />
          <ViewMoreButtonElement :class="heroClasses.viewMore" />
        </div>
      </ImageCitationShowOnPage>
    </div>
  </div>
</template>

<script setup lang="ts">
import { DropdownsRaw, HeaderRaw, HeroRaw } from "@/types/entrypoints"
import { computed, defineComponent, ref } from "vue"
import LayoutHeader from "@/components/Layout/Header.vue"
import { HeaderTranslation } from "@/types/translations"
import { HeroStyleFor } from "@/types/backend"
import ImageCitationShowOnPage from "@/components/ImageCitation/ShowOnPage.vue"

const props = defineProps<{
	heroRaw: HeroRaw
	headerRaw: HeaderRaw
	dropdownsRaw: DropdownsRaw
	headerTranslation: HeaderTranslation
}>()
const showMenu = ref(false)
const videoClassName = ref("ct-hero__video")
const heroClasses = computed(() => {
	const _heroClasses: { [key: string]: string[] } = {
		hero: ["ct-hero"],
		header: ["ct-hero__header"],
		container: ["ct-hero__container"],
		text: ["ct-hero__text"],
		title: ["ct-hero__title"],
		contentBlock: ["ct-hero__content-block"],
		viewMore: ["ct-hero__view-more"],
		overlay: ["ct-hero__overlay"],
		video: [videoClassName.value]
	}
	switch (props.heroRaw?.heroStyleFor) {
		case HeroStyleFor.BLOG:
			_heroClasses.hero.push("ct-hero--blog")
			_heroClasses.overlay.push("ct-hero__overlay--blog")
			_heroClasses.video.push("ct-hero__video--blog")
			_heroClasses.header.push("ct-hero__header--blog")
			_heroClasses.container.push("ct-hero__container--blog")
			_heroClasses.text.push("ct-hero__text--blog")
			_heroClasses.title.push("ct-hero__title--blog")
			_heroClasses.contentBlock.push("ct-hero__content-block--blog")
			_heroClasses.viewMore.push("ct-hero__view-more--blog")
			break
		case HeroStyleFor.HOME_PAGE:
			_heroClasses.hero.push("ct-hero--home")
			_heroClasses.overlay.push("ct-hero__overlay--home")
			_heroClasses.video.push("ct-hero__video--home")
			_heroClasses.header.push("ct-hero__header--home")
			_heroClasses.container.push("ct-hero__container--home")
			_heroClasses.text.push("ct-hero__text--home")
			_heroClasses.title.push("ct-hero__title--home")
			_heroClasses.contentBlock.push("ct-hero__content-block--home")
			_heroClasses.viewMore.push("ct-hero__view-more--home")
			break
		default:
			break
	}
	return _heroClasses
})
const ViewMoreButtonElement = defineComponent({
	template: props.heroRaw?.heroViewMoreButtonElementHtml || ""
})
// Video background settings
// Randomly choose the video background
const videoBackgroundInfo = computed(() => props.heroRaw?.backgrounds[Math.floor(Math.random() * props.heroRaw?.backgrounds.length)])
const backgroundStyle = computed(() =>
	videoBackgroundInfo.value
		? [
				`background:  linear-gradient(180deg, rgba(0, 0, 0, 0.00) 38.02%, rgba(0, 0, 0, 0.22) 95.4%), url('${videoBackgroundInfo.value.backgroundImageUrl}') lightgray 50% / cover no-repeat;`
			]
		: ""
)
const VideoBackgroundComponent = defineComponent({
	template: videoBackgroundInfo.value?.videoElementHtml ?? ""
})
</script>

<style scoped lang="postcss">
.ct-hero {
  @apply relative;

  &--dark-bg {
    @apply bg-extraDarkGrey;
  }
  &--home{
    @apply overflow-hidden;
  }
  &__overlay{
    @apply shared-tailwind__z-index--video-overlay absolute w-auto min-w-full min-h-full max-w-none;

    background: linear-gradient(0deg, rgb(0 0 0 / 50%) 0%, rgb(0 0 0 / 50%) 100%);
  }

  &__video {
    @apply shared-tailwind__z-index--video absolute min-w-full min-h-full max-w-none object-cover w-[50vw] h-full;
  }

  &__header {
    @apply relative;
  }

  &__container {
    @apply relative shared-tailwind__z-index--background;

    &--home {
      @apply px-4 pb-12 lg:px-[4.5rem] lg:pb-[4.5rem];
    }

    &--blog {
      @apply px-4 py-2.5 lg:px-[4.5rem];
    }
  }

  &__text {
    &--home {
      @apply flex flex-col gap-12 items-center justify-center text-center min-h-[32.38rem] lg:min-h-[50rem];
    }

    &--blog {
      @apply flex flex-col gap-3 lg:gap-6 justify-center;
    }
  }

  &__title {
    @apply text-white;

    &--home {
      @apply custom-font__h1 lg:max-w-[67.25rem];
    }

    &--blog {
      @apply custom-font__title-text-1;
    }
  }

  &__content-block {
    @apply text-white;

    &--home {
      @apply custom-font__lrg-body-text-1 lg:custom-font__subtext-2 lg:max-w-[67.25rem];
    }
  }

  &__view-more {
    @apply custom-font__button-text flex min-h-[3rem] lg:min-h-[3.375rem] min-w-[20.75rem] lg:min-w-[26rem] justify-center items-center gap-2.5 bg-primary px-2.5 py-3;
  }
}
</style>
