import { LayerSpecification } from "maplibre-gl"
import { DISPLAY_DATA_OF_MAP, MAP_RESOURCES } from "@/types/backend"
import { MapAreaType, MapRealm, MapArea, EsriLayerId } from "@/types/esri"
import { EsriLayerInfo } from "../mapsData"

// This is the legends used in frontend. Nothing to do with the ESRI layers!!! so you will need to map them up in mapsInfo function app/frontend/composables/useMap.ts
export enum MapLegend {
	ALL_TERRESTRIAL = "all_terrestrial",
	ALL_MARINE = "all_marine",
	NATIONAL_WATERS = "national_waters",
	ABNJ = "abnj",
	DATA_DEFICITENT = "data_deficient",
	LESS_THAN_FIVE = "less_than_five",
	FIVE_TO_TEN = "five_to_ten",
	FIVE_TO_SIXTEEN = "five_to_sixteen",
	SIXTEEN_TO_TWENTY_NINE = "sixteen_to_twenty_nine",
	SEVENTEEN_TO_TWENTY_NINE = "seventeen_to_twenty_nine",
	SEVENTEEN_TO_THIRTY = "seventeen_to_thirty",
	MORE_THAN_OR_EQUAL_THIRTY = "more_than_or_equal_thirty",
	MORE_THAN_THIRTY = "more_than_thirty",
	LESS_THAN_TEN = "less_than_ten",
	LESS_THAN_TWENTY = "less_than_twenty",
	TEN_TO_SEVENTEEN = "ten_to_seventeen",
	TEN_TO_NINETEEN = "ten_to_nineteen",
	TEN_TO_TWENTY = "ten_to_twenty",
	TEN_TO_THIRTY = "ten_to_thirty",
	NINETEEN_TO_TWENTY_NINE = "nineteen_to_twenty_nine",
	TEN_TO_TWENTY_NINE = "ten_to_twenty_nine",
	TWENTY_TO_TWENTY_NINE = "twenty_to_twenty_nine",
	TWENTY_TO_FOURTY = "twenty_to_fourty",
	FOURTY_TO_SIXTY = "fourty_to_sixty",
	SIXTY_TO_EIGHTY = "sixty_to_eighty",
	EIGHTY_TO_THUNDARD = "eighty_to_thundard",
	LESS_THAN_THUNDARD = "less_than_thundard",
	LESS_THAN_SIXTY = "less_than_sixty",
	FULLY_COVERED = "full_covered",
	PARTIALLY_COVERED = "partially_covered",
	NOT_COVERED = "not_covered",
	THIRTY_TO_FIFTY = "thirty_to_fifty",
	THIRTY_TO_SIXTY = "thirty_to_sixty",
	MORE_THAN_OR_EQUAL_SIXTY = "more_than_or_equal_sixty",
	MORE_THAN_EIGHTY = "more_than_eighty",
	LESS_THAN_ZERO_POINT_ONE = "less_than_zero_point_one",
	LESS_THAN_ZERO_POINT_TWO = "less_than_zero_point_two",
	ZERO_POINT_ONE_TO_ZERO_POINT_TWO = "zero_point_one_to_zero_point_two",
	ZERO_POINT_TWO_TO_ZERO_POINT_FOUR = "zero_point_two_to_zero_point_four",
	ZERO_POINT_FOUR_TO_ZERO_POINT_SIX = "zero_point_four_to_zero_point_six",
	ZERO_POINT_SIX_TO_ZERO_POINT_EIGHT = "zero_point_six_to_zero_point_eight",
	ZERO_POINT_ZERO_TWO_NINE_TO_ZERO_POINT_ZERO_SIX_TWO = "zero_point_zero_two_nine_to_zero_point_zero_six_two",
	LESS_THAN_ONE = "less_than_one",
	DEFAULT = "default",

	LESS_THAN_FIVE_PERCENT = "less_than_five_percent",
	FIVE_TO_TEN_PERCENT = "five_to_ten_percent",
	TEN_TO_SEVENTEEN_PERCENT = "ten_to_seventeen_percent",
	SEVENTEEN_TO_THIRTY_PERCENT = "seventeen_to_thirty_percent",
	MORE_THAN_THIRTY_PERCENT = "more_than_thirty_percent",

	LESS_THAN_TWENTY_PERCENT = "less_than_twenty_percent",
	TWENTY_TO_FOURTY_PERCENT = "twenty_to_fourty_percent",
	FOURTY_TO_SIXTY_PERCENT = "fourty_to_sixty_percent",
	SIXTY_TO_EIGHTY_PERCENT = "sixty_to_eighty_percent",
	EIGHTY_TO_THUNDARD_PERCENT = "eighty_to_thundard_percent",

	FIVE_TO_SIXTEEN_PERCENT = "five_to_sixteen_percent",
	SIXTEEN_TO_TWENTY_NINE_PERCENT = "sixteen_to_twenty_nine_percent",
	MORE_THAN_OR_EQUAL_THIRTY_PERCENT = "more_than_or_equal_thirty_percent",

	LESS_THAN_TEN_PERCENT = "less_than_ten_percent",
	TEN_TO_NINETEEN_PERCENT = "ten_to_nineteen_percent",
	NINETEEN_TO_TWENTY_NINE_PERCENT = "nineteen_to_twenty_nine_percent",

	TEN_TO_THIRTY_PERCENT = "ten_to_thirty_percent",
	THIRTY_TO_SIXTY_PERCENT = "thirty_to_sixty_percent",
	SIXTY_TO_HUNDARD_PERCENT = "sixty_to_hundard_percent",

	LESS_THAN_TWO_PERCENT = "less_than_two_percent",
	ONE_POINT_SEVEN_SIX_TWO_TO_FIVE_POINT_ONE_SIX_NINE_PERCENT = "one_point_seven_six_two_to_five_point_one_six_nine_percent",
	FIVE_POINT_ONE_SIX_NINE_TO_FOURTEEN_POINT_FOUR_EIGHT_NINE_PERCENT = "five_point_one_six_none_to_fourteen_point_four_eight_nine_percent",
	FOURTEEN_POINT_FOUR_EIGHT_NINE_TO_TWENTY_EIGHT_POINT_TWO_FIVE_SIX_PERCENT = "fourteen_point_four_eight_nine_to_twenty_eight_point_two_five_six_percent",

	LESS_THAN_THREE_PERCENT = "less_than_three_percent",
	THREE_TO_SIX_PERCENT = "three_to_six_percent",
	SIX_TO_THIRTEEN_PERCENT = "six_to_thirteen_percent",

	TWO_TO_FIVE_PERCENT = "two_to_five_percent",

	TEN_TO_TWENTY_PERCENT = "ten_to_twenty_percent",
	TWENTY_TO_THIRTY_PERCENT = "twenty_to_thirty_percent",

	LESS_THAN_ZERO_POINT_FIVE = "less_than_zero_point_five",
	ZERO_POINT_FIVE_TO_ZERO_POINT_SIX = "zero_point_five_to_zero_point_six",
	ZERO_POINT_SIX_TO_ZERO_POINT_SEVEN = "zero_point_six_to_zero_point_seven",
	ZERO_POINT_SEVEN_TO_ZERO_POINT_EIGHT = "zero_point_seven_to_zero_point_eight",
	ZERO_POINT_EIGHT_TO_ONE = "zero_point_eight_to_one",

	LESS_THAN_FOUR_POINT_FOUR = "less_than_four_point_four",
	FOUR_POINT_FOUR_TO_SIX_POINT_TWO = "four_point_four_to_six_point_two",
	SIX_POINT_TWO_TO_EIGHT_POINT_ONE = "six_point_two_to_eight_point_one",
	ABOVE_EIGHT_POINT_ONE = "above_eight_point_one",

	LESS_THAN_FOUR_POINT_TWO = "less_than_four_point_two",
	FOUR_POINT_TWO_TO_FIVE_POINT_SEVEN = "four_point_two_to_five_point_seven",
	FIVE_POINT_SEVEN_TO_SEVEN_POINT_ZERO = "five_point_seven_to_seven_point_zero",
	ABOVE_SEVEN = "above_seven"
}
export type MapOption<T extends string> = {
	label: string | undefined | null
	value: T
}

export type Resource = {
	id: MAP_RESOURCES
	area: MapArea
	type: MapAreaType
	resource: string | undefined
	allLayers: EsriLayerInfo[]
	isISO3Layer?: boolean
}

export type LayerMetaData = {
	resource: Resource
	realm: MapRealm
	esriLayerId: EsriLayerId
	legend: MapLegend
	area: MapArea
}
export type LayerSpecificationWithExtra = {
	metadata: LayerMetaData
} & LayerSpecification
type LegendColoursBase = {
	[MapAreaType.PA]?: string | undefined
	[MapAreaType.PA_OECMS]?: string | undefined
	[MapAreaType.PA_OECMS_ITTS]?: string | undefined
}
export type LegendColours = { iso?: LegendColoursBase } & LegendColoursBase
type LegendInfo = {
	legend: MapLegend
	esriLayerNames: EsriLayerId[]
	legendColours: LegendColours
}
export type MapRealmInfo = {
	legends: LegendInfo[]
	showISO3Toggle?: boolean
}

export type MapAreaInfo = {
	[key in MapRealm]?: MapRealmInfo
}

export type MapInfo = {
	areas: {
		[key in MapArea]?: MapAreaInfo
	}
	resources: Resource[]
}
export type MapsInfo = {
	[key in DISPLAY_DATA_OF_MAP ]?: MapInfo
}
export type MapStyleInfo = {
	title: string
	link: string
}
