<template>
  <li class="ct-link">
    <LinkToThePage
      v-if="props.link.linkToPage"
      class="ct-link__href"
    />
    <a
      v-else-if="props.link.linkToFile"
      class="ct-link__href"
      :href="props.link.linkToFile"
    >
      <span v-html="link.title" />
    </a>
  </li>
  <li
    v-if="isLastItem"
    class="ct-link ct-link--link-seperator"
  >
    <span class="ct-link__seperator" />
  </li>
</template>

<script setup lang="ts">
import { Link } from "@/types/entrypoints"
import { defineComponent } from "vue"

const props = defineProps<{
	link: Link
	isLastItem: boolean
}>()
const LinkToThePage = defineComponent({
	template: props.link.linkToPage || ""
})
</script>

<style scoped lang="postcss">
.ct-link {
  &--link-seperator {
    @apply flex;
  }

  &__seperator {
    @apply w-[0.0625rem] h-full bg-white;
  }
}
</style>
