<template>
  <div class="ct-map-tabs">
    <slot />
    <ul
      class="ct-map-tabs__tabs"
      :class="{
        'ct-map-tabs__tabs--tabs': isFor === 'tabs',
        'ct-map-tabs__tabs--options': isFor === 'options'
      }"
    >
      <li
        v-for="(option, index) in options"
        :key="`ct-map-tabs__tab${index}`"
        class="ct-map-tabs__tab"
        :class="{
          'ct-map-tabs__tab--tabs': isFor === 'tabs',
          'ct-map-tabs__tab--options': isFor === 'options',
          'ct-map-tabs__tab--active': option.value === model
        }"
        @click="chooseThisOption(option)"
        v-html="option.label"
      />
    </ul>
  </div>
</template>

<script setup lang="ts">
import { MapOption } from "@/types/Components/map"

const model = defineModel<string | undefined>()
defineProps<{
	options: MapOption<string>[]
	isFor: "tabs" | "options"
}>()
function chooseThisOption(option: MapOption<string>) {
	model.value = option.value
}
</script>

<style lang="postcss" scoped>
.ct-map-tabs {
  &__tabs {
    &--tabs {
      @apply flex items-center;
    }

    &--options {
      @apply flex items-start content-start gap-3 self-stretch flex-wrap px-0 py-3;
    }
  }

  &__tab {
    @apply cursor-pointer;

    &--tabs {
      @apply flex grow min-h-[3.375rem] justify-center items-center gap-2.5 px-8 py-2.5 text-white border-b-primary border-b-2 custom-font__lrg-body-text-2;
    }

    &--options {
      @apply flex justify-center items-center gap-2.5 border border-grey px-6 py-1.5 rounded-[3.125rem] text-white border-solid custom-font__lrg-body-text-1;
    }

    &--active {
      @apply bg-primary text-black;
    }
  }
}
</style>
