<template>
  <div
    v-if="show"
    class="ct-cookies-banner"
  >
    <div class="ct-cookies-banner__container">
      <Description class="ct-cookies-banner__description" />
      <ul class="ct-cookies-banner__decisions">
        <li class="ct-cookies-banner__decision">
          <button
            class="ct-cookies-banner__button ct-cookies-banner__button--accept"
            @click.prevent="onAcceptButtonClick"
          >
            {{ cookiesTranslation.accept }}
          </button>
        </li>
        <li class="ct-cookies-banner__decision">
          <button
            class="ct-cookies-banner__button"
            @click.prevent="onRejectButtonClick"
          >
            {{ cookiesTranslation.reject }}
          </button>
        </li>
      </ul>
    </div>
    <div class="ct-cookies-banner__overlay" />
  </div>
</template>
<script setup lang="ts">
import useAnalytics from "@/composables/useAnalytics"
import useCommon from "@/composables/useCommon"
import useCookie from "@/composables/useCookie"
import { CookiesRaw } from "@/types/entrypoints"
import { CookiesTranslation } from "@/types/translations"
import { defineComponent, onBeforeMount, ref } from "vue"

const props = defineProps<{
	cookiesRaw: CookiesRaw
	cookiesTranslation: CookiesTranslation
}>()
const { setUserConsentToCookie, getUserConsentFromCookie } = useCookie()
const { freezeBackground } = useCommon()
const { activate } = useAnalytics()
const show = ref(false)
const Description = defineComponent({
	template: props.cookiesRaw.descriptionHtml || ""
})
function onAcceptButtonClick() {
	setUserConsentToCookie(true)
	optInAnalytics()
	location.reload()
}
function onRejectButtonClick() {
	setUserConsentToCookie(false)
	location.reload()
}
function showHideBanner(_show: boolean) {
	show.value = _show
	freezeBackground(_show)
}
function optInAnalytics() {
	activate.all()
}
onBeforeMount(() => {
	const userDecision = getUserConsentFromCookie()
	if (userDecision === undefined && !props.cookiesRaw.isPrivacyPolicyPage) {
		showHideBanner(true)
	}
	else if (userDecision === "true") {
		optInAnalytics()
	}
})
</script>

<style lang="postcss" scoped>
.ct-cookies-banner {
  @apply lg:flex lg:items-center;

  &__container {
    @apply bg-primary shared-tailwind__z-index--full-screen w-full fixed bottom-0 p-3 md:p-6 flex flex-col gap-1 lg:gap-4 lg:items-center lg:flex-row lg:justify-between;
  }

  &__description {
    @apply custom-font__sml-body-text-2 text-white;

    :deep(a) {
      @apply underline;
    }
  }

  &__decisions {
    @apply text-white mt-4 flex items-center;
  }

  &__decision {
    @apply text-white;
  }

  &__button {
    @apply custom-font__button-text px-5 py-3 flex gap-3 items-center uppercase;

    &--accept {
      @apply p-3 border border-solid border-white;
    }
  }

  &__overlay {
    @apply bg-black/75 fixed inset-0 shared-tailwind__z-index--above-background;
  }
}
</style>
