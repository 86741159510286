import { DISPLAY_DATA_OF_CLUSTERED_BAR_CHART,
	DISPLAY_DATA_OF_GAUGE_CHART,
	DISPLAY_DATA_OF_LINE_CHART,
	DISPLAY_DATA_OF_PIE_CHART,
	DISPLAY_DATA_OF_STACKED_CHART,
	ChapterIs,
	DISPLAY_DATA_OF_MAP,
	DropdownSelectBackground,
	DropdownSelectIcon,
	HeroStyleFor,
	TARGET_LINE,
	VITE_LINKS,
	Legend
} from "@/types/backend"
import { FrontendChartDataInfo } from "@/types/chartsData"
import { FrontendMapsDataInfo } from "@/types/mapsData"

export type FrontendData = {
	charts: FrontendChartDataInfo
	maps: FrontendMapsDataInfo
}
export type ChapterContentBlock = {
	parentId: number
	chapterType: ChapterIs
	contentBlockId: string
	contentBlockHtml: string
}
export type Chapter = {
	chapterTitle: string
	backgroundImageInfo: {
		imageUrl: string | undefined
		citations: string | undefined
	}
	chapterContentBlocks: Record<ChapterIs, ChapterContentBlock> | undefined
}
export type Chapters = Map<number, Chapter>
export type ChaptersRaw = Chapters | undefined
export type PopUp = {
	order: number
	popupElementId: string
	parentId: string
	popupId: string
	title: string
	backgroundImageInfo: {
		imageUrl: string | undefined
		citations: string | undefined
	}
	contentBlock: string
	popupDescriptionId: string | undefined
	popupDescriptionContentBlock: string
}
export type PopUps = {
	backgroundImageInfo: {
		imageUrl: string | undefined
		citations: string | undefined
	}
	popUpList: PopUp[]
}
export type PopUpsRaw = Record<string, PopUps> | undefined
export type Tab = {
	tabElementId: string
	parentId: string
	tabId: number
	tabName: string
	contentBlock: string
}
export type Tabs = Tab[]
export type TabsRaw = Record<string, Tabs> | undefined
export type ContentBlockList = {
	descriptionInPopUpElementId: string
	descriptionOutPopUpElementId: string
	imageInPopUpHtml: string
	language: string
	contentBlock: {
		descriptionInPopUp: string
		descriptionOutsidePopUp: string
	}
}
export type ContentBlock = {
	id: number
	elementId: string
	list: ContentBlockList[]
	title: string
	centreAllNestedElements: boolean
	viewMoreButtonTitle: string
	backgroundImageInfo: {
		imageUrl: string | undefined
		citations: string | undefined
	}
	allNestedElements: { id: string, name: string }[]
}
export type ContentBlocksRaw = Record<string, ContentBlock> | undefined
type DropdownOptionShared = {
	parentId?: string
	downloadFileOptionId?: string
	text: string
}
export type DropdownOptionLink = {
	internalFileLink?: string
	externalFileLink?: string
	downloadFileInNewWindow: boolean
	value?: string
} & DropdownOptionShared
export type DropdownOptionText = {
	value: string | number
} & DropdownOptionShared
type DropdownOptionsShared = {
	title?: string
	elementId: string
	parentId: number
	background?: DropdownSelectBackground
	icon?: DropdownSelectIcon
	mounted?: boolean
}
export type DropdownOptions = (
	| { optionsAreLinks: true, options: DropdownOptionLink[] }
	| { optionsAreLinks: false, options: DropdownOptionText[] }
) &
DropdownOptionsShared
export type DropdownsRaw = Record<string, DropdownOptions> | undefined
export type HeroBackground = {
	backgroundImageUrl: string | undefined
	videoElementHtml: string | undefined
	citations: string | undefined
}
export type HeroRaw =
	| {
		elementId: string
		title: string
		heroStyleFor: HeroStyleFor
		heroViewMoreButtonElementHtml: string
		contentBlock: string | undefined
		backgrounds: HeroBackground[]
	}
	| undefined
export type SocialMediaNames = Record<VITE_LINKS, string>
export type HeaderRaw =
	| {
		headerElementId: string
		title: string
		showSocialMediaButtons: boolean
		allNestedElements: { id: string, name: string }[]
	}
	| undefined
export type FooterRaw =
	| {
		elementId: string
		title: string
		socialMediaNames: SocialMediaNames
		showSocialMediaButtons: boolean
		allNestedElements: { id: string, name: string }[]
	}
	| undefined

export type Link = {
	parentId: string
	linkId: string
	title: string
	linkToPage: string
	linkToFile: string
}
export type Links = {
	parentId: string
	elementId: string
	links: Link[]
}
export type LinksRaw = Record<string, Links> | undefined
export type CookiesRaw = {
	elementId: string
	descriptionHtml: string
	isPrivacyPolicyPage: boolean
}
export type TargetLine = {
	parentId: string
	targetType: {
		legend: TARGET_LINE
		internalId: Legend.MARINE | Legend.TERRESTRIAL | Legend.COVERAGE_TARGET
		label: string
	}
	targetPercentage: string
}
export type TargetLinesRaw = Record<string, TargetLine[]> | undefined
export type ChartBasic<_DisplayDataOf> = {
	cmsId: string
	elementId: string
	title: string
	description: string
	descriptionAfterChart: string
	displayDataOf: _DisplayDataOf
	downloadButtonsHtml?: string
}
export type MapInfo = {
	elementId: string
	disclaimerElementId: string
	title: string
	displayDataOf: DISPLAY_DATA_OF_MAP
	descriptionAfterMap: string
	downloadButtonsHtml?: string
	disclaimerHtml?: string
}
export type MapRaw = Record<string, MapInfo> | undefined
export type CmsInfoChartStacked = ChartBasic<DISPLAY_DATA_OF_STACKED_CHART>
export type ChartStackedRaw = Record<string, CmsInfoChartStacked> | undefined
export type CmsInfoChartLine = ChartBasic<DISPLAY_DATA_OF_LINE_CHART>
export type ChartLineRaw = Record<string, CmsInfoChartLine> | undefined
export type CmsInfoChartClusteredBar = ChartBasic<DISPLAY_DATA_OF_CLUSTERED_BAR_CHART>
export type ChartClusteredBarRaw = Record<string, CmsInfoChartClusteredBar> | undefined
export type CmsInfoChartGauge = ChartBasic<DISPLAY_DATA_OF_GAUGE_CHART>
export type ChartGaugeRaw = Record<string, CmsInfoChartGauge> | undefined
export type CmsInfoChartPie = ChartBasic<DISPLAY_DATA_OF_PIE_CHART>
export type ChartPieRaw = Record<string, CmsInfoChartPie> | undefined
export type CmsInfo = CmsInfoChartStacked | CmsInfoChartLine | CmsInfoChartClusteredBar | CmsInfoChartGauge | CmsInfoChartPie
export enum ChartType {
	PIE = "pie",
	STACKED = "stacked",
	LINE = "line",
	ClUSTERED_BAR = "clusteredBar",
	GAUGE = "gauge"
}
export type CmsData = {
	chaptersRaw?: ChaptersRaw
	popUpsRaw?: PopUpsRaw
	tabsRaw?: TabsRaw
	contentBlocksRaw?: ContentBlocksRaw
	dropdownsRaw?: DropdownsRaw
	heroRaw?: HeroRaw
	headerRaw?: HeaderRaw
	footerRaw?: FooterRaw
	linksRaw?: LinksRaw
	cookiesRaw?: CookiesRaw
	mapRaw?: MapRaw
	chartStackedRaw?: ChartStackedRaw
	chartLineRaw?: ChartLineRaw
	chartClusteredBarRaw?: ChartClusteredBarRaw
	chartGaugeRaw?: ChartGaugeRaw
	chartPieRaw?: ChartPieRaw
	targetLinesRaw?: TargetLinesRaw
}
