<template>
  <ul
    class="ct-chapter-menu"
    :class="{ 'ct-chapter-menu--narrow': !collapseTabMenu }"
  >
    <li class="ct-chapter-menu__item">
      <button
        class="ct-chapter-menu__button ct-chapter-menu__numbering ct-chapter-menu__numbering--arrow"
        @click="togglePanel"
      >
        <IconArrow
          class="ct-chapter-menu__arrow"
          :class="{ 'ct-chapter-menu__arrow--right': !collapseTabMenu }"
        />
      </button>
    </li>
    <li
      v-for="(tab, index) of menuItems"
      :key="`${index}tabBase`"
      class="ct-chapter-menu__item"
      :class="{ 'ct-chapter-menu__item--active': currentItem === tab.id }"
      @click="toggleTab(tab.id)"
    >
      <div
        class="ct-chapter-menu__numbering"
        :class="{ 'ct-chapter-menu__numbering--active': currentItem === tab.id }"
      >
        <span v-html="index" />
      </div>
      <span
        v-show="collapseTabMenu"
        class="ct-chapter-menu__text"
        :class="{ 'ct-chapter-menu__text--active': currentItem === tab.id }"
        v-html="tab.title"
      />
    </li>
  </ul>
</template>

<script setup lang="ts">
import { TabMenuItems } from "@/types/Components/tab"
import IconArrow from "@/components/Icon/Arrow.vue"

const props = defineProps<{
	menuItems: TabMenuItems
	currentItem?: number | undefined
	collapseTabMenu: boolean
}>()
const emit = defineEmits<{
	click: [tab: number | string]
	collapseMenu: [collpase: boolean]
}>()
function toggleTab(tab: number | string | undefined) {
	emit("click", tab as number | string)
}
function togglePanel() {
	emit("collapseMenu", !props.collapseTabMenu)
}
</script>

<style scoped lang="postcss">
.ct-chapter-menu {
  @apply hidden sticky top-0 lg:flex flex-col bg-extraDarkGrey cursor-pointer w-[19.125rem];

  &--narrow {
    @apply w-[5.375rem];
  }

  &__numbering {
    @apply flex w-9 h-9 justify-center items-center gap-2.5 p-2.5 text-white;

    &--arrow {
      @apply bg-primary text-black;
    }

    &--active {
      @apply text-black;
    }
  }

  &__arrow {
    @apply w-full rotate-90;

    &--right {
      @apply -rotate-90;
    }
  }

  &__item {
    @apply pl-6 pr-2.5 py-2.5 flex gap-2.5 items-center text-white;

    &--active {
      @apply bg-primary;
    }
  }

  &__text {
    @apply max-w-[14.125rem];

    &--active {
      @apply text-black;
    }
  }
}
</style>
