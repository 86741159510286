<template>
  <div
    class="ct-input-toggle"
    @click="toggle"
  >
    <div
      class="ct-input-toggle__inner"
      :class="{
        'ct-input-toggle__inner--active' :model
      }"
      v-html="label"
    />
  </div>
</template>

<script setup lang="ts">
defineProps<{
	label: string
}>()
const model = defineModel<boolean>({ default: false })
function toggle() {
	model.value = !model.value
}
</script>

<style scoped lang="postcss">
.ct-input-toggle{
    @apply border border-grey min-w-[3.2rem] rounded-2xl overflow-hidden flex items-center min-h-6 p-[0.2rem] cursor-pointer;

    &__inner{
        @apply ml-0 mr-auto pl-1 text-center;

        &--active{
        @apply ml-auto mr-0 px-1 rounded-xl bg-primary;
    }
    }
}
</style>
