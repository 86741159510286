<template>
  <div class="ct-chapter-filter">
    <div class="ct-chapter-filter__info">
      <span class="ct-chapter-filter__text">
        <span
          class="ct-chapter-filter__text--currently-showing"
          v-html="`${chaptersTranslation?.currently_showing}:`"
        />
        <span
          class="ct-chapter-filter__text--chapter"
          v-html="currentChapterTextByNumbering"
        />
      </span>
      <span
        class="ct-chapter-filter__text--filter-by-chapters"
        v-html="`${chaptersTranslation?.filter_by_chapters}:`"
      />
    </div>
    <DropdownSelect
      v-model="currentChapter"
      class="ct-chapter-filter__dropdown"
      v-bind="dropdownInfo"
      @click="toggleTab"
    >
      {{ dropdownInfo.title }}
    </DropdownSelect>
  </div>
</template>

<script setup lang="ts">
import { DropdownSelectProps, DropdownTypeIs } from "@/types/Components/dropdownSelect"
import { TabMenuItems } from "@/types/Components/tab"
import { Chapter } from "@/types/entrypoints"
import { computed, ref } from "vue"
import DropdownSelect from "@/components/DropdownSelect/Index.vue"
import { ChaptersTranslation } from "@/types/translations"
import { DropdownSelectBackground, DropdownSelectIcon } from "@/types/backend"

const props = defineProps<{
	items: TabMenuItems
	chaptersTranslation?: ChaptersTranslation
	currentChapterInfo: Chapter | null
	currentChapter: number | undefined
	currentChapterTextByNumbering: string
}>()
const currentChapter = ref(props.currentChapter)
const dropdownInfo = computed<DropdownSelectProps>(() => {
	const _dropdownInfo: DropdownSelectProps = {
		title: props.chaptersTranslation?.menu ?? "",
		elementId: "",
		icon: DropdownSelectIcon.ARROW,
		background: DropdownSelectBackground.PRIMARY,
		options: [],
		optionsAreLinks: false,
		dropdownType: DropdownTypeIs.SELECT
	}
	for (const item of props.items) {
		_dropdownInfo.options.push({
			text: item.title,
			value: item.id
		})
	}
	return _dropdownInfo
})
const emit = defineEmits<{
	click: [tab: number]
}>()
function toggleTab() {
	emit("click", currentChapter.value as number)
}
</script>

<style scoped lang="postcss">
.ct-chapter-filter {
  @apply flex lg:hidden sticky shared-tailwind__z-index--filter top-0 h-[5.375rem] justify-between items-center shrink-0 bg-white shadow-[0px_6px_8px_0px_rgba(0,0,0,0.15)] px-4 py-3;

  &__info {
    @apply flex flex-col items-start gap-1.5 shrink-0;
  }

  &__text {
    @apply flex gap-1;

    &--currently-showing {
      @apply custom-font__sml-body-text-2;
    }

    &--chapter {
      @apply custom-font__sml-body-text-1;
    }

    &--filter-by-chapters {
      @apply custom-font__lrg-body-text-1;
    }
  }

  &__dropdown {
    :deep(.ct-dropdown__options--default) {
      @apply min-w-[21rem] py-5 min-h-[40rem] bg-white right-[-18%];
    }
  }
}
</style>
