<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 10"
    fill="none"
  >
    <path
      d="M0.857147 0.714286C0.857147 0.319797 1.17694 0 1.57143 0H14.4286C14.8231 0 15.1429 0.319797 15.1429 0.714286C15.1429 1.10877 14.8231 1.42857 14.4286 1.42857H1.57143C1.17694 1.42857 0.857147 1.10877 0.857147 0.714286Z"
      fill="currentColor"
    />
    <path
      d="M0.857147 5C0.857147 4.60551 1.17694 4.28571 1.57143 4.28571H14.4286C14.8231 4.28571 15.1429 4.60551 15.1429 5C15.1429 5.39449 14.8231 5.71429 14.4286 5.71429H1.57143C1.17694 5.71429 0.857147 5.39449 0.857147 5Z"
      fill="currentColor"
    />
    <path
      d="M1.57143 8.57143C1.17694 8.57143 0.857147 8.89122 0.857147 9.28571C0.857147 9.6802 1.17694 10 1.57143 10H14.4286C14.8231 10 15.1429 9.6802 15.1429 9.28571C15.1429 8.89122 14.8231 8.57143 14.4286 8.57143H1.57143Z"
      fill="currentColor"
    />
  </svg>
</template>
