export type useEnvs = ImportMetaEnv & {
	readonly VITE_MAPBOX_TOKEN: string
	readonly VITE_MAPBOX_STYLE_TERRAIN: string
	readonly VITE_MAPBOX_STYLE_SATELLITE: string
	readonly VITE_LINK_X: string
	readonly VITE_LINK_FACEBOOK: string
	readonly VITE_LINK_LINKEDIN: string
	readonly VITE_LINK_EMAIL: string
	readonly VITE_LINK_WCMC: string
	readonly VITE_LINK_IUCN: string
	readonly VITE_LINK_WCPA: string
	readonly VITE_LINK_PP: string
	readonly VITE_SHARE_REPORT_LINK_X: string
	readonly VITE_SHARE_REPORT_LINK_FACEBOOK: string
	readonly VITE_SHARE_REPORT_LINK_LINKEDIN: string
	readonly VITE_ENVIROMENT: "production" | "development" | "staging"
}

export function useEnvs(): useEnvs {
	return import.meta.env as useEnvs
}
export default useEnvs
