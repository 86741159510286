<template>
  <div

    class="ct-pop-up-list"
    :style="backgroundStyle"
    :class="{ 'ct-pop-up-list--multiple': isMultple }"
  >
    <div
      v-if="backgroundImageInfo.citations"
      class="ct-pop-up-list__container--top"
    >
      <Tooltip class="ct-pop-up-list__citations">
        <div v-html="backgroundImageInfo.citations" />
      </Tooltip>
    </div>
    <div
      ref="containerRef"
      class="ct-pop-up-list__container"
      :class="{ 'ct-pop-up-list__container--multiple': isMultple }"
    >
      <Popup
        v-for="(popUp, index) in popUpList"
        :key="`${index}popUp`"
        class="ct-pop-up-list__pop-up"
        :class="{ 'ct-pop-up-list__pop-up--multiple': isMultple }"
        v-bind="{
          popUp,
          isSingle: popUpList.length === 1
        }"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { PopUps } from "@/types/entrypoints"
import Popup from "@/components/PopUp/Index.vue"
import { computed, onMounted, ref } from "vue"
import Tooltip from "@/components/ImageCitation/Tooltip.vue"
import useCommon from "@/composables/useCommon"

const props = defineProps<PopUps>()
const isMultple = computed(() => (props.popUpList ? props.popUpList.length > 1 : false))
const containerRef = ref()
const loadBackground = ref(false)
const { activeOnlyWhenEnteringViewpoint } = useCommon()
onMounted(() => {
	activeOnlyWhenEnteringViewpoint(containerRef.value, () => {
		loadBackground.value = true
	})
})
const backgroundStyle = computed(() => {
	const url = props.backgroundImageInfo.imageUrl
	if (!url || !loadBackground.value) return []
	return [
		{
			background: `url('${url}') lightgray 50% / cover no-repeat`
		}
	]
})
</script>

<style scoped lang="postcss">
.ct-pop-up-list {
  @apply flex flex-col;

  &--multiple {
    @apply flex p-4 lg:gap-6 lg:p-[4.5rem] items-center;
  }

  &__container {
    &--top{
      @apply flex justify-end w-full;
    }

    &--multiple {
      @apply w-full grow grid grid-cols-2 gap-4 lg:flex lg:gap-6 lg:max-w-[82.5rem];
    }
  }
}
</style>
