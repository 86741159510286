<template>
  <div class="ct-panel-full-screen">
    <div
      ref="modalContainer"
      class="ct-panel-full-screen__container"
    >
      <div class="ct-panel-full-screen__top">
        <span
          class="ct-panel-full-screen__title"
          v-html="title"
        />
        <button
          class="ct-panel-full-screen__button--close-panel"
          @click="closePanel"
        >
          <IconPlus class="ct-panel-full-screen__icon--close-panel" />
        </button>
      </div>

      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside, onKeyStroke } from "@vueuse/core"
import IconPlus from "@/components/Icon/Plus.vue"
import { onUnmounted, ref, watchEffect } from "vue"
import useCommon from "@/composables/useCommon"

const props = defineProps<{
	title?: string
	showPanel?: boolean
}>()
const emit = defineEmits<{
	closePanel: []
}>()
const modalContainer = ref(null)
onClickOutside(modalContainer, () => closePanel())
onKeyStroke("Escape", () => closePanel())
function closePanel() {
	emit("closePanel")
}
const { freezeBackground } = useCommon()
watchEffect(() => {
	freezeBackground(props.showPanel)
})
onUnmounted(() => {
	freezeBackground(false)
})
</script>

<style lang="postcss" scoped>
.ct-panel-full-screen {
  @apply overflow-visible left-0 top-0 bottom-0 right-0 h-full w-full fixed shared-tailwind__z-index--full-screen bg-black/75 flex justify-center items-center;

  &__icon {
    &--close-panel {
      @apply w-4 rotate-45;
    }
  }

  &__container {
    @apply shared-tailwind__z-index--full-screen flex flex-col p-3 gap-6 bg-white w-[95%] lg:w-[79rem] h-[98%] md:h-[90%] overflow-y-auto;
  }

  &__top {
    @apply flex justify-between items-center;
  }

  &__title {
    @apply custom-font__title-text-1 lg:custom-font__title-text-2;
  }
}
</style>
