<template>
  <div class="ct-tab-list">
    <TabMenuRow
      class="ct-tab-list__tabs"
      v-bind="{ menuItems, currentItem: currentTab }"
      @click="toggleTabBlock"
    />
    <div class="ct-tab-list__container--dropdown">
      <span class="ct-tab-list__text">
        <span
          class="ct-tab-list__text--currently-showing"
          v-html="`${tabsTranslation?.currently_showing}:`"
        />
        <span
          class="ct-tab-list__text--option"
          v-html="currentTitle"
        />
      </span>
      <div class="ct-tab-list__container--bottom">
        <span
          class="ct-tab-list__text--select-options"
          v-html="tabsTranslation?.select_options"
        />
        <DropdownSelect
          v-model="currentTab"
          class="ct-tab-list__dropdown"
          v-bind="dropdownInfo"
        />
      </div>
    </div>
    <template
      v-for="(tab, index) in tabs"
      :key="`${index}tab`"
    >
      <TabBlock
        v-show="tab.tabId === currentTab"
        v-bind="{
          tab
        }"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import { DropdownSelectBackground, DropdownSelectIcon } from "@/types/backend"
import { DropdownSelectProps, DropdownTypeIs } from "@/types/Components/dropdownSelect"
import type { DropdownOptionText, Tabs } from "@/types/entrypoints"
import TabBlock from "@/components/Tab/Block.vue"
import TabMenuRow from "@/components/Tab/MenuRow.vue"
import DropdownSelect from "@/components/DropdownSelect/Index.vue"
import { computed, ref } from "vue"
import { TabsTranslation } from "@/types/translations"

const props = defineProps<{
	tabs?: Tabs
	tabsTranslation?: TabsTranslation
}>()
const firstTabKey = computed(() => {
	if (props.tabs && props.tabs.length > 0) {
		const firstTab = props.tabs[0]
		return firstTab ? firstTab : undefined
	}
	return undefined
})
const currentTab = ref(firstTabKey.value?.tabId)
const currentTitle = computed(() => {
	for (const { tabId, tabName } of props.tabs ?? []) {
		if (tabId === currentTab.value) return tabName
	}
	return ""
})
const menuItems = computed(() => {
	const _menuItems = []
	for (const { tabId, tabName } of props.tabs ?? []) {
		_menuItems.push({
			id: tabId,
			title: tabName
		})
	}
	return _menuItems
})
const dropdownInfo = computed<DropdownSelectProps>(() => {
	const _options: DropdownOptionText[] = []
	if (props.tabs) {
		for (const tab of props.tabs) {
			_options.push({
				text: tab.tabName,
				value: tab.tabId
			})
		}
	}
	const _dropdownInfo: DropdownSelectProps = {
		title: currentTitle.value,
		elementId: "",
		icon: DropdownSelectIcon.ARROW,
		background: DropdownSelectBackground.PRIMARY_WITH_WHITE_BACKGROUND,
		options: _options,
		optionsAreLinks: false,
		dropdownType: DropdownTypeIs.SELECT
	}
	return _dropdownInfo
})
function toggleTabBlock(id: number | string) {
	currentTab.value = id as number
}
</script>

<style scoped lang="postcss">
.ct-tab-list {
  @apply flex flex-col gap-12;

  :deep(.ct-tab-menu-row) {
    @apply bg-white;
  }

  &__tabs {
    @apply hidden md:flex;
  }

  &__container {
    &--dropdown {
      @apply flex flex-col items-start gap-3 md:hidden;
    }

    &--bottom {
      @apply grow flex justify-between items-center w-full;
    }
  }

  &__text {
    @apply flex gap-1;

    &--currently-showing {
      @apply custom-font__sml-body-text-2;
    }

    &--option {
      @apply custom-font__sml-body-text-1;
    }

    &--select-options {
      @apply custom-font__title-text-3;
    }
  }

  &__dropdown {
    :deep(.ct-dropdown__options--default) {
      @apply w-full;
    }
  }
}
</style>
