/* eslint-disable prefer-rest-params */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import useConstants from "@/composables/useConstants"
import useEnvs from "@/composables/useEnvs"

const { GOOGLEANALYTICSID } = useConstants()
const { VITE_ENVIROMENT } = useEnvs()
function activateGoogleAnalytics() {
	let gTagId = GOOGLEANALYTICSID.staging
	if (VITE_ENVIROMENT === "production") gTagId = GOOGLEANALYTICSID.production
	const script = `https://www.googletagmanager.com/gtag/js?id=${gTagId}`
	let tag = document.head.querySelector(`[src="${script}"`)
	if (!tag) {
		tag = document.createElement("script")
		tag.setAttribute("src", script)
		tag.setAttribute("type", "text/javascript")
		document.head.appendChild(tag)
		window.dataLayer = window.dataLayer || []
		function gtag() {
			dataLayer.push(arguments)
		}
		gtag("js", new Date())
		gtag("config", gTagId)
	}
}
function activateHotjar() {
	if (VITE_ENVIROMENT === "production") {
		(function (h, o, t, j, a, r) {
			h.hj = h.hj || function () {
				(h.hj.q = h.hj.q || []).push(arguments)
			}
			h._hjSettings = { hjid: 5131004, hjsv: 6 }
			a = o.getElementsByTagName("head")[0]
			r = o.createElement("script")
			r.async = 1
			r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
			a.appendChild(r)
		})(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=")
	}
}
export default function useAnalytics() {
	return {
		activate: {
			all: () => {
				activateGoogleAnalytics()
				activateHotjar()
			},
			activateHotjar,
			activateGoogleAnalytics
		}
	}
}
