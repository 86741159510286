<template>
  <div
    class="ct-select-tickbox"
    :class="{
      'ct-select-tickbox--round': displayStyle === 'round',
      'ct-select-tickbox--square': displayStyle === 'square'
    }"
    @click="toggleOption"
  >
    <span
      class="ct-select-tickbox__inner"
      :class="{
        'ct-select-tickbox__inner--round': displayStyle === 'round',
        'ct-select-tickbox__inner--square': displayStyle === 'square',
        'ct-select-tickbox__inner--active': model === true
      }"
      :style
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"

const model = defineModel<boolean>()
const props = defineProps<{
	displayStyle: "round" | "square"
	colour?: string
}>()
const emit = defineEmits<{
	click: []
}>()
function toggleOption() {
	model.value = !model.value
	emit("click")
}
const style = computed(() => {
	if (props.colour) {
		return `background-color: ${props.colour};`
	}
	return ""
})
</script>

<style lang="postcss" scoped>
.ct-select-tickbox {
  @apply border-white border cursor-pointer relative flex justify-center items-center w-5 h-5;

  &--round {
    @apply rounded-full;
  }

  /* &--square {
    @apply w-5 h-5;
  } */

  &__inner {
    @apply bg-primary absolute transition-opacity ease-in duration-100 opacity-0 w-3 h-3;

    &--active{
      @apply opacity-100;
    }

    &--round {
      @apply rounded-full;
    }

    /* &--square {
      @apply w-3 h-3;
    } */
  }
}
</style>
