<template>
  <div
    ref="containerRef"
    class="ct-chapter-hero"
    :style="backgroundStyle"
  >
    <div
      v-if="currentChapterInfo?.backgroundImageInfo.citations"
      class="ct-chapter-hero__container--top"
    >
      <Tooltip class="ct-chapter-hero__citations">
        <div v-html="currentChapterInfo?.backgroundImageInfo.citations" />
      </Tooltip>
    </div>
    <div class="ct-chapter-hero__container--main">
      <span
        class="ct-chapter-hero__title--second"
        v-html="currentChapterTextByNumbering"
      />
      <span
        class="ct-chapter-hero__title--first"
        v-html="currentChapterInfo?.chapterTitle"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { Chapter } from "@/types/entrypoints"
import { ChaptersTranslation } from "@/types/translations"
import { computed, onMounted, ref } from "vue"
import Tooltip from "@/components/ImageCitation/Tooltip.vue"
import useCommon from "@/composables/useCommon"

const props = defineProps<{
	currentChapterInfo?: Chapter | null
	chaptersTranslation: ChaptersTranslation
	currentChapterTextByNumbering: string
}>()

const { activeOnlyWhenEnteringViewpoint } = useCommon()
const containerRef = ref()
const loadImage = ref(false)
onMounted(() => {
	activeOnlyWhenEnteringViewpoint(containerRef.value, () => {
		loadImage.value = true
	})
})
const backgroundStyle = computed(() => {
	if (!props.currentChapterInfo?.backgroundImageInfo.imageUrl || !loadImage.value)
		return []
	return [
		{
			background: `linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), url('${props.currentChapterInfo.backgroundImageInfo.imageUrl}') lightgray 50% / cover no-repeat`
		}
	]
})
</script>

<style scoped lang="postcss">
.ct-chapter-hero {
  @apply flex flex-col h-[15.8rem] lg:h-[22.25rem] gap-2.5 px-6 py-12 lg:p-12;

  &__container {
    &--top{
      @apply flex justify-end w-full;
    }

   &--main{
    @apply grow flex flex-col justify-center gap-3 text-white;
   }
  }

  &__title {
    &--second {
      @apply custom-font__sml-body-text-1;
    }

    &--first {
      @apply custom-font__title-text-2 lg:custom-font__title-text-1;
    }
  }
}
</style>
