<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 12"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.32023 7.08064L0.545295 11.9586C0.625178 11.9854 0.710689 12 0.799588 12H17.2004C17.2892 12 17.3746 11.9854 17.4545 11.9585L10.6798 7.08064L9.86786 7.78022C9.38342 8.19763 8.61702 8.19801 8.13214 7.78022L7.32023 7.08064ZM6.69696 6.5436L0.015131 11.3545C0.00520395 11.3041 0 11.252 0 11.1987V0.801311C0 0.792042 0.000157742 0.782809 0.000470661 0.773616L6.69696 6.5436ZM0.432317 0.0897042C0.542429 0.0323875 0.667376 0 0.799588 0H17.2004C17.3329 0 17.4579 0.0323268 17.5679 0.0895242L9.34565 7.17417C9.16121 7.33309 8.83898 7.33325 8.65435 7.17417L0.432317 0.0897042ZM17.9995 0.773615C17.9998 0.782809 18 0.792042 18 0.801311V11.1987C18 11.252 17.9948 11.3041 17.9848 11.3545L11.303 6.5436L17.9995 0.773615Z"
      fill="currentColor"
    />
  </svg>
</template>
