<template>
  <DropdownSelect
    class="ct-social-media-dropdown"
    v-bind="{
      elementId: '',
      title: socialMediaNames[VITE_LINKS.SHARE],
      options,
      optionsAreLinks: true,
      dropdownType: DropdownTypeIs.DROPDOWN
    }"
  />
</template>

<script setup lang="ts">
import type { SocialMediaNames } from "@/types/entrypoints"
import DropdownSelect from "@/components/DropdownSelect/Index.vue"
import useLinks from "@/composables/useLinks"
import { VITE_LINKS } from "@/types/backend"
import { DropdownTypeIs } from "@/types/Components/dropdownSelect"
import { computed } from "vue"

const props = defineProps<{
	socialMediaNames: SocialMediaNames
	useShareReportLinks: boolean
}>()
const { socialMediaList, shareReportList } = useLinks(props.socialMediaNames)
const options = computed(() => props.useShareReportLinks ? shareReportList : socialMediaList)
</script>

<style scoped lang="postcss">
.ct-social-media-dropdown {
  :deep(.ct-dropdown__options--default) {
    @apply min-w-[9.3rem];
  }
}
</style>
