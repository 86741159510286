<template>
  <div
    class="ct-chart-stacked-charts-biogeo-graphic-regions"
    :class="`ct-chart-stacked--${cmsInfo?.displayDataOf}`"
  >
    <div
      v-for="(category,mainTitle) in chartSettings.category_orders"
      :key="`BiogeoGraphicRegionsChartDiv${mainTitle}`"
      class="ct-chart-stacked-charts-biogeo-graphic-regions__container"
    >
      <span
        class="ct-chart-stacked-charts-biogeo-graphic-regions__title"
        v-text="category"
      />
      <Stacked
        v-for="(chart, index) in chartsInfo[category]"
        :id="chart.id"
        :key="`${index}stackedChart`"
        :cmsInfo="(cmsInfo as CmsInfoChartStacked)"
        :chartData="(chart.data as StackedChartData)"
        :chartSettings
        :title="chart.sub_title"
        :showTitle="true"
        :allColours="generateColours(chart)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { CmsInfoChartStacked } from "@/types/entrypoints"
import { ChartSettings, Legend } from "@/types/backend"
import { StackedChartBiogeoGraphicRegionsData, StackedChartData, StackedChartsBiogeoGraphicRegionsData } from "@/types/chartsData"
import { ChartTranslation } from "@/types/translations"
import Stacked from "@/components/Chart/Stacked/Index.vue"
import { colours } from "../../../../../tailwind/colours"
import * as am5 from "@amcharts/amcharts5/index"
import { computed } from "vue"

const props = defineProps<{
	cmsInfo: CmsInfoChartStacked
	chartsInfo: Record<string, StackedChartsBiogeoGraphicRegionsData>
	chartTranslation: ChartTranslation
	chartSettings: ChartSettings
}>()
const chartSettings = computed(() => props.chartSettings as ChartSettings & { category_orders?: string[] })
function generateColours(chart: StackedChartBiogeoGraphicRegionsData) {
	if (props.chartSettings?.all_legends_map === undefined || props.chartSettings?.all_legends_map === undefined)
		return undefined
	const category = props.chartSettings.all_legends_map[chart.category]
	if ([Legend.TERRESTRIAL, Legend.MARINE, Legend.FRESHWATER, Legend.TRANSTIONAL, Legend.PELAGIC].indexOf(category) === -1) {
		console.warn("We cannot detect the colour code in BiogeoGraphicRegionsList.vue. All details are here. Only Legend.BIOMES , Legend.ECOREGIONS allowed in secondCategory. You have passed", chart.category)
		return undefined
	}
	const chartColours = colours.bioGeoGraphicChart[category as Legend.TERRESTRIAL | Legend.MARINE | Legend.FRESHWATER | Legend.TRANSTIONAL | Legend.PELAGIC]
	const paColourCode = chartColours[Legend.PA]
	const OECMSColourCode = chartColours[Legend.OECMS]
	if (!paColourCode || !OECMSColourCode) {
		console.warn("We cannot detect the colour code in BiogeoGraphicRegionsList.vue. All details are here.", {
			category,
			chartColours,
			paColourCode,
			OECMSColourCode
		})
		return undefined
	}
	const coloursMap: { [key: string]: am5.Color } = {}
	for (const [legendConveted, legend] of Object.entries(props.chartSettings.all_legends_map)) {
		switch (legend) {
			case Legend.PA:
				coloursMap[legendConveted] = am5.color(paColourCode)
				break
			case Legend.OECMS:
				coloursMap[legendConveted] = am5.color(OECMSColourCode)
				break
			default:
				break
		}
	}
	return coloursMap
}
</script>

<style scoped lang="postcss">
.ct-chart-stacked-charts-biogeo-graphic-regions {
  @apply flex flex-col gap-10;

  &__container{
    @apply flex flex-col gap-3;
  }

  &__title{
    @apply custom-font__title-text-3 text-xl;
  }

}
</style>
