<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 18"
    fill="none"
  >
    <path
      d="M15.7263 0H18.7798L12.1088 7.62462L19.9568 18H13.8118L8.99891 11.7074L3.49183 18H0.436444L7.57183 9.84461L0.0432129 0H6.34414L10.6946 5.75169L15.7263 0ZM14.6546 16.1723H16.3466L5.42475 1.73169H3.60906L14.6546 16.1723Z"
      fill="currentColor"
    />
  </svg>
</template>
