export const GOOGLEANALYTICSID = {
	production: "G-H1TDV4TMW1",
	staging: "G-E592D40M7D"
}

export enum COOKIE_NAMES {
	CONSENT = "cookieConsent"
}
export const MAP_STYLE_ID_TERRAIN = "cko1hsfi50vog17l697cr4d6p"
export const MAP_STYLE_ID_SATELITE = "ckniq2twg0q3b17s5gqfxhagf"
export const LAYER_ADDED_AFTER_THIS_LAYER_TERRAIN = "aerialway"
export const LAYER_ADDED_AFTER_THIS_LAYER_SATELITE = "aerialway"
